// Disable explicit any rule for this file until we move to Redux toolkit, which should fix typings
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Tooltip,
} from '@material-ui/core'
import { Link } from 'react-router-dom'

import { checkEventEditability } from '../../utils/checkEventEditability'
import {
  EventCreation,
  EventStatus,
  PublicationStatus,
  UserType,
  constants,
} from '../../constants'
import { getConfirmationMarkup } from '../../utils/confirm'
import {
  appendEventDataWithSubEvents,
  cancelEvents,
  deleteAPIEvents,
  getEventsWithSubEvents,
  publishEvents,
} from '../../utils/events'
import { getButtonLabel } from '../../utils/helpers'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import { eventIsEditable } from '../../utils/eventIsEditable'
import { APIEvent } from '../../types/apiTypes'

// TODO: RTK

const { PUBLICATION_STATUS, EVENT_STATUS, USER_TYPE } = constants

type ColorType = 'default' | 'primary' | 'secondary'

interface EventProps {
  id: string
  publication_status: PublicationStatus
  // TODO: Probably should be required, now optional in order to initial values to be undefined.
  event_status?: EventStatus
}

interface EventActionProps {
  editor: any // object, TODO: Define type
  user: {
    userType: UserType | undefined
  }
  confirm: (msg: any, style: any, actionButtonLabel: any, data: any) => void
  action: EventCreation
  askConfirmation: boolean
  customAction: () => Promise<void>
  customButtonLabel: string
  event: EventProps
  subEvents: []
  eventIsPublished: boolean
  loading: boolean
  runAfterAction: (action: EventCreation, event: EventProps) => void
}

/** Returns whether the button is a save button based on given action */
const isSaveButton = (action: EventCreation): boolean =>
  ['publish', 'update', 'update-draft', 'update-and-delete'].includes(action)

const EventActionButton = (props: EventActionProps): React.ReactElement => {
  const {
    editor,
    user,
    action,
    askConfirmation,
    customAction,
    customButtonLabel,
    event = {
      publication_status: PUBLICATION_STATUS.PUBLIC,
    } as EventProps,
    eventIsPublished,
    loading,
  } = props
  const [agreedToTerms, setAgreedToTerms] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [events, setEvents] = useState<APIEvent[]>([])
  const intl = useIntl()

  useEffect(() => {
    const fetchEventDataForMarkup = async (): Promise<void> => {
      if (showConfirmationModal) {
        const eventData = [event, ...props.subEvents]

        // get the id's of events that have sub events
        // don't re-fetch sub event data for the event that the action is run for, as we already have it
        const eventsWithSubEvents = getEventsWithSubEvents(eventData).filter(
          (eventId) => eventId !== event.id
        )

        const data =
          eventsWithSubEvents.length > 0
            ? await appendEventDataWithSubEvents(eventData, eventsWithSubEvents)
            : eventData

        const editableEvents = data.filter(
          (eve) => eventIsEditable(eve).editable
        )

        setEvents(editableEvents)
      }
    }
    fetchEventDataForMarkup()
  }, [showConfirmationModal, event, props.subEvents])

  const isRegularUser = user?.userType === USER_TYPE.REGULAR

  const formHasSubEvents = editor?.values?.sub_events?.length > 0
  const isDraft = event?.publication_status === PUBLICATION_STATUS.DRAFT
  const isPostponed = event?.event_status === EVENT_STATUS.POSTPONED
  const { editable, explanationId } = checkEventEditability(
    user,
    event,
    action,
    editor
  )
  const showTermsCheckbox = isRegularUser && isSaveButton(action) && !isDraft
  let disabled = !editable || loading || (showTermsCheckbox && !agreedToTerms)

  // TODO Refactor EventActionButton into dedicated action buttons to simplify this mess
  const actionButtonLabel = `${action}-events`
  const message = isDraft ? `confirm-${action}-draft` : `confirm-${action}`
  const modalStyle =
    action === 'cancel' || action === 'delete' ? 'warning' : 'message'
  const extraMessage = action === 'cancel' ? 'confirm-cancel-extra' : null
  const warningMessage = isDraft ? `${action}-draft` : action

  let color: ColorType = 'default'
  const buttonLabel =
    customButtonLabel ||
    getButtonLabel(
      action,
      isRegularUser,
      isDraft,
      eventIsPublished,
      formHasSubEvents
    )

  if (action === 'publish' || action.includes('update') || action === 'edit') {
    color = 'primary'
  }
  if (action === 'cancel' || action === 'delete') {
    color = 'secondary'
  }

  if (action === 'postpone' && isPostponed) {
    disabled = true
  }

  const handleConfirm = (): void => {
    if (customAction) {
      customAction()
    } else if (events.length) {
      switch (action) {
        case 'publish':
          publishEvents(events)
          break
        case 'update':
          // TODO: this is not actually true, but the update implementation is
          // handled with customAction in a very opaque manner
          console.warn(`action '${action}' is not implemented!`)
          break
        case 'cancel':
          cancelEvents(events)
          break
        case 'delete':
          deleteAPIEvents(events)
          break
        default:
          break
      }
      props.runAfterAction(action, event)
      setShowConfirmationModal(false)
    }
  }

  const handleOnClick = async (): Promise<void> => {
    if (askConfirmation) {
      setShowConfirmationModal(true)
    } else {
      await customAction()
    }
  }

  const tooltipMessage = explanationId
    ? intl.formatMessage({ id: explanationId })
    : ''

  return (
    <Tooltip title={tooltipMessage}>
      <span>
        {showTermsCheckbox && (
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={agreedToTerms}
                onChange={(e): void => setAgreedToTerms(e.target.checked)}
              />
            }
            label={
              <React.Fragment>
                <FormattedMessage id={'terms-agree-text'} />
                &nbsp;
                <Link to={'/terms'} target="_blank">
                  <FormattedMessage id={'terms-agree-link'} />
                </Link>
              </React.Fragment>
            }
          />
        )}
        <Button
          variant="contained"
          disabled={disabled}
          endIcon={
            loading &&
            isSaveButton(action) && (
              <CircularProgress color="inherit" size={25} />
            )
          }
          className={`editor-${action}-button`}
          onClick={handleOnClick}
          color={color}
        >
          <FormattedMessage id={buttonLabel} />
        </Button>
        {showConfirmationModal && (
          <ConfirmationModal
            loading={loading}
            msg={message}
            style={modalStyle}
            actionButtonLabel={actionButtonLabel}
            cancel={() => setShowConfirmationModal(false)}
            confirm={handleConfirm}
            additionalMarkup={getConfirmationMarkup(
              extraMessage,
              warningMessage,
              intl,
              events
            )}
          />
        )}
      </span>
    </Tooltip>
  )
}

// Disabled the error, because we will migrate this later.
// TODO: Migrate to redux toolkit

const mapStateToProps = (state: any) => ({
  editor: state.editor,
  user: state.user,
})

// TODO: Use Redux toolkit
export const ConnectedEventActionButton =
  connect(mapStateToProps)(EventActionButton)
