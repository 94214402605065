export const helBrandColors = {
  coatBlue: {
    main: '#0072c6',
    lighter: '#cce2f3',
    light: '#80b8e2',
    dark: '#000098',
    contrastText: '#fff',
  },
  coatGold: {
    main: '#c2a251',
    lighter: '#f2ecdc',
    light: '#e0d0a8',
    dark: '#977b34',
    contrastText: '#000',
  },
  coatSilver: {
    main: '#dedfe1',
  },
  brick: {
    main: '#bd2719',
    lighter: '#f1d3d1',
    light: '#de938c',
    dark: '#99160a',
    contrastText: '#fff',
  },
  bus: {
    main: '#0000bf',
    lighter: '#ccccf2',
    light: '#7f7fdf',
    dark: '#000098',
    contrastText: '#fff',
  },
  copper: {
    main: '#00d7a7',
    lighter: '#ccf7ed',
    light: '#80ebd3',
    dark: '#01b78e',
    contrastText: '#000',
  },
  engel: {
    main: '#ffe977',
    lighter: '#fffae3',
    light: '#fff4bb',
    dark: '#f5da4e',
    contrastText: '#000',
  },
  fog: {
    main: '#9fc9eb',
    lighter: '#ebf4fb',
    light: '#cfe4f5',
    dark: '#78a4c7',
    contrastText: '#000',
  },
  metro: {
    main: '#fd4f00',
    lighter: '#fedbcc',
    light: '#fea780',
    dark: '#ca3f00',
    contrastText: '#000',
  },
  summer: {
    main: '#ffc61e',
    lighter: '#fff3d2',
    light: '#ffe28e',
    dark: '#e2ab0a',
    contrastText: '#000',
  },
  suomenlinna: {
    main: '#f5a3c7',
    lighter: '#fdecf3',
    light: '#fad1e3',
    dark: '#de7ba6',
    contrastText: '#000',
  },
  tram: {
    main: '#009246',
    lighter: '#cce9da',
    light: '#80c8a2',
    dark: '#007438',
    contrastText: '#000',
  },
  gray: {
    black: '#000',
    white: '#fff',
    black5: '#f1f1f1',
    black10: '#e5e5e5',
    black20: '#cccccc',
    black30: '#b2b2b2',
    black40: '#999898',
    black50: '#808080',
    black60: '#666666',
    black70: '#4c4c4c',
    black80: '#333333',
    black90: '#1a1a1a',
  },
  error: {
    main: '#c4123e',
    light: '#f6e2e6',
  },
  success: {
    main: '#007a64',
    light: '#e2f5f3',
  },
  alert: {
    main: '#ffda07',
    light: '#fff4b4',
    dark: '#986700',
  },
  info: {
    main: '#007293',
    light: '#dcf1f5',
  },
  gray2: {
    black: '#343434',
    light: '#ebedf1',
    main: '#abb2bd',
    dark: '#525a65',
    contrastText: '#fff',
  },
}
