import React from 'react'
import {
  mapHobbyCategoriesToForm,
  mapKeywordSetToForm,
} from '../../utils/apiDataMapping'
import { OptionGroup } from './Fields/OptionGroup'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { UIEvent } from '../../types/apiTypes'

export const Topics: React.FC<{ values: UIEvent }> = ({ values }) => {
  const keywordSets = useSelector(
    (state: RootState) => state.editor.keywordSets
  )
  const locale = useSelector((state: RootState) => state.userLocale.locale)

  const topics = mapKeywordSetToForm(keywordSets, 'espoo:topics', locale)
    .filter((topicKeyword) => values.keywords.includes(topicKeyword.value))
    .map((topicKeyword) => topicKeyword.label)

  const librarytopics = mapKeywordSetToForm(
    keywordSets,
    'espoo:librarytopics',
    locale
  )
    .filter((topicKeyword) =>
      values.libraryKeywords?.includes(topicKeyword.value)
    )
    .map((topicKeyword) => topicKeyword.label)

  // This should be string[]
  // adn OptionGroup shouldn't use getStringWithLocale, but rather plain string.
  const hobbyCategories = mapHobbyCategoriesToForm(
    values.hobbyCategories,
    locale
  )

  return (
    <>
      <OptionGroup values={topics} labelKey="topics" />
      <OptionGroup values={hobbyCategories} labelKey="event-hobby-category" />
      <OptionGroup values={librarytopics} labelKey="event-library-topic" />
    </>
  )
}
