import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { LocaleState, UserLocale } from './types'

const initialState: LocaleState = {
  locale: 'fi',
}

export const userLocaleSlice = createSlice({
  name: 'userLocale',
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<UserLocale>) => {
      state.locale = action.payload
    },

    resetLocale: (state) => {
      state.locale = initialState.locale
    },
  },
})

export const { setLocale, resetLocale } = userLocaleSlice.actions

export default userLocaleSlice.reducer
