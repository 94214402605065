import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers'
import moment from 'moment-timezone'
import { useIntl } from 'react-intl'
import { isNil } from 'lodash'

import './HelDatePicker.scss'
import { AccessTime } from '@material-ui/icons'

export const HelDatePicker = ({
  type = 'date',
  name,
  label,
  disabled,
  disablePast = false,
  defaultValue,
  placeholder,
  minDate,
  maxDate,
  onChange = (_) => {}, // (value: moment.MomentInput) => void
  required,
}) => {
  const [date, setDate] = useState(null)
  const intl = useIntl()

  useEffect(() => {
    if (!isNil(defaultValue) && defaultValue !== '') {
      setDate(moment(defaultValue).tz('Europe/Helsinki'))
    } else {
      setDate(null)
    }
  }, [defaultValue])

  const commonProps = {
    fullWidth: true,
    disabled,

    label: label
      ? typeof label === 'object'
        ? label
        : intl.formatMessage({ id: label })
      : null,
    name,
    value: date,
    variant: 'inline',
    onChange,
    required,
  }

  return (
    <div className="hel-date-picker--container">
      {type === 'date' && (
        <KeyboardDatePicker
          strictCompareDates
          disablePast={disablePast}
          format={'DD.MM.YYYY'}
          invalidDateMessage={intl.formatMessage({ id: 'invalid-date-format' })}
          minDate={minDate}
          maxDate={maxDate}
          minDateMessage={intl.formatMessage({
            id: 'validation-afterStartTimeAndInFuture',
          })}
          maxDateMessage=""
          placeholder={
            placeholder || intl.formatMessage({ id: 'date-field-placeholder' })
          }
          {...commonProps}
        />
      )}
      {type === 'time' && (
        <KeyboardTimePicker
          ampm={false}
          format={'HH.mm'}
          invalidDateMessage={intl.formatMessage({ id: 'invalid-time-format' })}
          keyboardIcon={<AccessTime />}
          placeholder={
            placeholder || intl.formatMessage({ id: 'time-field-placeholder' })
          }
          {...commonProps}
        />
      )}
      {type === 'date-time' && (
        <KeyboardDateTimePicker
          strictCompareDates
          disablePast={disablePast}
          ampm={false}
          format={'DD.MM.YYYY HH.mm'}
          invalidDateMessage={intl.formatMessage({ id: 'invalid-date-format' })}
          minDate={minDate}
          maxDate={maxDate}
          minDateMessage={intl.formatMessage({
            id: 'validation-afterStartTimeAndInFuture',
          })}
          maxDateMessage=""
          placeholder={
            placeholder ||
            intl.formatMessage({ id: 'date-time-field-placeholder' })
          }
          {...commonProps}
        />
      )}
    </div>
  )
}

HelDatePicker.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.oneOf(['date', 'time', 'date-time']),
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  disablePast: PropTypes.bool,
  required: PropTypes.bool,
}
