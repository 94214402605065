import '../MultiValueField.scss'
import React from 'react'
import { MultiLanguageField } from '../../../types/apiTypes'
import { MultiLanguageFieldToArray } from '../../../utils/conversions'
import { NoValue } from './NoValue'
import { LabelKey } from './LabelKey'
import { HtmlValue } from './HtmlValue'

export const MultiLanguageHtmlValue: React.FC<{
  labelKey: string
  value?: MultiLanguageField
}> = ({ labelKey, value }) => {
  const valueAsArray = MultiLanguageFieldToArray(value)
  if (valueAsArray.length === 0) {
    return (
      <div className="multi-value-field">
        <LabelKey labelKey={labelKey} />
        <div>
          <NoValue labelKey={labelKey} />
        </div>
      </div>
    )
  }

  // Determine column size depending on the amount of language options
  const className = {
    [valueAsArray.length]: 'col-md-4',
    1: 'col-md-12',
    2: 'col-md-6',
    3: 'col-md-4',
  }[valueAsArray.length]

  return (
    <div className="multi-value-field">
      <LabelKey labelKey={labelKey} />
      <div className="row">
        <HtmlValue lang="fi" rawHtml={value?.fi} className={className} />
        <HtmlValue lang="sv" rawHtml={value?.sv} className={className} />
        <HtmlValue lang="en" rawHtml={value?.en} className={className} />
      </div>
    </div>
  )
}
