import './TextEditor.scss'
import 'quill/dist/quill.snow.css'
import React, { useRef } from 'react'
import ReactQuill, { UnprivilegedEditor } from 'react-quill'
import DeltaStatic from 'quill'
import Sources from 'quill'
import quillModules from './QuillModules'
import { Delta } from 'quill/core'
import { ValidationPopover } from '../ValidationPopover/ValidationPopover'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

const plainTextPaster = (_: Node, delta: Delta): Delta => {
  delta.ops = delta.ops
    .filter((op) => op.insert && typeof op.insert === 'string')
    .map((op) => ({ insert: op.insert }))

  return delta
}
/**
 * Snow themed Quill Text Editor.
 */
export const TextEditor: React.FC<{
  label: string
  name: string
  defaultValue?: string | null
  onChange?: (
    value: string,
    delta: DeltaStatic,
    source: Sources,
    editor: UnprivilegedEditor
  ) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  labelRef?: any
  validationErrors: string[]
  index: string
}> = ({
  label,
  labelRef,
  validationErrors,
  index,
  name,
  defaultValue,
  onChange,
}) => {
  const ref = useRef<ReactQuill | null>(null)
  const isLoading = useSelector((state: RootState) => state.editor.loading)

  return (
    <div className="text-editor">
      <label htmlFor={name} ref={labelRef}>
        {`${label} *`}
        {labelRef && (
          <ValidationPopover
            index={index}
            anchor={labelRef.current}
            validationErrors={validationErrors}
          />
        )}
      </label>
      <ReactQuill
        id={name}
        ref={ref}
        className="ql-text-editor"
        theme="snow"
        onFocus={(_, __, ___) => {
          if (!isLoading)
            ref.current
              ?.getEditor()
              .clipboard.addMatcher(Node.ELEMENT_NODE, plainTextPaster)
        }}
        value={defaultValue ?? ''}
        onChange={onChange}
        modules={quillModules}
      />
    </div>
  )
}
