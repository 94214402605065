import React from 'react'
import { useIntl } from 'react-intl'

export const LabelKey: React.FC<{ labelKey: string }> = ({ labelKey }) => {
  const intl = useIntl()

  return (
    <div>
      <label>{intl.formatMessage({ id: labelKey })}</label>
    </div>
  )
}
