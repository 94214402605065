import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment-timezone'
import { isNil, isEmpty } from 'lodash'
import {
  Button,
  IconButton,
  TextField,
  Typography,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core'
import { Add, Close } from '@material-ui/icons'

import './RecurringEvent.scss'
import { DayCheckbox } from './DayCheckbox'
import { setEventData, sortSubEvents } from '../../actions/editor'
import { validationRules } from '../../validation/validationRules'
import { ValidationPopover } from '../ValidationPopover/ValidationPopover'
import { constants } from '../../constants'
import { HelMaterialTheme } from '../../themes/material-ui'
import { HelDatePicker } from '../HelFormFields/HelDatePicker'

const { VALIDATION_RULES } = constants

const RepetitionTextField = withStyles((theme) => ({
  root: {
    margin: 0,
    width: 40,
    '& input': {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      textAlign: 'center',
    },
  },
}))(TextField)

/* eslint-disable @typescript-eslint/unbound-method */
export class RecurringEvent extends React.Component {
  static contextTypes = {
    intl: PropTypes.object,
    dispatch: PropTypes.func,
  }

  static propTypes = {
    values: PropTypes.object,
    toggle: PropTypes.func,
    validationErrors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    formType: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.onCheckboxChange = this.onCheckboxChange.bind(this)
    this.weekIntervalChange = this.weekIntervalChange.bind(this)
    this.onTimeChange = this.onTimeChange.bind(this)

    this.repetitionRef = React.createRef()
    this.playDateRef = React.createRef()
    this.startDateRef = React.createRef()
    this.startTimeRef = React.createRef()
    this.endDateRef = React.createRef()

    const { values } = props
    const dateInvalid = (date) => isEmpty(date) || !moment(date).isValid()
    const recurrenceTime = (start_time, end_time) =>
      this.props.formType === 'update'
        ? moment(start_time).set({
            hour: moment(end_time).hour(),
            minute: moment(end_time).minute(),
          })
        : moment(end_time)

    this.state = {
      weekInterval: 1,
      daysSelected: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      },
      recurringStartDate: dateInvalid(values.start_time)
        ? null
        : moment(values.start_time),
      recurringStartTime: dateInvalid(values.start_time)
        ? null
        : moment(values.start_time),
      recurringEndDate: null,
      recurringEndTime: dateInvalid(values.end_time)
        ? null
        : recurrenceTime(values.start_time, values.end_time),
      errors: {
        weekInterval: null,
        daysSelected: null,
        recurringStartDate: null,
        recurringEndDate: null,
      },
    }
  }

  clearErrors = () => {
    this.setState({
      errors: {
        weekInterval: null,
        daysSelected: null,
        recurringStartDate: null,
        recurringEndDate: null,
      },
    })
  }

  onChange(type, value) {
    this.clearErrors()
    this.setState({
      [type]: value,
    })
  }

  onTimeChange(name, time) {
    this.clearErrors()
    this.setState({
      [name]: time,
    })
  }

  generateEvents = () => {
    const {
      recurringStartDate,
      recurringStartTime,
      recurringEndDate,
      recurringEndTime,
      daysSelected,
      weekInterval,
    } = this.state
    const errors = this.getValidationErrors()

    // handle validation errors
    if (errors.length > 0) {
      this.setState((state) => ({
        errors: errors.reduce(
          (acc, error) => ({ ...acc, [error.key]: error.rule }),
          { ...state.errors }
        ),
      }))
      // if no validation errors, format datetime
    } else if (recurringStartDate && recurringEndDate && weekInterval > 0) {
      const days = Object.keys(daysSelected).reduce(
        (acc, key) => (daysSelected[key] ? { ...acc, [key]: key } : acc),
        {}
      )
      const dayCodes = {
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
        sunday: 7,
      }

      let eventLength
      if (recurringEndTime) {
        eventLength = moment(recurringEndTime).diff(
          recurringStartTime,
          'minutes'
        )
      }

      const formattedRecurringStartTime = {
        hours: recurringStartTime ? recurringStartTime.hours() : 0,
        minutes: recurringStartTime ? recurringStartTime.minutes() : 0,
      }
      const formattedRecurringEndTime = {
        hours: recurringEndTime ? recurringEndTime.hours() : 0,
        minutes: recurringEndTime ? recurringEndTime.minutes() : 0,
      }

      let count = 1

      for (const key in days) {
        // eslint-disable-next-line no-prototype-builtins
        if (days.hasOwnProperty(key)) {
          const day = dayCodes[days[key]]
          // find the first valid matching weekday
          let firstMatchWeekday
          const recurrenceStart = moment(recurringStartDate).isSame(
            // eslint-disable-next-line no-undef
            moment(values.start_time)
          )
            ? moment(recurringStartDate).endOf('day')
            : moment(recurringStartDate).subtract(1, 'day').endOf('day')
          const recurrenceEnd = moment(recurringEndDate).endOf('day')
          for (let i = 0; i <= weekInterval; i += 1) {
            const startDateWeekday = moment(recurringStartDate).isoWeekday(
              day + i * 7
            )
            if (startDateWeekday.isBetween(recurrenceStart, recurrenceEnd)) {
              firstMatchWeekday = startDateWeekday
              break
            }
          }
          // calculate all the following weekdays using weekInterval as step
          for (
            let matchWeekday = firstMatchWeekday;
            matchWeekday.isBetween(recurrenceStart, recurrenceEnd);
            matchWeekday = matchWeekday.add(weekInterval, 'week')
          ) {
            const obj = {}
            const key2 =
              Object.keys(this.props.values.sub_events ?? 0).length + count
            count += 1
            const startTime = matchWeekday
              .hours(formattedRecurringStartTime.hours)
              .minutes(formattedRecurringStartTime.minutes)
            let endTime
            if (recurringEndTime) {
              endTime = { ...startTime }
              endTime = moment(endTime)
                .add(eventLength, 'minutes')
                .hours(formattedRecurringEndTime.hours)
                .minutes(recurringEndTime.minutes)
            }

            obj[key2] = {
              start_time: moment
                .tz(startTime, 'Europe/Helsinki')
                .utc()
                .toISOString(),
              end_time: endTime
                ? moment.tz(endTime, 'Europe/Helsinki').utc().toISOString()
                : undefined,
            }
            this.context.dispatch(setEventData(obj, key2))
            this.props.toggle()
          }
        }
      }
      this.context.dispatch(sortSubEvents())
    }
  }

  getValidationErrors = () => {
    const { recurringStartDate, recurringEndDate, daysSelected, weekInterval } =
      this.state
    const endDateTestObject = {
      type: 'end_date',
      start_time: !isNil(recurringStartDate)
        ? moment(recurringStartDate).format('YYYY-MM-DD')
        : recurringStartDate,
      end_time: !isNil(recurringEndDate)
        ? moment(recurringEndDate).subtract(1, 'day').format('YYYY-MM-DD')
        : recurringEndDate,
    }
    const intervalTestObject = {
      type: 'day_within_interval',
      daysSelected,
      start_day_index: moment(recurringStartDate).weekday(),
      end_day_index: moment(recurringEndDate).weekday(),
    }

    return [
      this.validate(
        'weekInterval',
        VALIDATION_RULES.IS_MORE_THAN_ONE,
        weekInterval
      ),
      this.validate(
        'daysSelected',
        VALIDATION_RULES.AT_LEAST_ONE_IS_TRUE,
        daysSelected
      ),
      this.validate(
        'daysSelected',
        VALIDATION_RULES.DAY_WITHIN_INTERVAL,
        intervalTestObject
      ),
      this.validate(
        'recurringStartDate',
        VALIDATION_RULES.REQUIRED,
        recurringStartDate
      ),
      this.validate(
        'recurringEndDate',
        VALIDATION_RULES.REQUIRED,
        recurringEndDate
      ),
      this.validate(
        'recurringEndDate',
        VALIDATION_RULES.AFTER_START_TIME,
        endDateTestObject
      ),
    ].filter((item) => !item.passed)
  }

  validate = (key, type, value) => {
    const { recurringStartDate, recurringEndDate } = this.state

    if (value && value.type === 'end_date') {
      return {
        key,
        rule: type,
        passed: validationRules[type](value, value.end_time),
      }
    }

    if (value && value.type === 'day_within_interval') {
      return {
        key,
        rule: type,
        passed: validationRules[type](
          value,
          moment(recurringEndDate).diff(recurringStartDate, 'days')
        ),
      }
    }

    if (typeof validationRules[type] === 'function') {
      return {
        key,
        rule: type,
        passed: validationRules[type](null, value),
      }
    }

    return {}
  }

  onCheckboxChange(key, value) {
    this.clearErrors()
    const newDays = {
      ...this.state.daysSelected,
      [key]: value,
    }
    this.setState({ daysSelected: newDays })
  }

  weekIntervalChange(event) {
    this.setState({ weekInterval: event.target.value })
  }

  generateCheckboxes(days) {
    const dayElements = []

    for (const key in days) {
      // eslint-disable-next-line no-prototype-builtins
      if (days.hasOwnProperty(key)) {
        dayElements.push(
          <DayCheckbox
            key={key}
            day={key}
            onChange={this.onCheckboxChange}
            defaultChecked={days[key]}
          />
        )
      }
    }
    return dayElements
  }

  UNSAFE_componentWillMount() {
    if (this.props.values.start_time) {
      const newDays = { ...this.state.daysSelected }

      for (const key in newDays) {
        // eslint-disable-next-line no-prototype-builtins
        if (newDays.hasOwnProperty(key)) {
          if (
            key ===
            moment(this.props.values.start_time)
              .locale('en')
              .format('dddd')
              .toLowerCase()
          ) {
            newDays[key] = true
          }
        }
      }
      this.setState({ daysSelected: newDays })
    }
  }

  render() {
    const { recurringStartDate, recurringEndDate, errors } = this.state
    const days = this.generateCheckboxes(this.state.daysSelected)

    return (
      <Dialog open fullWidth maxWidth="lg" onClose={this.props.toggle}>
        <DialogTitle>
          <FormattedMessage id="event-add-recurring" />
          <IconButton onClick={this.props.toggle}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-xs-12 col-sm-12">
              <Typography variant="h6">
                <FormattedMessage id="repetition-interval-label" />
                <span>&thinsp;{'*'}</span>
              </Typography>

              <div className="repetition-count" ref={this.repetitionRef}>
                <FormattedMessage id="repeated" />
                <RepetitionTextField
                  value={this.state.weekInterval}
                  onFocus={(event) => event.target.select()}
                  onBlur={() => this.clearErrors()}
                  onChange={this.weekIntervalChange}
                />
                <FormattedMessage id="repetition-interval" />
                <ValidationPopover
                  inModal
                  placement={'right-end'}
                  anchor={this.repetitionRef.current}
                  validationErrors={
                    errors.weekInterval && [errors.weekInterval]
                  }
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-12">
              <Typography
                ref={this.playDateRef}
                style={{
                  display: 'inline-block',
                  marginTop: HelMaterialTheme.spacing(2),
                }}
                variant="h6"
              >
                <FormattedMessage id="play-date-label" />
                <span>&thinsp;{'*'}</span>
              </Typography>
              <ValidationPopover
                inModal
                placement={'right-end'}
                anchor={this.playDateRef.current}
                validationErrors={errors.daysSelected && [errors.daysSelected]}
              />
            </div>
          </div>
          <div className="row">{days}</div>

          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <HelDatePicker
                required={true}
                name="recurringStartDate"
                label={
                  <span ref={this.startDateRef}>
                    <FormattedMessage id="repetition-begin" />
                  </span>
                }
                defaultValue={recurringStartDate}
                maxDate={
                  recurringEndDate ? moment(recurringEndDate) : undefined
                }
                onChange={(value) => this.onChange('recurringStartDate', value)}
              />
              <ValidationPopover
                inModal
                placement={'right'}
                anchor={this.startDateRef.current}
                validationErrors={
                  errors.recurringStartDate && [errors.recurringStartDate]
                }
              />
            </div>
            <div className="col-xs-12 col-sm-6">
              <HelDatePicker
                required={true}
                name="recurringEndDate"
                label={
                  <span ref={this.endDateRef}>
                    <FormattedMessage id="repetition-end" />
                  </span>
                }
                defaultValue={recurringEndDate}
                disablePast
                minDate={
                  recurringStartDate ? moment(recurringStartDate) : undefined
                }
                onChange={(value) => this.onChange('recurringEndDate', value)}
              />
              <ValidationPopover
                inModal
                placement={'right'}
                anchor={this.endDateRef.current}
                validationErrors={
                  errors.recurringEndDate && [errors.recurringEndDate]
                }
              />
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <HelDatePicker
                type={'time'}
                name="recurringStartTime"
                label={
                  <span ref={this.startTimeRef}>
                    <FormattedMessage id="repetition-start-time" />
                  </span>
                }
                defaultValue={this.state.recurringStartTime}
                onChange={(value) =>
                  this.onTimeChange('recurringStartTime', value)
                }
              />
              <ValidationPopover
                inModal
                anchor={this.startTimeRef.current}
                validationErrors={
                  errors.recurringStartTime && [errors.recurringStartTime]
                }
              />
            </div>

            <div className="col-xs-6 col-sm-6">
              <HelDatePicker
                type={'time'}
                name="recurringEndTime"
                label={<FormattedMessage id="repetition-end-time" />}
                defaultValue={this.state.recurringEndTime}
                onChange={(value) =>
                  this.onTimeChange('recurringEndTime', value)
                }
              />
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-12">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => this.generateEvents()}
                style={{ margin: `${HelMaterialTheme.spacing(2)}px 0` }}
                startIcon={<Add />}
              >
                <FormattedMessage id="add-more" />
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}
/* eslint-enable @typescript-eslint/unbound-method */
