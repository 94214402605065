import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { AppAction, AppState } from './types'

const initialState: AppState = {
  flashMsg: undefined, // Used to control showing flash messages
  loginModal: false,
  loginType: localStorage.getItem('loginType'),
  pendingRequests: 0,
  pendingDrafts: 0,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setFlashMsg: (state, { payload }: PayloadAction<AppAction>) => {
      if (payload.msg && payload.msg.length) {
        state.flashMsg = {
          msg: payload.msg,
          style: payload.style,
          data: payload.data,
          sticky: payload.sticky,
          action: payload.action,
        }
      } else {
        state.flashMsg = undefined
      }
    },
    clearFlashMsg: (state) => {
      state.flashMsg = undefined
    },
    setLoginModal: (state, { payload }: PayloadAction<boolean>) => {
      state.loginModal = payload
    },
    clearLoginType: (state) => {
      state.loginType = null
    },
    setPendingRequests: (state, { payload }: PayloadAction<number>) => {
      state.pendingRequests = payload
    },
    decreasePendingRequests: (state) => {
      state.pendingRequests -= 1
    },
    setPendingDrafts: (state, { payload }: PayloadAction<number>) => {
      state.pendingDrafts = payload
    },
    decreasePendingDrafts: (state) => {
      state.pendingDrafts -= 1
    },
  },
})

export const {
  setFlashMsg,
  clearFlashMsg,
  setLoginModal,
  clearLoginType,
  setPendingRequests,
  decreasePendingRequests,
  setPendingDrafts,
  decreasePendingDrafts,
} = appSlice.actions

export default appSlice.reducer
