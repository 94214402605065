import PropTypes from 'prop-types'
import React from 'react'
import { isNil } from 'lodash'
import { TableCell } from '@material-ui/core'

export const PublisherCell = (props) => {
  const { publisher, createdBy, eventName } = props
  let creator = null
  let email = null

  if (!isNil(createdBy)) {
    const searchKey = ' - '
    const splitCreator = createdBy.split(searchKey)
    splitCreator[0] += searchKey
    ;[creator, email] = splitCreator
  }

  return (
    <TableCell className="publisher-cell">
      <span>
        {publisher}
        {creator && email && (
          <React.Fragment>
            <br />
            <small>
              {creator}
              <a
                /* eslint-disable-next-line max-len */
                href={`mailto:${email}?subject=Tapahtumailmoituksesi ${eventName ? `"${eventName}" ` : ''}vaatii toimenpiteitä – Helsingin kaupunki`}
              >
                {email}
              </a>
            </small>
          </React.Fragment>
        )}
      </span>
    </TableCell>
  )
}

PublisherCell.propTypes = {
  publisher: PropTypes.string,
  createdBy: PropTypes.string,
  eventName: PropTypes.string,
}
