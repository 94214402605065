import React from 'react'
import { Language } from '@material-ui/icons'
import Select, { ValueType } from 'react-select'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import { SupportedLocales } from '../../store/userLocale/constants'
import { useDispatch, useSelector } from 'react-redux'
import { setLocale as setLocaleAction } from '../../store/userLocale'
import { RootState } from '../../store'
import {
  HelLanguageSelectStyles,
  HelSelectTheme,
} from '../../themes/react-select'

const LanguageSelect: React.FC = () => {
  const dispatch = useDispatch()
  const userLocale = useSelector((state: RootState) => state.userLocale)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setLocale = (locale: any): void => {
    dispatch(setLocaleAction(locale))
  }

  const changeLanguage = (
    selectedOption: ValueType<
      {
        value: string
        label: string
      },
      false
    >
  ): void => {
    setLocale(selectedOption?.value ?? 'fi')
    moment.locale(selectedOption?.value)
    momentTimezone.locale(selectedOption?.value)
  }

  const getLanguageOptions = SupportedLocales.map((item) => ({
    label: item.toUpperCase(),
    value: item,
  }))

  return (
    <div className="helsinki-bar__language-button">
      <div className="language-selector">
        <Language className="language-icon" />
        <Select
          className="language-select"
          isClearable={false}
          isSearchable={false}
          value={{
            label: userLocale.locale.toUpperCase(),
            value: userLocale.locale,
          }}
          options={getLanguageOptions}
          onChange={changeLanguage}
          styles={HelLanguageSelectStyles}
          theme={HelSelectTheme}
        />
      </div>
    </div>
  )
}

export default LanguageSelect
