import { filter, keys, map } from 'lodash'

import { API } from '../api'

// TODO: configure somewhere properly.
const SKIP_FIELDS = new Set([
  'location',
  'keywords',
  'placeKeywords',
  'audience',
  'languages',
  'in_language',
  'sub_events',
])

// Enumerate all the property names of an object recursively.
// NOTE! Generator functions were introduced in ES6. It seems that the TypeScript Compiler can't transpile generator
// functions to ES5. That's why using generator functions requires targeting ES6 in tsconfig.json. Otherwise, calling
// propertyNames will fail silently.
function* propertyNames(obj) {
  for (const name of keys(obj)) {
    const val = obj[name]
    if (val instanceof Object && !SKIP_FIELDS.has(name)) {
      yield* propertyNames(val)
    }
    if (val && val !== '') {
      yield name
    }
  }
}

// Given an event object, get all the languages which
// have any attributes filled.
export const getContentLanguages = (event) => {
  if (!event) {
    return []
  }
  const orderedLanguages = map(API.eventInfoLanguages(), (l) => l.value)
  const languages = new Set(orderedLanguages)
  const foundLanguages = new Set()

  for (const name of propertyNames(event)) {
    if (foundLanguages.size === languages.size) {
      break
    }
    if (languages.has(name)) {
      foundLanguages.add(name)
    }
  }
  return filter(orderedLanguages, (l) => foundLanguages.has(l))
}
