import { getEnvironment } from './env'

type FeatureFlags = {
  userManagement: boolean
  multiLogin: boolean
}

const env = getEnvironment()

export const features: FeatureFlags = {
  userManagement:
    env === 'local' ||
    env === 'dev' ||
    env === 'test' ||
    env === 'staging' ||
    env === 'prod',
  multiLogin:
    env === 'local' ||
    env === 'dev' ||
    env === 'test' ||
    env === 'staging' ||
    env === 'prod',
}
