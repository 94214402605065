import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { RootState } from '../../store'

/** Defines document head for the application */
export const DocumentHead: React.FC = () => {
  const locale = useSelector((state: RootState) => state.userLocale.locale)
  const intl = useIntl()

  return (
    <Helmet>
      <html lang={locale} />
      <title>{intl.formatMessage({ id: 'page-name' })}</title>
      <link rel="canonical" href={intl.formatMessage({ id: 'page-url' })} />
    </Helmet>
  )
}
