import { FormControlLabel, Radio } from '@material-ui/core'
import RadioGroup from '@material-ui/core/RadioGroup'
import React, { useEffect, useMemo, useState } from 'react'
import { UmbrellaEventPicker } from './UmbrellaEventPicker'
import { useDispatch, useSelector } from 'react-redux'
import { fetchEvents } from '../../../utils/events'
import {
  APIEvent,
  EventsMetaFields,
  Keyword,
  SuperEventType,
  UIEvent,
  WrappedId,
} from '../../../types/apiTypes'
import { mapAPIDataToUIFormat } from '../../../utils/formDataMapping'
import {
  setSuperEvent,
  setSuperEventOrg,
  setSuperEventType,
} from '../../../store/editor'
import { RootState } from '../../../store'
import { getStringWithLocale } from '../../../utils/locale'
import moment from 'moment'
import { AutoComplete } from './AutoComplete'
import { useIntl } from 'react-intl'
import { constants } from '../../../constants'
import { debounce } from 'lodash'
import { scrollToTop } from '../../../utils/helpers'
import { Link } from 'react-router-dom'

export const UmbrellaSelector = ({
  updateExisting,
  isOldSubEvent,
  superEvent,
}: {
  updateExisting: boolean
  isOldSubEvent: boolean
  superEvent: UIEvent
}) => {
  const [umbrellaSelection, setUmbrellaSelection] =
    useState<string>('isSingleEvent')
  const [futureSubEvents, setFutureSubEvents] =
    useState<(UIEvent & WrappedId)[]>()
  const keywordSets = useSelector(
    (state: RootState) => state.editor.keywordSets
  ) as unknown as Keyword[][]

  const intl = useIntl()
  const [startedAsSingle, setStartedAsSingle] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const locale = useSelector((state: RootState) => state.userLocale.locale)
  const formValues = useSelector((state: RootState) => state.editor.values)
  const dispatch = useDispatch()
  const [autocompleteString, setAutocompleteString] = useState('')
  const globalLoading = useSelector(
    (state: RootState) => state.editor.values.name
  )

  const fetchFutureEvents = useMemo(() => {
    if (
      (futureSubEvents != null && futureSubEvents.length > 0) ||
      keywordSets.length === 0
    )
      return

    const fetchData = (
      text: string,
      super_event_type?: string,
      start?: string,
      end?: string
    ) => {
      setIsLoading(true)
      fetchEvents({
        values: {
          page_size: constants.pageSize,
          text: text,
          umbrella: super_event_type === 'umbrella',
          start,
          end,
        },
      }).then((x) => {
        const validSubEvents = x.data.data.map(
          (x: APIEvent & EventsMetaFields) => ({
            ...mapAPIDataToUIFormat(x, keywordSets),
            '@id': x['@id'],
          })
        )
        setFutureSubEvents(validSubEvents)
        setIsLoading(false)
      })
    }
    return debounce(fetchData, 1000)
  }, [keywordSets])

  useEffect(() => {
    if (fetchFutureEvents && autocompleteString.length > 2)
      fetchFutureEvents(
        autocompleteString,
        formValues.super_event_type,
        formValues.start_time,
        formValues.end_time
      )
  }, [fetch, autocompleteString])

  useEffect(() => {
    let selection
    if (formValues.super_event_type) {
      selection = 'isUmbrellaEvent'
    } else if (!!formValues.super_event || umbrellaSelection === 'isSubEvent') {
      selection = 'isSubEvent'
    } else {
      selection = 'isSingleEvent'
    }
    setUmbrellaSelection(selection)
    if (!loaded && globalLoading != null) {
      setStartedAsSingle(selection === 'isSingleEvent')
      setLoaded(true)
    }
  }, [formValues.super_event_type, formValues.super_event])

  return (
    <div>
      <RadioGroup
        name="UmbrellaSelector"
        value={umbrellaSelection}
        onChange={(event) => {
          dispatch(
            setSuperEventType({
              newType:
                event.target.value === 'isUmbrellaEvent'
                  ? 'umbrella'
                  : undefined,
            })
          )
          setUmbrellaSelection(event.target.value)
        }}
      >
        <FormControlLabel
          value="isSingleEvent"
          control={<Radio />}
          test-label="single-event"
          label={intl.formatMessage({ id: 'single-event-radio' })}
          disabled={
            !(updateExisting && startedAsSingle) &&
            (formValues.super_event_type === 'recurring' ||
              (formValues.super_event_type === 'umbrella' && updateExisting) ||
              (updateExisting && isOldSubEvent))
          }
        />
        <FormControlLabel
          value="isUmbrellaEvent"
          control={<Radio />}
          test-label="umbrella-event"
          label={intl.formatMessage({ id: 'umbrella-event-radio' })}
          disabled={
            updateExisting && formValues.super_event != null && !startedAsSingle
          }
        />
        {umbrellaSelection === 'isUmbrellaEvent' &&
          formValues.super_event_type === 'umbrella' && (
            <UmbrellaEventPicker
              isLoading={isLoading}
              futureSubEvents={futureSubEvents ?? []}
              setACValue={(val: string) => {
                setAutocompleteString(val)
              }}
            />
          )}
        <FormControlLabel
          value="isSubEvent"
          control={<Radio />}
          test-label="sub-event"
          label={intl.formatMessage({ id: 'sub-event-radio' })}
          disabled={
            !(updateExisting && startedAsSingle) &&
            (formValues.super_event_type === 'recurring' ||
              ((!startedAsSingle ||
                formValues.super_event_type === 'umbrella') &&
                updateExisting))
          }
        />
        {umbrellaSelection === 'isSubEvent' &&
          (isOldSubEvent && updateExisting ? (
            <div className="links-to-events">
              <p className="links-to-events--text">
                <Link to={`/event/${superEvent.id}`} onClick={scrollToTop}>
                  <span>{getStringWithLocale(superEvent, 'name', locale)}</span>
                </Link>
              </p>
            </div>
          ) : (
            <AutoComplete
              setACValue={(val: string) => {
                setAutocompleteString(val)
              }}
              pickHandler={(superEvent: string, extra?: string) => {
                dispatch(setSuperEvent({ superEventId: { '@id': superEvent } }))
                extra && dispatch(setSuperEventOrg(extra))
              }}
              isLoading={isLoading}
              label="sub-event-autocomplete"
              options={futureSubEvents
                ?.filter(
                  (e) =>
                    e.super_event_type ===
                    SuperEventType.SUPER_EVENT_TYPE_UMBRELLA
                )
                .map((e) => ({
                  name: `${getStringWithLocale(e, 'name', locale)} ${moment(e.start_time).format('DD.MM.YYYY')}`,
                  value: e['@id'],
                  extra: e.organization,
                }))}
            />
          ))}
      </RadioGroup>
    </div>
  )
}
