import { createBrowserHistory } from 'history'
import {
  configureStore,
  combineReducers,
  ThunkAction,
  Action,
  Middleware,
} from '@reduxjs/toolkit'
import { routerMiddleware, connectRouter } from 'connected-react-router'

import { update as user } from '../reducers/user'

import { update as images } from '../reducers/images'
import app from './app'
import userLocale from './userLocale'
import { validatorMiddleware } from '../middlewares/validator-middleware'
import editor from './editor'

export const history = createBrowserHistory()

export const rootReducer = combineReducers({
  router: connectRouter(history),
  user,
  editor,
  images,
  app,
  userLocale,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      routerMiddleware(history) as Middleware,
      validatorMiddleware
    ),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
