import '../Legend.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

export const ImageValue = (props) => {
  if (props.value !== undefined && props.value instanceof Object) {
    return (
      <legend>
        <img src={props.value.url} className="event-image" />
      </legend>
    )
  }

  return (
    <legend>
      <FormattedMessage id="no-image" />
    </legend>
  )
}

ImageValue.propTypes = {
  value: PropTypes.object,
}
