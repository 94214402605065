import { Paper } from '@material-ui/core'
import React from 'react'
import { HelMaterialTheme } from '../../themes/material-ui'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

// TODO: create type for user

const MissingOrganizationBanner: React.FC = (): JSX.Element | null => {
  const user = useSelector((state: RootState) => state.user)
  const page = useSelector((state: RootState) => state.router.location.pathname)

  if (!user || user.organization || page === '/user') return null

  return (
    <Paper
      elevation={3}
      style={{
        margin: HelMaterialTheme.spacing(3),
        padding: 16,
      }}
    >
      <h4>Tervetuloa käyttämään Espoo Eventsiä, {user.displayName}!</h4>
      <p>
        Sinulla ei ole vielä oikeutta hallinnoida Espoon tapahtumia. Voit pyytää
        käyttöoikeuksia&nbsp;
        <a href="/user">Käyttäjätiedot</a> -sivulta saadaksesi oikeudet
        tapahtumien syöttämiseen ja muokkaamiseen.
      </p>
      <p>
        {' '}
        Jos olet jo saanut käyttöoikeudet, kirjautumisesi saattaa olla
        vanhentunut. Kokeile päivittää sivu (F5) ja kirjaudu uudestaan.
      </p>
      <p>
        Ongelmatilanteissa lähetä sähköposti osoitteeseen&nbsp;
        <a href="mailto:verkkoviestinta@espoo.fi">verkkoviestinta@espoo.fi</a>.
      </p>
    </Paper>
  )
}

export default MissingOrganizationBanner
