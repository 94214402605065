import './HelLabeledCheckboxGroup.scss'
import React, { useRef } from 'react'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import { ValidationPopover } from '../../ValidationPopover/ValidationPopover'
import { CheckboxOption } from './CheckboxOption'

export const HelLabeledCheckboxGroup: React.FC<{
  // TODO: Convert this type to pure string[]
  // Ensure that the selectedValues are always strings
  selectedValues: (string | { '@id': string } | { value: string })[]
  options: CheckboxOption<string>[]
  itemClassName?: string
  groupLabel: string
  validationErrors?: string[]
  required?: boolean
  handleChange: (selectedNewValues: string[]) => void
}> = (props) => {
  const {
    required = false,
    groupLabel,
    selectedValues = [],
    validationErrors,
    itemClassName,
    options,
    handleChange,
  } = props
  const labelRef = useRef<HTMLLegendElement>(null)

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked && !selectedValues.includes(event.target.name)) {
      const mapped = selectedValues
        .map((item) =>
          typeof item === 'object' && 'value' in item ? item.value : item
        )
        .map((item) =>
          typeof item === 'object' && '@id' in item ? item['@id'] : item
        )

      // add checbox value to selectedValues
      const data = [...mapped, event.target.name]
      handleChange(data)
    } else if (
      !event.target.checked &&
      selectedValues.includes(event.target.name)
    ) {
      // remove checbox value from selectedValues
      const data = selectedValues
        .filter((value) => value !== event.target.name)
        .map((item) =>
          typeof item === 'object' && 'value' in item ? item.value : item
        )
        .map((item) =>
          typeof item === 'object' && '@id' in item ? item['@id'] : item
        )

      handleChange(data)
    } else {
      console.warn(
        'onChange - event listener was triggered, but the event is not handled.'
      )
    }
  }

  const checkedOptions = selectedValues
    .map((item) =>
      typeof item === 'object' && 'value' in item ? item.value : item
    )
    .map((item) =>
      typeof item === 'object' && '@id' in item ? item['@id'] : item
    )

  return (
    <>
      {/* TODO: Can FormControl component="fieldset" be used instead? */}
      <fieldset className="col-sm-6 hel-checkbox-group">
        <legend ref={labelRef}>
          {groupLabel}
          {required && <>&thinsp;{'*'}</>}
        </legend>
        <div className="row">
          {options.map(({ label, value }, index) => (
            <span key={`hel-checkbox-${index}`} className={itemClassName || ''}>
              <FormControlLabel
                key={index}
                label={label}
                control={
                  <Checkbox
                    color="primary"
                    checked={checkedOptions.includes(value)}
                    value={value}
                    name={value}
                    onChange={onChange}
                  />
                }
              />
            </span>
          ))}
        </div>
      </fieldset>
      <ValidationPopover
        anchor={labelRef.current}
        validationErrors={validationErrors}
      />
    </>
  )
}
