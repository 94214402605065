export const local = 'local'
export const dev = 'dev'
export const test = 'test'
export const staging = 'staging'
export const prod = 'prod'

export type Environment =
  | typeof local
  | typeof dev
  | typeof test
  | typeof staging
  | typeof prod

// gets the current environment based on window.location.host
export const getEnvironment = (): Environment | undefined => {
  // TODO: url is stored (with https://) in i18, page-url
  // use that.
  const domain = 'tapahtumasyotto.espoo.fi'
  const host = window.location.host

  if (host.startsWith('localhost')) return local

  if (host === `dev.${domain}`) return dev

  if (host === `test.${domain}`) return test

  if (host === `staging.${domain}`) return staging

  if (host === `prod.${domain}` || host === domain) return prod

  return undefined
}
