import './OptionGroup.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { map } from 'lodash'
import { FormattedMessage } from 'react-intl'
import { getStringWithLocale } from '../../../utils/locale'
import { CheckedValue } from './CheckedValue'
import { NoValue } from './NoValue'

export const OptionGroup = (props) => {
  const values = props.values || []

  let elements = map(values, (val, key) => {
    const name =
      getStringWithLocale(val, 'name') || val.label || val.id || val || ''
    return <CheckedValue checked={true} label={name} key={key} />
  })

  if (elements.length === 0) {
    elements = <NoValue labelKey={props.labelKey} />
  }

  return (
    <div className="option-group" hidden={props.hidden}>
      <div>
        <label>
          <FormattedMessage id={`${props.labelKey}`} />
        </label>
      </div>
      {elements}
    </div>
  )
}

OptionGroup.propTypes = {
  values: PropTypes.array,
  labelKey: PropTypes.string,
  hidden: PropTypes.bool,
}
