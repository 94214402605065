import { get, isArray, isNil, isNull, keys, some } from 'lodash'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import { Chip, withStyles } from '@material-ui/core'

import { helBrandColors } from '../themes/hel/hel-brand-colors'
import { constants } from '../constants'

const { VALIDATION_RULES, CHARACTER_LIMIT } = constants

/**
 * Get text limit base on it's rule
 * @param {string} Rulename - Validation rule
 * @return {number} Character limit
 * @return {null} Return false if they're not string count rules
 */
export const getCharacterLimitByRule = (ruleName) => {
  if (ruleName === VALIDATION_RULES.SHORT_STRING) {
    return CHARACTER_LIMIT.SHORT_STRING
  }
  if (ruleName === VALIDATION_RULES.MEDIUM_STRING) {
    return CHARACTER_LIMIT.MEDIUM_STRING
  }
  if (ruleName === VALIDATION_RULES.LONG_STRING) {
    return CHARACTER_LIMIT.LONG_STRING
  }
  return null
}

/**
 * Check if text input is reaching the limit
 * @param  {object || string } value
 * @param  {int} limit
 * @return {boolean} validation status
 */
export const textLimitValidator = (value, limit) => {
  if (typeof value === 'object') {
    return !some(value, (item) => !isNull(item) && item.length > limit)
  }

  if (typeof value === 'string') {
    return value.length <= limit
  }

  return true
}

// set a property of an object to empty value based on its type
export const emptyField = (object, field) => {
  let value = object[field]
  const fieldValueType = isArray(value) ? 'array' : typeof value

  switch (fieldValueType) {
    case 'array':
      value = []
      break
    case 'object':
      value = {}
      break
    case 'string':
    case 'number':
      value = ''
      break
    default:
  }

  return {
    ...object,
    [field]: value,
  }
}

/**
 * Scrolls to the top of the page
 */
export const scrollToTop = () => window.scrollTo(0, 0)

/**
 * Returns the first defined value of a multi-language field
 * @param field             Multi-language field to get the value from
 * @param contentLanguages  Optional. If given, the value will only be looked for, for the given languages
 * @returns {string|undefined}
 */
export const getFirstMultiLanguageFieldValue = (
  field,
  contentLanguages = null
) => {
  if (isNil(field)) {
    return undefined
  }
  return isArray(contentLanguages)
    ? get(
        field,
        keys(field)
          .filter((key) => contentLanguages.includes(key))
          .find((key) => !isNil(field[key])),
        ''
      )
    : get(
        field,
        keys(field).find((key) => !isNil(field[key])),
        ''
      )
}

/**
 * Returns a badge for the given type
 * @param type Type of the badge
 * @param size  Size of the badge
 * @returns {*}
 */
export const getBadge = (type, size = 'small') => {
  const BadgeChip = withStyles({
    root: {
      '&.badge-chip': {
        fontSize: '0.6em',
        fontWeight: 'bold',
        textTransform: 'uppercase',
      },
      '&.umbrella': {
        backgroundColor: helBrandColors.bus.main,
        color: helBrandColors.gray.white,
      },
      '&.series': {
        backgroundColor: helBrandColors.tram.main,
        color: helBrandColors.gray.white,
      },
      '&.cancelled': {
        backgroundColor: helBrandColors.metro.main,
        color: helBrandColors.gray.white,
      },
      '&.draft': {
        backgroundColor: helBrandColors.summer.main,
        color: helBrandColors.gray.black90,
      },
      '&.postponed': {
        backgroundColor: helBrandColors.fog.main,
        color: helBrandColors.gray.black90,
      },
    },
    sizeSmall: {
      '&.badge-chip': {
        fontSize: '90%',
        textTransform: 'none',
      },
    },
    // fixes for FF
    label: {
      '& > span': {
        display: 'block',
        padding: '0 12px',
      },
    },
    // fixes for FF
    labelSmall: {
      '& > span': {
        padding: '0 8px',
      },
    },
  })(Chip)

  return (
    <BadgeChip
      className={`${type} badge-chip`}
      size={size}
      label={<FormattedMessage id={type} />}
    />
  )
}

/**
 * Returns a formatted date
 * @param date  Date to format
 * @returns {string}
 */
export const getDate = (date) => moment(date).format('D.M.YYYY')

/**
 * Returns a formatted date time
 * @param date  Date to format
 * @returns {string}
 */
export const getDateTime = (date) => moment(date).format('D.M.YYYY HH:mm')

/**
 * Returns the button label
 * @param action
 * @param isRegularUser
 * @param isDraft
 * @param eventIsPublished
 * @param formHasSubEvents
 * @returns {string}
 */
export const getButtonLabel = (
  action,
  isRegularUser,
  isDraft,
  eventIsPublished,
  formHasSubEvents
) => {
  let buttonLabel = `${action}-events`

  if (action === 'return') {
    buttonLabel = 'return-without-saving'
  }
  if (action === 'update') {
    if (isRegularUser) {
      buttonLabel = isDraft
        ? 'event-action-save-draft-existing'
        : 'event-action-save-draft-new'
    } else {
      buttonLabel = eventIsPublished
        ? 'event-action-save-existing'
        : 'event-action-save-new'
    }

    if (!eventIsPublished && formHasSubEvents) {
      buttonLabel = 'event-action-save-multiple'
    }
  }

  return buttonLabel
}
