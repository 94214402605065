import './HelSelect.scss'

import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import AsyncSelect from 'react-select/async'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { get, isNil } from 'lodash'
import debounce from 'debounce-promise'

import { setData as setDataAction } from '../../actions/editor'
import { ValidationPopover } from '../ValidationPopover/ValidationPopover'
import { client } from '../../api/client'
import { HelSelectTheme, HelSelectStyles } from '../../themes/react-select'

const HelSelect = ({
  intl,
  setData,
  isClearable,
  isMultiselect,
  name,
  resource,
  legend,
  selectedValue,
  validationErrors,
  placeholderId,
  customOnChangeHandler,
  onChangeCallback,
  required,
  lang,
  topics,
  useDefaultOptions,
}) => {
  const labelRef = useRef(null)

  const onChange = (value) => {
    // let the custom handler handle the change if given
    if (typeof customOnChangeHandler === 'function') {
      customOnChangeHandler(value)
      // otherwise set data
    } else if (isNil(value)) {
      setData({ [name]: null })
    } else {
      setData({
        [name]: {
          name: { fi: value.label },
          id: value.value,
          '@id': value['@id'],
        },
      })
    }

    if (typeof onChangeCallback === 'function') {
      onChangeCallback(value)
    }
  }

  const getLocationOptions = async (input) => {
    const queryParams = {
      show_all_places: 1,
      text: input,
    }

    try {
      const response = await client.get(`${resource}`, queryParams)
      const options = response.data.data

      return options.map((item) => {
        let label = get(item, ['name', 'fi'], '')

        if (item.data_source !== 'osoite' && item.street_address) {
          label = `${label} (${item.street_address.fi})`
        }

        return {
          label,
          value: item.id,
          '@id': `/v1/${resource}/${item.id}/`,
          id: item.id,
          n_events: item.n_events,
          postalCode: item.postal_code,
        }
      })
    } catch (e) {
      throw Error(e)
    }
  }

  /** @deprecated */
  const getHobbyCategoryOptions = async (input) => {
    const queryParams = {
      text: input,
      topics,
      page_size: 30,
    }
    try {
      const response = await client.get(`${resource}`, queryParams)
      const options = response.data.data
      return options.map((item) => ({
        ...item,
        label: item[`name_${lang}`],
      }))
    } catch (e) {
      throw Error(e)
    }
  }

  const getOptions = async (input) => {
    if (name === 'location') {
      const locations = await getLocationOptions(input)
      return locations
    }

    if (name === 'hobby-category') {
      const hobbyCategories = await getHobbyCategoryOptions(input)
      return hobbyCategories
    }
    return undefined
  }

  // Lodash's debounce doesn't work with react-select and that's why we use debounce-promise. For more information, see
  // https://github.com/JedWatson/react-select/issues/3075#issuecomment-450194917
  const debouncedGetOptions = debounce(getOptions, 500)

  const getDefaultValue = () => {
    if (!selectedValue || Object.keys(selectedValue).length === 0) {
      return null
    }
    if (name === 'location') {
      return {
        label: selectedValue.name.fi,
        value: selectedValue.id,
      }
    }
    return undefined
  }

  const formatOption = (item) => (
    <React.Fragment>
      {item[`name_${lang}`] ?? item.label}
      {item && typeof item.n_events === 'number' && (
        <small>
          <br />
          {intl.formatMessage(
            { id: 'format-select-count' },
            { count: item.n_events }
          )}
        </small>
      )}
    </React.Fragment>
  )

  // no need to filter data returned by the api, text filter might have matched to non-displayed fields
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const filterOptions = (candidate, input) => true

  return (
    <React.Fragment>
      <legend ref={labelRef}>
        {legend}
        {required && <>&thinsp;{'*'}</>}
      </legend>
      <AsyncSelect
        isClearable={isClearable}
        isMulti={isMultiselect}
        value={getDefaultValue()}
        loadOptions={(input) => debouncedGetOptions(input)}
        onChange={onChange}
        placeholder={intl.formatMessage({ id: placeholderId })}
        loadingMessage={() => intl.formatMessage({ id: 'loading' })}
        noOptionsMessage={() => intl.formatMessage({ id: 'search-no-results' })}
        filterOption={filterOptions}
        formatOptionLabel={formatOption}
        styles={HelSelectStyles}
        theme={HelSelectTheme}
        defaultOptions={useDefaultOptions}
      />
      <ValidationPopover
        anchor={labelRef.current}
        validationErrors={validationErrors}
      />
    </React.Fragment>
  )
}

HelSelect.defaultProps = {
  placeholderId: 'select',
  isClearable: true,
  isMultiselect: false,
  lang: 'fi',
}

HelSelect.propTypes = {
  intl: PropTypes.object,
  setData: PropTypes.func,
  name: PropTypes.string,
  isClearable: PropTypes.bool,
  isMultiselect: PropTypes.bool,
  resource: PropTypes.string,
  legend: PropTypes.string,
  validationErrors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  selectedValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  placeholderId: PropTypes.string,
  customOnChangeHandler: PropTypes.func,
  onChangeCallback: PropTypes.func,
  required: PropTypes.bool,
  lang: PropTypes.string,
  topics: PropTypes.array,
  useDefaultOptions: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => ({
  setData: (value) => dispatch(setDataAction(value)),
})

export const ConnectedHelSelect = injectIntl(
  connect(null, mapDispatchToProps)(HelSelect)
)
