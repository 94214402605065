import './MultiField.scss'

import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { isEqual, map, zipObject } from 'lodash'

import { HelTextField } from './HelTextField'
import { ValidationPopover } from '../ValidationPopover/ValidationPopover'
import { setData } from '../../actions/editor'

export class MultiLanguageField extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.defaultValue || {},
      labelRef: null,
    }
  }

  static contextTypes = {
    intl: PropTypes.object,
    dispatch: PropTypes.func,
  }

  static propTypes = {
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func,
    name: PropTypes.string,
    forceApplyToStore: PropTypes.bool,
    onBlur: PropTypes.func,
    languages: PropTypes.array,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    validations: PropTypes.array,
    validationErrors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    index: PropTypes.string,
    multiLine: PropTypes.bool,
    label: PropTypes.string,
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange(e, value, lang) {
    this.setState({ value: this.getValue() })

    if (typeof this.props.onChange === 'function') {
      this.props.onChange(e, this.getValue())
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onBlur(e, value) {
    this.setState({ value: this.getValue() })

    if (this.props.name) {
      const obj = {}
      obj[this.props.name] = this.getValue()
      if (this.noValidationErrors() || this.props.forceApplyToStore) {
        this.context.dispatch(setData(obj))
      }
    }

    if (typeof this.props.onBlur === 'function') {
      this.props.onBlur(e, this.getValue())
    }
  }

  getValue() {
    // eslint-disable-next-line react/no-string-refs
    const langs = map(this.refs, (ref, key) => key)
    // eslint-disable-next-line react/no-string-refs
    const values = map(this.refs, (ref) => ref.getValue())

    const valueObj = zipObject(langs, values)

    return valueObj
  }

  noValidationErrors() {
    // eslint-disable-next-line react/no-string-refs
    let errors = map(this.refs, (elem) => elem.getValidationErrors().length)
    errors = errors.filter((errorCount) => errorCount > 0)

    return errors.length === 0
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.defaultValue, this.props.defaultValue)) {
      this.setState({ value: nextProps.defaultValue || {} })
    }
    this.forceUpdate()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(nextState, this.state) ||
      !isEqual(nextProps.languages, this.props.languages) ||
      !isEqual(nextProps.disabled, this.props.disabled)
    )
  }

  setLabelRef = (element) => {
    this.setState({ labelRef: element })
  }

  render() {
    const { props } = this
    // Set default language to fi if no languages are selected
    let langs = props.languages

    if (langs.length === 0) {
      langs = ['fi']
    }

    let textInputs = []

    if (langs.length === 1) {
      const label = `${this.context.intl.formatMessage({
        id: props.label,
      })} (${this.context.intl.formatMessage({ id: `in-${langs[0]}` })})`
      return (
        <div
          style={{ position: 'relative' }}
          key={`${props.name}_${langs[0]}`}
          data-testid={props.label}
        >
          <HelTextField
            required={this.props.required}
            defaultValue={this.state.value[langs[0]]}
            label={label}
            ref={langs[0]}
            onChange={(e, v) => this.onChange(e, v, langs[0])}
            onBlur={(e, v) => this.onBlur(e, v)}
            disabled={this.props.disabled}
            validations={this.props.validations}
            validationErrors={this.props.validationErrors}
            index={this.props.index}
            multiLine={this.props.multiLine}
          />
        </div>
      )
    }
    textInputs = langs.map((lang) => {
      const value = this.state.value[lang]
      return (
        <div key={`${props.name}_${lang}`} data-testid={props.label}>
          <HelTextField
            multiLine={this.props.multiLine}
            required={this.props.required}
            defaultValue={value}
            ref={lang}
            label={this.context.intl.formatMessage({ id: `in-${lang}` })}
            onChange={(e, v) => this.onChange(e, v, lang)}
            onBlur={(e, v) => this.onBlur(e, v)}
            disabled={this.props.disabled}
            validations={this.props.validations}
          />
        </div>
      )
    }, this)

    return (
      <div className="multi-field">
        <div className="indented">
          <label ref={this.setLabelRef}>
            <FormattedMessage id={`${props.label}`} />
            <ValidationPopover
              index={this.props.index}
              anchor={this.state.labelRef}
              validationErrors={this.props.validationErrors}
            />
          </label>
          {textInputs}
        </div>
      </div>
    )
  }
}
