import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { FormattedMessage } from 'react-intl'
import { Button, Snackbar } from '@material-ui/core'
import { isEqual, omit, values } from 'lodash'

import { clearFlashMsg as clearFlashMsgAction } from '../../store/app/index'

class Notifications extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props)
  }

  render() {
    const { flashMsg, clearFlashMsg } = this.props
    let flashMsgSpan = <span />
    let isSticky = flashMsg && flashMsg.sticky

    if (
      flashMsg &&
      flashMsg.data?.response &&
      flashMsg.data.response.status === 400
    ) {
      flashMsgSpan = values(
        omit(flashMsg.data, ['apiErrorMsg', 'response'])
      ).join(' ')
      isSticky = true
    } else if (flashMsg && flashMsg.msg && flashMsg.msg.length) {
      flashMsgSpan = <FormattedMessage id={flashMsg.msg} />
    }

    const duration = isSticky ? null : 7000
    const closeFn = isSticky ? () => {} : () => clearFlashMsg()

    let actionLabel
    if (flashMsg && flashMsg.action) {
      if (flashMsg.action.label) {
        actionLabel = flashMsg.action.label
      } else if (flashMsg.action.labelId) {
        actionLabel = <FormattedMessage id={flashMsg.action.labelId} />
      }
    }

    const actionFn = flashMsg && flashMsg.action && flashMsg.action.fn

    let actionButton = null
    if (actionLabel && actionFn) {
      actionButton = (
        <Button key="snackActionButton" onClick={actionFn}>
          {actionLabel}
        </Button>
      )
    }

    return (
      <Snackbar
        open={!!flashMsg}
        message={flashMsgSpan}
        autoHideDuration={duration}
        onClose={closeFn}
        action={[actionButton]}
      />
    )
  }
}

Notifications.propTypes = {
  flashMsg: PropTypes.object,
  clearFlashMsg: PropTypes.func,
}

const mapDisPatchToProps = (dispatch) => ({
  clearFlashMsg: () => dispatch(clearFlashMsgAction()),
})
const mapStateToProps = () => ({})
// TODO: if leave null, react-intl not refresh. Replace this with better React context
export const ConnectedNotifications = connect(
  mapStateToProps,
  mapDisPatchToProps
)(Notifications)
