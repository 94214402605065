// Validator actor which listens to validation changes and sets flash message if validation errors are cleared.
// Subscribes for store changes in src/index.js
import { keys } from 'lodash'

import { setFlashMsg, clearFlashMsg } from '../store/app/index'
import { constants } from '../constants'
import { Middleware } from '@reduxjs/toolkit'

let wasErrors: boolean = false

// TODO: Consider this to put into a slice
// Original validator was an actor. Maybe this can be combined with an infoModal slice.
export const validatorMiddleware: Middleware =
  (store) => (next) => (action) => {
    const result = next(action)
    const { editor, router } = store.getState()
    const { dispatch } = store

    const errorCount = keys(editor.validationErrors).length

    if (errorCount > 0 && wasErrors === false) {
      wasErrors = true

      const payload = {
        labelId: 'validation-error-goto-error',
        fn: (): void => {
          const top = window.scrollY || window.pageYOffset
          const popovers = document.getElementsByClassName('validation-popper')
          if (popovers[0]) {
            window.scrollTo(
              0,
              top + popovers[0].getBoundingClientRect().top - 16
            )
          }
        },
      }

      dispatch(
        setFlashMsg({
          msg: 'validation-error',
          style: 'error',
          sticky: true,
          action: payload,
        })
      )
      return result
    }

    if (wasErrors === true) {
      if (errorCount === 0) {
        wasErrors = false
        if (
          (router?.location?.pathname?.indexOf('/event/create/') > -1 ||
            router?.location?.pathname?.indexOf('/event/update/') > -1) &&
          editor.validationStatus === constants.VALIDATION_STATUS.RESOLVE
        ) {
          dispatch(
            setFlashMsg({ msg: 'no-validation-errors', style: 'success' })
          )
        } else {
          dispatch(clearFlashMsg())
        }
      }
    }
  }
