import React from 'react'
import { HelLabeledCheckboxGroup } from '../../HelFormFields'
import { RootState } from '../../../store'
import { useDispatch, useSelector } from 'react-redux'
import { CheckboxOption } from '../../HelFormFields/HelLabeledCheckboxGroup/CheckboxOption'
import { setData } from '../../../actions/editor'
import { useIntl } from 'react-intl'
import { mapKeywordSetToForm } from '../../../utils/apiDataMapping'

const useLibraries = (): CheckboxOption[] => {
  const keywordSets = useSelector(
    (state: RootState) => state.editor.keywordSets
  )
  const locale = useSelector((state: RootState) => state.userLocale.locale)

  const topicOptions = mapKeywordSetToForm(
    keywordSets,
    'espoo:librarytopics',
    locale
  )
    .sort((a, b) => String(a.label).localeCompare(b.label ?? ''))
    .map((item) => item as CheckboxOption)

  return topicOptions
}

export const LibraryEventCheckboxGroup: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const selectedValues = useSelector(
    (state: RootState) => state.editor.values.libraryKeywords
  )
  const validationErrors = useSelector(
    (state: RootState) => state.editor.validationErrors.libraryKeywords
  )
  const options = useLibraries()

  return (
    <HelLabeledCheckboxGroup
      required={false}
      groupLabel={intl.formatMessage({ id: 'event-topics-label-library' })}
      options={options}
      validationErrors={validationErrors}
      selectedValues={selectedValues}
      itemClassName="col-lg-6 col-md-12"
      handleChange={(libraryKeywords) => dispatch(setData({ libraryKeywords }))}
    />
  )
}
