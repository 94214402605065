import '../SingleValueField.scss'
import moment from 'moment-timezone'
import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl'
import { NoValue } from './NoValue'

export const DateTime = (props) => {
  // TODO: if all day event show it on this field. Add a props for it
  if (
    props.value &&
    props.value.length !== undefined &&
    props.value.length > 0
  ) {
    const time = moment(props.value).tz('Europe/Helsinki')
    let value = ''
    if (time.isValid()) {
      value = (
        <div>
          <FormattedDate
            value={time}
            year="numeric"
            month="short"
            day="numeric"
            weekday="long"
          />
          <FormattedTime value={time} hour="numeric" minute="2-digit" />
        </div>
      )
    }
    return (
      <div className="single-value-field">
        <label>
          <FormattedMessage id={`${props.labelKey}`} />
        </label>
        <span className="value">{value}</span>
      </div>
    )
  }

  return (
    <div className="single-value-field">
      <label>
        <FormattedMessage id={`${props.labelKey}`} />
      </label>
      <span className="value">
        <NoValue labelKey={props.labelKey} />
      </span>
    </div>
  )
}

DateTime.propTypes = {
  labelKey: PropTypes.string,
  value: PropTypes.string,
}
