import { Button, CircularProgress } from '@material-ui/core'
import React, { useState } from 'react'
import { UserViewInformation } from './UserInfoPage'
import { useIntl } from 'react-intl'
import { useClient } from '../../api/clientContext'
import { useDispatch, useSelector } from 'react-redux'
import { clearLoginType, setFlashMsg } from '../../store/app'
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal'
import { RootState } from '../../store'
import { logout } from '../../actions/user'
import { getLogoutUrl } from '../../utils/user'
import axios from 'axios'

const BasicUserInfo: React.FC<{
  loading: boolean
  userViewInfo?: UserViewInformation
}> = ({ loading, userViewInfo }) => {
  const intl = useIntl()
  const client = useClient()
  const dispatch = useDispatch()
  const loginType = useSelector((state: RootState) => state?.app.loginType)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const user = useSelector((state: RootState) => state.user)

  if (loading) return <CircularProgress />

  const handleUserDeletion = async (): Promise<void> => {
    if (!client) {
      console.warn('Client not initialized')
      return
    }

    try {
      await client.delete(`user/${user.id}`)
      dispatch(logout())
      await axios.post(getLogoutUrl(loginType))
      localStorage.removeItem('loginType')
      dispatch(clearLoginType())
      setShowConfirmationModal(false)
      dispatch(
        setFlashMsg({
          msg: 'user-deleted-successfully',
          style: 'message',
          sticky: false,
        })
      )
    } catch (error) {
      dispatch(
        setFlashMsg({
          msg: 'generic-error-message',
          style: 'error',
          sticky: false,
        })
      )
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <div>
        <p>
          <strong>{intl.formatMessage({ id: 'user-name-DPDSA' })}</strong>
        </p>
        {userViewInfo?.displayName ? (
          <p>{userViewInfo.displayName}</p>
        ) : (
          <p>
            <i>{intl.formatMessage({ id: 'name-not-found' })}</i>
          </p>
        )}
      </div>
      <Button
        variant="contained"
        onClick={() => setShowConfirmationModal(true)}
        endIcon={loading && <CircularProgress color="inherit" size={25} />}
        color="secondary"
        style={{ marginLeft: '10em' }}
      >
        {intl.formatMessage({
          id: 'delete-user',
        })}
      </Button>
      {showConfirmationModal && (
        <ConfirmationModal
          loading={loading}
          msg="delete-user-message"
          additionalMsg={intl.formatMessage({
            id: 'delete-user-message-additional',
          })}
          actionButtonLabel="yes"
          cancel={() => setShowConfirmationModal(false)}
          confirm={() => handleUserDeletion()}
        />
      )}
    </div>
  )
}

export default BasicUserInfo
