import PropTypes from 'prop-types'
import React from 'react'
import { IconButton, withStyles } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { filter, map, omit } from 'lodash'

import './NewOffer.scss'
import { MultiLanguageField } from './MultiLanguageField'
import { setOfferData } from '../../actions/editor'
import { constants } from '../../constants'

const DeleteButton = withStyles((theme) => ({
  root: {
    left: 0,
    position: 'absolute',
    top: 0,
    transform: `translate(calc(-1.2em - ${theme.spacing(3)}px), ${theme.spacing(4)}px)`,
    '& svg': {
      height: '1.2em',
      width: '1.2em',
    },
  },
}))(IconButton)

export class NewOffer extends React.Component {
  static contextTypes = {
    dispatch: PropTypes.func,
  }

  static propTypes = {
    validationErrors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFree !== this.props.isFree) {
      this.onBlur()
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onBlur(e) {
    if (this.props.offerKey) {
      //            if(this.noValidationErrors()) {
      const obj = {}
      obj[this.props.offerKey] = this.buildObject()
      this.context.dispatch(setOfferData(obj, this.props.offerKey))
      //            }
    }
  }

  // Creates database 'offers' object from inputs
  buildObject() {
    let obj = {}
    obj.is_free = this.props.isFree
    // Unwrap connect and injectIntl
    // eslint-disable-next-line react/no-string-refs
    const pairs = map(this.refs, (ref, key) => ({
      key,
      value: ref.getValue(),
    }))
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const key in this.props.defaultValue) {
      pairs.forEach((pair) => {
        obj[pair.key] = pair.value
      })
      if (obj.is_free === true) {
        obj = omit(obj, ['price', 'description'])
      }
    }
    return obj
  }

  noValidationErrors() {
    // eslint-disable-next-line react/no-string-refs, @typescript-eslint/no-unused-vars
    const noErrors = map(this.refs, (ref, key) => ref.noValidationErrors())
    const actualErrors = filter(noErrors, (i) => i === false)

    if (actualErrors.length > 0) {
      return false
    }

    return true
  }

  render() {
    const { offerKey, defaultValue, isFree, languages, onDelete } = this.props
    const { VALIDATION_RULES } = constants

    return (
      <div key={offerKey} className="new-offer">
        <MultiLanguageField
          defaultValue={defaultValue.price}
          disabled={isFree}
          // eslint-disable-next-line react/no-string-refs
          ref="price"
          label="event-price"
          languages={languages}
          onBlur={(e) => this.onBlur(e)}
          validationErrors={this.props.validationErrors.price}
          index={this.props.offerKey}
          required={true}
        />

        <MultiLanguageField
          defaultValue={defaultValue.info_url}
          // eslint-disable-next-line react/no-string-refs
          ref="info_url"
          label="event-purchase-link"
          languages={languages}
          onBlur={(e) => this.onBlur(e)}
          validations={[VALIDATION_RULES.IS_URL]}
          validationErrors={this.props.validationErrors.offer_info_url}
          index={this.props.offerKey}
        />

        <MultiLanguageField
          defaultValue={defaultValue.description}
          disabled={isFree}
          // eslint-disable-next-line react/no-string-refs
          ref="description"
          label="event-price-info"
          languages={languages}
          multiLine={true}
          onBlur={(e) => this.onBlur(e)}
          validationErrors={this.props.validationErrors.offer_description}
          index={this.props.offerKey}
        />

        <DeleteButton
          color="secondary"
          onClick={() => onDelete(this.props.offerKey)}
        >
          <Delete />
        </DeleteButton>
      </div>
    )
  }
}

NewOffer.propTypes = {
  isFree: PropTypes.bool,
  languages: PropTypes.array,
  offerKey: PropTypes.string.isRequired,
  defaultValue: PropTypes.object,
  onDelete: PropTypes.func,
}
