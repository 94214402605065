import { Button } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { constants } from '../../constants'
import { mapAPIDataToUIFormat } from '../../utils/formDataMapping'
import { APIEvent, Keyword } from '../../types/apiTypes'
import { clearData, setValidationErrors } from '../../actions/editor'
import { doValidations } from '../../validation/validator'
import { getContentLanguages } from '../../utils/language'
import { setFlashMsg } from '../../store/app'
import { useParams } from 'react-router'

const { PUBLICATION_STATUS, USER_TYPE } = constants

export const EditorHeader: React.FC<{
  event: APIEvent
}> = ({ event }) => {
  const user = useSelector((state: RootState) => state.user)
  const keywordSets = useSelector(
    (state: RootState) => state.editor.keywordSets
  ) as unknown as Keyword[][]
  const dispatch = useDispatch()
  const intl = useIntl()
  const { action: editMode } = useParams<{ action: string }>()

  const userType = user && user.userType
  const isAdminUser = userType === USER_TYPE.ADMIN
  const isDraft = event.publication_status === PUBLICATION_STATUS.DRAFT

  const validateEvent = () => {
    const formattedEvent = mapAPIDataToUIFormat(event, keywordSets)
    const validationErrors = doValidations(
      formattedEvent,
      getContentLanguages(formattedEvent),
      PUBLICATION_STATUS.PUBLIC,
      keywordSets
    )

    if (Object.keys(validationErrors).length > 0) {
      setValidationErrors(validationErrors)
      dispatch(setValidationErrors(validationErrors))
    } else {
      dispatch(setFlashMsg({ msg: 'no-validation-errors', style: 'success' }))
    }
  }

  return (
    <div className="container header">
      <h1>
        {editMode === 'update'
          ? intl.formatMessage({ id: 'edit-events' })
          : intl.formatMessage({ id: 'create-events' })}
      </h1>
      <span className="controls">
        {isAdminUser && isDraft && (
          <Button variant="contained" onClick={validateEvent} color="primary">
            {intl.formatMessage({ id: 'validate-form' })}
          </Button>
        )}
        <Button
          variant="contained"
          onClick={() => dispatch(clearData())}
          color="primary"
          endIcon={<Close />}
        >
          {intl.formatMessage({ id: 'clear-form' })}
        </Button>
      </span>
    </div>
  )
}
