import { get, set } from 'lodash'
import axios from 'axios'

import { constants } from '../constants'
import { setEditorAuthFlashMsg } from './editor'
import { client } from '../api/client'
import { getAdminOrganizations, getRegularOrganizations } from '../utils/user'
import {
  clearLoginType,
  setPendingDrafts,
  setPendingRequests,
} from '../store/app'

const { RECEIVE_USERDATA, CLEAR_USERDATA, USER_TYPE } = constants

// Handled by the user reducer
export function receiveUserData(data) {
  return {
    type: RECEIVE_USERDATA,
    payload: data,
  }
}

// Handled by the user reducer
export function clearUserData() {
  return {
    type: CLEAR_USERDATA,
  }
}

const getUserType = (permissions) => {
  if (permissions.includes(USER_TYPE.ADMIN)) {
    return USER_TYPE.ADMIN
  }
  if (permissions.includes(USER_TYPE.REGULAR)) {
    return USER_TYPE.REGULAR
  }
  return undefined
}

export const retrieveUserFromSession = () => async (dispatch) => {
  const meResponse = await axios.get(`/auth/me?${+new Date()}`)
  const user = meResponse.data

  if (user.token) {
    const userResponse = await client.get(
      `user/${user.username}`,
      {},
      {
        headers: { Authorization: `JWT ${user.token}` },
      }
    )
    const userData = userResponse.data
    const permissions = []

    if (get(userData, 'admin_organizations', []).length > 0) {
      permissions.push(USER_TYPE.ADMIN)
    }
    if (get(userData, 'organization_memberships', []).length > 0) {
      permissions.push(USER_TYPE.REGULAR)
    }

    const mergedUser = {
      ...user,
      organization: get(userData, 'organization', null),
      adminOrganizations: get(userData, 'admin_organizations', null),
      organizationMemberships: get(userData, 'organization_memberships', null),
      permissions,
      superuser: userData?.superuser ?? false,
      userType: getUserType(permissions),
    }

    const adminOrganizations = await Promise.all(
      getAdminOrganizations(mergedUser)
    )
    const regularOrganizations = await Promise.all(
      getRegularOrganizations(mergedUser)
    )

    // store data of all the organizations that the user is admin in
    mergedUser.adminOrganizationData = adminOrganizations.reduce(
      (acc, organization) =>
        set(acc, `${organization.data.id}`, organization.data),
      {}
    )
    // store data of all the organizations where the user is a regular user
    mergedUser.regularOrganizationData = regularOrganizations.reduce(
      (acc, organization) =>
        set(acc, `${organization.data.id}`, organization.data),
      {}
    )
    const pendingDrafts = await client.get(
      'event',
      { admin_user: true, publication_status: 'draft', start: 'now' },
      {
        headers: { Authorization: `JWT ${user.token}` },
      }
    )
    dispatch(setPendingDrafts(pendingDrafts.data.meta.count))
    // get organizations with regular users
    mergedUser.organizationsWithRegularUsers = adminOrganizations
      .filter((organization) =>
        get(organization, ['data', 'has_regular_users'], false)
      )
      .map((organization) => organization.data.id)

    if (mergedUser.superuser) {
      const pendingOrganizationAccessRequests = client.get(
        'organizationaccessrequest',
        { pending: true },
        {
          headers: { Authorization: `JWT ${user.token}` },
        }
      )
      const pendingOrganizationRequests = client.get(
        'organizationcreationrequest',
        { pending: true },
        {
          headers: { Authorization: `JWT ${user.token}` },
        }
      )

      const pendingRequests = await Promise.all([
        pendingOrganizationAccessRequests,
        pendingOrganizationRequests,
      ])

      dispatch(
        setPendingRequests(
          pendingRequests.reduce((a, c) => a + c.data.meta.count, 0)
        )
      )
    }

    dispatch(receiveUserData(mergedUser))
    dispatch(setEditorAuthFlashMsg())
  }
}

export function logout() {
  return (dispatch) => {
    localStorage.removeItem('loginType')
    dispatch(clearLoginType())
    dispatch(clearUserData())
    dispatch(setEditorAuthFlashMsg())
  }
}
