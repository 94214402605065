import moment from 'moment'
import { constants } from '../constants'
import { UIEvent } from '../types/apiTypes'

const { EVENT_STATUS } = constants

// This function has been separated into its own file since there would be a cyclic dependency between
// checkEventEditability.js and formDataMapping.js if this function would be in checkEventEditability.js
// TODO check where to put this function to avoid the cyclic dependency but to make the structure more clear
export const eventIsEditable = (
  event: UIEvent
): { editable: boolean; explanationId: string } => {
  const eventIsCancelled = event?.event_status === EVENT_STATUS.CANCELLED
  const eventIsDeleted = event?.deleted
  const startTime = event?.start_time ?? ''
  const endTime = event?.end_time ?? null
  const eventIsInThePast =
    (endTime && moment(endTime).isBefore(moment())) ||
    (!endTime && moment(startTime).isBefore(moment().startOf('day')))
  if (eventIsCancelled) {
    return { editable: true, explanationId: 'event-canceled' }
  }
  if (eventIsDeleted) {
    return { editable: false, explanationId: 'event-deleted' }
  }
  if (eventIsInThePast) {
    return { editable: false, explanationId: 'event-in-the-past' }
  }
  return { editable: true, explanationId: '' }
}
