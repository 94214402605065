import './NewEvent.scss'
import React from 'react'
import { useDispatch } from 'react-redux'
import { IconButton, withStyles } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import {
  EditorValues,
  ValidationErrors,
  deleteSubEvent,
} from '../../../store/editor'
import { updateSubEvent } from '../../../actions/editor'
import { HelDateTimeField } from '../../HelFormFields'

const DeleteButton = withStyles((theme) => ({
  root: {
    alignSelf: 'center',
    position: 'absolute',
    top: '33%',
    left: 0,
    transform: `translateX(calc(-1.7em - ${theme.spacing(1)}px))`,
    '& svg': {
      height: '1.2em',
      width: '1.2em',
    },
  },
}))(IconButton)

export const NewEvent: React.FC<{
  event: EditorValues
  eventKey: string
  errors: ValidationErrors
}> = ({ event, eventKey, errors }) => {
  const dispatch = useDispatch()
  return (
    <div className="new-sub-event">
      <div
        className="new-sub-event--inputs"
        style={{ display: 'flex', justifyContent: 'space-around' }}
      >
        <HelDateTimeField
          required
          name="start_time"
          label="event-starting-datetime"
          defaultValue={event.start_time}
          validationErrors={errors.start_time}
          onChange={(value) =>
            dispatch(updateSubEvent(value, 'start_time', eventKey))
          }
        />
        <HelDateTimeField
          disablePast
          name="end_time"
          label="event-ending-datetime"
          defaultValue={event.end_time}
          validationErrors={errors.end_time}
          onChange={(value) =>
            dispatch(updateSubEvent(value, 'end_time', eventKey))
          }
        />
      </div>
      <DeleteButton
        className="new-sub-event--delete"
        color="secondary"
        onClick={() =>
          dispatch(deleteSubEvent({ event, subEventKey: +eventKey }))
        }
      >
        <Delete />
      </DeleteButton>
    </div>
  )
}
