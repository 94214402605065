const quillModules = {
  // https://quilljs.com/docs/modules/toolbar/
  toolbar: [
    'bold', // <strong></strong>
    'link', // <a href=""></a>
    { header: 2 }, // <h2></h2>
    { list: 'bullet' }, // <ul><li></li></ul>
  ],
}

export default quillModules
