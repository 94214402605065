import React from 'react'
import LogoutButton from './LogoutButton'
import LanguageSelect from './LanguageSelect'
import LoginButton from './LoginButton'

// TODO typings for the user
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const UserActions: React.FC<{ user: any }> = ({ user }) => (
  <div className="helsinki-bar__user-actions">
    <LanguageSelect />
    {user ? <LogoutButton /> : <LoginButton />}
  </div>
)
