import {
  Organization,
  OrganizationAccessRequest,
  OrganizationCreationRequest,
} from '../../types/apiTypes'
import { UsersTableColumn } from './UsersTable'

export type UserAccessInfo = {
  userName: string
  email?: string
  name?: string
  organizations: string
}

export type OrganizationAccessRequestInfo = {
  requester: string
  request_received: string
  requested_organization: string
  id: string
}

export type OrganizationCreationRequestInfo = {
  creator: string
  request_received: string
  email: string
  id: string
  name: string
}

export const mapDataToUserAccessInfo = (
  obj: Record<string, string> & { admin_organizations: string[] },
  organizationData: Organization[]
): UserAccessInfo => ({
  userName: obj.username,
  email: obj.email ? obj.email : undefined,
  name: obj.display_name ? obj.display_name : undefined,
  organizations:
    obj.admin_organizations.length > 0
      ? obj.admin_organizations
          .map(
            (id) =>
              organizationData.find((org: Organization) => org.id === id)?.name
          )
          .join(', ')
      : '',
})

export const mapDataToOARInfo = (
  data: OrganizationAccessRequest,
  organizationData: Organization[]
): OrganizationAccessRequestInfo => ({
  requester: ` ${data.requester?.first_name ?? ''}  ${data.requester?.last_name ?? ''}`,
  request_received: data.request_date
    .split('T')[0]
    .split('-')
    .reverse()
    .join('.'),
  requested_organization:
    organizationData.find((org) => org.id === data.organization)?.name ?? '',
  id: data.id,
})

export const mapDataToOCRInfo = (
  data: OrganizationCreationRequest
): OrganizationCreationRequestInfo => ({
  creator: ` ${data.creator?.first_name ?? ''}  ${data.creator?.last_name ?? ''}`,
  request_received: data.request_date
    .split('T')[0]
    .split('-')
    .reverse()
    .join('.'),
  name: data.name,
  id: data.id,
  email: data.email,
})

export const usersTableColumns: UsersTableColumn[] = [
  { id: 'email', translationId: 'email' },
  { id: 'name', translationId: 'name' },
  { id: 'organizations', translationId: 'organizations' },
]

export const oarsTableColumns: UsersTableColumn[] = [
  { id: 'requester', translationId: 'name' },
  { id: 'request_received', translationId: 'request-received' },
  { id: 'requested_organization', translationId: 'requested-organization' },
]

export const orgCreationTableColumns: UsersTableColumn[] = [
  { id: 'creator', translationId: 'name' },
  { id: 'request_received', translationId: 'request-received' },
  { id: 'name', translationId: 'new-org-name' },
  { id: 'email', translationId: 'email' },
]
