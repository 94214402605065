import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { connect } from 'react-redux'

import { fetchUserImages as fetchUserImagesAction } from '../../actions/userImages'
import { ConnectedImageThumbnail } from '../ImageThumbnail/ImageThumbnail'

const ImagePagination = (props) => {
  // During the first modal load there is no data yet. Data is fetched in ComponentDidMount.
  if (props.responseMetadata === undefined) {
    return null
  }

  const pageAmount = Math.ceil(parseInt(props.responseMetadata.count, 10) / 100)
  const { currentPage } = props.responseMetadata

  let classes
  const pages = []

  for (let i = 1; i < pageAmount + 1; i += 1) {
    classes =
      props.responseMetadata.currentPage !== undefined && currentPage === i
        ? 'page-item active'
        : 'page-item'

    pages.push(
      <li className={classes} key={i}>
        <a className="page-link" href="#" onClick={() => props.clickedPage(i)}>
          {i}
        </a>
      </li>
    )
  }

  return (
    <nav aria-label="Image pagination">
      <ul className="pagination">{pages}</ul>
    </nav>
  )
}

class ImageGalleryGrid extends React.Component {
  componentDidMount() {
    this.fetchImages()
  }

  componentDidUpdate() {
    const { fetchComplete, isFetching } = this.props.images

    if (fetchComplete || isFetching) {
      return
    }

    this.fetchImages()
  }

  fetchImages = (user = this.props.user, pageSize = 100, pageNumber = null) => {
    if (user) {
      this.props.fetchUserImages(pageSize, pageNumber)
    }
  }

  // Get the desired page number as a parameter and fetch images for that page
  changeImagePage = (pageNumber) => {
    this.fetchImages(this.props.user, 100, pageNumber)
  }

  render() {
    // save the id of the selected image of this event (or editor values)
    const selectedId = get(this.props.editor.values, 'image.id', null)

    // build the classes for the thumbnails
    const imgs = this.props.images.items.map((img) => {
      const selected = selectedId === img.id
      return (
        <ConnectedImageThumbnail
          selected={selected}
          key={img.id}
          url={img.url}
          data={img}
        />
      )
    })

    // ...and finally check if there is no image for this event to be able to set the class
    const selected = selectedId == null

    // unsift == prepend
    imgs.unshift(
      <ConnectedImageThumbnail
        selected={selected}
        key={0}
        empty={true}
        url=""
        data={{}}
      />
    )

    return (
      <div className="image-grid container-fluid">
        <div className="row">
          {imgs}
          <div className="clearfix" />
        </div>

        <ImagePagination
          clickedPage={this.changeImagePage}
          responseMetadata={
            Array.isArray(this.props.images.meta) ? {} : this.props.images.meta
          }
        />
      </div>
    )
  }
}

ImageGalleryGrid.propTypes = {
  images: PropTypes.object,
  user: PropTypes.object,
  editor: PropTypes.object,
  fetchUserImages: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
  fetchUserImages: (user, amount, pageNumber) =>
    dispatch(fetchUserImagesAction(user, amount, pageNumber)),
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapStateToProps = (state, ownProps) => ({})

ImagePagination.propTypes = {
  responseMetadata: PropTypes.object,
  clickedPage: PropTypes.func,
}

// TODO: if leave null, react-intl not refresh. Replace this with better React context
export const ConnectedImageGalleryGrid = connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageGalleryGrid)
