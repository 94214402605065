import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { MultiLanguageField } from '../../types/apiTypes'
import { MultiLanguageTextEditor } from '../HelFormFields/MultiLanguageTextEditor/MultiLanguageTextEditor'
import { setHtmlDescription } from '../../store/editor'

export const HtmlDescription: React.FC<{
  validationErrors: string[]
  index: string
}> = ({ validationErrors, index }) => {
  const field: MultiLanguageField = useSelector(
    (state: RootState) => state.editor.values.description_html
  )
  const languages = useSelector(
    (state: RootState) => state.editor.contentLanguages
  )
  const dispatch = useDispatch()

  return (
    <MultiLanguageTextEditor
      label="event-description"
      name="description_html"
      defaultValue={field}
      validationErrors={validationErrors}
      index={index}
      languages={languages}
      onChange={(lang, val) => {
        // Note that we dispatch the action everytime, when the text changes
        // This is a little bit different than other form fields,
        // which dispatch when they loose focus or after certain time.
        // This was just faster to implement and doesn't seem to cause performance issues.
        dispatch(setHtmlDescription({ lang, text: val }))
      }}
    />
  )
}
