import React from 'react'
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { FormattedMessage } from 'react-intl'

interface ConfirmationModalProps {
  msg: string
  cancel: () => void
  confirm: () => void
  style?: string
  actionButtonLabel?: string
  additionalMsg?: string
  additionalMarkup?: string
  open?: boolean
  loading?: boolean
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  msg,
  cancel,
  confirm,
  style = 'warning',
  actionButtonLabel = 'confirm',
  additionalMsg = '',
  additionalMarkup = ' ',
  open = true,
  loading = false,
}) => (
  <Dialog open={open} onClose={cancel} transitionDuration={0}>
    <DialogTitle>
      <span style={{ marginRight: 8 }}>
        <FormattedMessage id={msg} />
      </span>
      <IconButton onClick={cancel}>
        <Close />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <p>
        <strong>{additionalMsg || ''}</strong>
      </p>
      <div dangerouslySetInnerHTML={{ __html: additionalMarkup || '' }} />
    </DialogContent>
    <DialogActions>
      <Button
        style={{ paddingLeft: '32px', paddingRight: '32px' }}
        variant="outlined"
        onClick={cancel}
      >
        <FormattedMessage id="cancel" />
      </Button>
      <Button
        style={{ paddingLeft: '32px', paddingRight: '32px' }}
        variant="contained"
        color={style === 'warning' ? 'secondary' : 'primary'}
        onClick={confirm}
        disabled={loading}
        endIcon={loading && <CircularProgress color="inherit" size={25} />}
      >
        <FormattedMessage id={actionButtonLabel || 'confirm'} />
      </Button>
    </DialogActions>
  </Dialog>
)

export default ConfirmationModal
