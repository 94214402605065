import { ImagesType, constants } from '../constants'

// TODO: Set type for implicit props like items, selected, meta

type ImagesState = {
  isFetching: boolean
  fetchComplete: boolean
  items: []
  selected: boolean
  meta: []
}

// Is fetching checks?
const initialState: ImagesState = {
  isFetching: false,
  fetchComplete: false,
  items: [],

  selected: false,
  meta: [],
}

type ImagesAction = {
  type: ImagesType
  items: []
  data: boolean
  meta: []
}

export const update = (
  state = initialState,
  action: ImagesAction
): ImagesState => {
  if (action.type === constants.IMAGES.RECEIVE_IMAGES) {
    return {
      ...state,
      isFetching: false,
      fetchComplete: true,
      items: action.items,
    }
  }

  if (action.type === constants.IMAGES.RECEIVE_IMAGES_AND_META) {
    return {
      ...state,
      isFetching: false,
      fetchComplete: true,
      items: action.items,
      meta: action.meta,
    }
  }

  if (action.type === constants.IMAGES.RECEIVE_IMAGES_ERROR) {
    return {
      ...state,
      isFetching: false,
      fetchComplete: true,
      items: action.items,
    }
  }

  if (action.type === constants.IMAGES.REQUEST_IMAGES) {
    return {
      ...state,
      isFetching: true,
      fetchComplete: false,
      items: [],
    }
  }

  if (action.type === constants.IMAGES.REQUEST_IMAGES_AND_META) {
    return {
      ...state,
      isFetching: true,
      fetchComplete: false,
      items: [],
      meta: [],
    }
  }

  if (action.type === constants.IMAGES.IMAGE_UPLOAD_SUCCESS) {
    return {
      ...state,
      selected: action.data,
      fetchComplete: false,
    }
  }

  return state
}
