import './NoValue.scss'
import React from 'react'
import { FormattedMessage } from 'react-intl'

export const NoValue: React.FC<{
  labelKey?: string
}> = ({ labelKey }) => {
  const header = labelKey ? (
    <span>
      <FormattedMessage id={labelKey} />
      &nbsp;
    </span>
  ) : null
  return (
    <div className="no-value">
      {header}
      <FormattedMessage id="no-value" />
    </div>
  )
}
