import './CheckedValue.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'
import { helBrandColors } from '../../../themes/hel/hel-brand-colors'

export const CheckedValue = ({ checked, labelKey, label }) => (
  <div className="checked-value">
    {checked ? (
      <CheckBox htmlColor={helBrandColors.tram.main} />
    ) : (
      <CheckBoxOutlineBlank />
    )}
    <label>{labelKey ? <FormattedMessage id={labelKey} /> : label}</label>
  </div>
)

CheckedValue.propTypes = {
  checked: PropTypes.bool,
  labelKey: PropTypes.string,
  label: PropTypes.string,
}
