import { useEffect, useState } from 'react'
import { useClient } from '../../api/clientContext'
import { HobbyCategory, PaginatedResponse } from '../../types/apiTypes'
import {
  TopicType,
  YsoMapping,
  ysoShortCodeMapping,
} from '../../utils/formDataMapping'
import { RootState } from '..'

export type YsoLongCodeMapping = Partial<{
  [key in keyof YsoMapping]: string | undefined
}>

export const useHobbyCategories = (): HobbyCategory[] => {
  const client = useClient()
  const [hobbyCategories, setHobbyCategories] = useState<HobbyCategory[]>([])

  useEffect(() => {
    if (!client) {
      return
    }

    const fetchHobbyCategories = async (): Promise<HobbyCategory[]> => {
      const resp = await client.get<PaginatedResponse<HobbyCategory>>(
        'hobbycategory',
        {
          params: {
            text: null,
            page_size: 30,
          },
        }
      )

      return (
        resp.data?.data
          // There might be empty values in the database
          // filtering based on used fields. userLocale does not support swedish for example
          // therefore, if swedish value exists, is not needed to check.
          .filter((item) => item.name || item.name_fi || item.name_en)
      )
    }

    const json = localStorage.getItem('hobbyCategories')
    if (json) {
      const categories = JSON.parse(json) as HobbyCategory[]
      setHobbyCategories(categories)
    } else {
      fetchHobbyCategories().then((data) => {
        localStorage.setItem('hobbyCategories', JSON.stringify(data))
        setHobbyCategories(data)
      })
    }
  }, [client])

  return hobbyCategories
}

export const selectYsoMapper = (state: RootState): YsoLongCodeMapping => {
  const ysoLongCodeMapping: YsoLongCodeMapping = {}
  if (!(state.editor?.keywordSets.length > 0)) {
    return ysoLongCodeMapping
  }

  const keywords = state.editor.keywordSets.find(
    (x) => x.id == 'espoo:topics'
  )?.keywords

  if (!keywords) {
    return ysoLongCodeMapping
  }

  Object.keys(ysoShortCodeMapping).forEach((key) => {
    const found = keywords.filter(
      (x) => x.id == ysoShortCodeMapping[key as TopicType]
    )

    ysoLongCodeMapping[key as TopicType] =
      !found || found.length == 0 ? undefined : found[0]['@id']
  })

  return ysoLongCodeMapping
}
