import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { HelCheckbox } from '../HelFormFields'

export const DayCheckbox = ({ day, onChange, defaultChecked }) => {
  const changePasser = (event, value) => {
    onChange(day, value)
  }
  return (
    <div className="col-xs-12 col-md-6">
      <div className="recurring-day">
        <HelCheckbox
          onChange={changePasser}
          defaultChecked={defaultChecked}
          label={<FormattedMessage id={day} />}
        />
      </div>
    </div>
  )
}

DayCheckbox.propTypes = {
  day: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultChecked: PropTypes.bool,
}
