import React, { useEffect, useState } from 'react'
import { HelLabeledCheckboxGroup } from '../../HelFormFields'
import { RootState } from '../../../store'
import { useDispatch, useSelector } from 'react-redux'
import { setData } from '../../../actions/editor'
import { useIntl } from 'react-intl'
import { CheckboxOption } from '../../HelFormFields/HelLabeledCheckboxGroup/CheckboxOption'
import { HobbyCategory } from '../../../types/apiTypes'
import { UserLocale } from '../../../store/userLocale/types'
import { useHobbyCategories } from '../../../store/editor/keywords'

const mapHobbyCategories = (
  locale: UserLocale,
  hobbyCategories: HobbyCategory[]
): CheckboxOption[] => {
  return hobbyCategories.map((item) => {
    let label = item.name
    if (locale === 'fi') {
      label = item.name_fi
    }
    if (locale === 'en') {
      label = item.name_en
    }

    // The rest of the languages are not supported in UI.
    return {
      label,
      value: item['@id'],
    }
  })
}

const useHobbyCategoryOptions = (): CheckboxOption[] => {
  const categories = useHobbyCategories()
  const [options, setOptions] = useState<CheckboxOption[]>([])
  const locale = useSelector((state: RootState) => state.userLocale.locale)

  useEffect(() => {
    const opts = mapHobbyCategories(locale, categories)
    setOptions(opts)
  }, [categories, locale])

  return options
}

// TODO: Once you remove the old topics,
// Remove getHobbyCategoryOptions from HelSelect...
export const HobbyTopicsCheckboxGroup: React.FC = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const selectedValues = useSelector(
    (state: RootState) => state.editor.values.hobbyCategories
  )
  const validationErrors = useSelector(
    (state: RootState) => state.editor.validationErrors.hobbyCategories
  )
  const options = useHobbyCategoryOptions()

  return (
    <HelLabeledCheckboxGroup
      required={false}
      groupLabel={intl.formatMessage({ id: 'event-topics-label-hobby' })}
      options={options}
      validationErrors={validationErrors}
      selectedValues={selectedValues}
      itemClassName="col-lg-6 col-md-12"
      handleChange={(hobbyCategories) => {
        dispatch(setData({ hobbyCategories }))
      }}
    />
  )
}
