import '../SingleValueField.scss'
import React from 'react'
import { useIntl } from 'react-intl'
import { NoValue } from './NoValue'

export const TextValue: React.FC<{
  value?: string | number
  labelKey: string
}> = ({ value, labelKey }) => {
  const intl = useIntl()

  return (
    <div className="single-value-field">
      <div>
        <label>{intl.formatMessage({ id: labelKey })}</label>
      </div>
      {value === 0 || value ? (
        <span className="value">{value}</span>
      ) : (
        <NoValue labelKey={labelKey} />
      )}
    </div>
  )
}
