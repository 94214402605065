import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'

import '../../assets/additional_css/bootstrap.custom.min.css'
import '../../assets/main.scss'
import { HeaderBar } from '../../components/Header/Header'
import {
  fetchLanguages as fetchLanguagesAction,
  fetchKeywordSets as fetchKeywordSetsAction,
} from '../../actions/editor'
import { retrieveUserFromSession as retrieveUserFromSessionAction } from '../../actions/user'
import { HelMaterialTheme } from '../../themes/material-ui'
import { ConnectedNotifications } from '../Notification/Notification'
import MissingOrganizationBanner from '../../components/MissingOrganizationBanner/MissingOrganizationBanner'

// localized moment utils
class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return moment(date).format('DD.MM')
  }

  getDateTimePickerHeaderText(date) {
    return moment(date).format('DD.MM')
  }
}

class App extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    fetchKeywordSets: PropTypes.func,
  }

  static childContextTypes = {
    muiTheme: PropTypes.object,
    intl: PropTypes.object,
    dispatch: PropTypes.func,
  }

  getChildContext() {
    return {
      muiTheme: HelMaterialTheme,
      dispatch: this.props.dispatch,
      intl: this.props.intl,
    }
  }

  UNSAFE_componentWillMount() {
    // fetch Hel.fi languages
    this.props.fetchLanguages()

    // Prefetch editor related keyword sets
    this.props.fetchKeywordSets()

    // Fetch userdata
    this.props.user == null && this.props.retrieveUserFromSession()
  }

  render() {
    const { flashMsg } = this.props.app
    const { children } = this.props

    return (
      <MuiPickersUtilsProvider utils={LocalizedUtils}>
        <div>
          <HeaderBar />
          <MissingOrganizationBanner />
          <div className="content">{children}</div>
          <ConnectedNotifications flashMsg={flashMsg} />
        </div>
      </MuiPickersUtilsProvider>
    )
  }
}

App.propTypes = {
  intl: PropTypes.object,
  app: PropTypes.object,
  user: PropTypes.object,
  dispatch: PropTypes.func,
  fetchLanguages: PropTypes.func,
  retrieveUserFromSession: PropTypes.func,
}

const mapStateToProps = (state) => ({
  editor: state.editor,
  user: state.user,
  app: state.app,
})

const mapDispatchToProps = (dispatch) => ({
  fetchKeywordSets: () => dispatch(fetchKeywordSetsAction()),
  fetchLanguages: () => dispatch(fetchLanguagesAction()),
  retrieveUserFromSession: () => dispatch(retrieveUserFromSessionAction()),
})

export const ConnectedApp = injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(App)
)
