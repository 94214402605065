import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import UsersTable from './UsersTable'
import { useClient } from '../../api/clientContext'
import {
  OrganizationAccessRequest,
  OrganizationCreationRequest,
} from '../../types/apiTypes'
import { AxiosInstance, AxiosResponse } from 'axios'
import { debounce } from 'lodash'
import { constants } from '../../constants'
import { decreasePendingRequests, setFlashMsg } from '../../store/app'
import { useDispatch, useSelector } from 'react-redux'
import {
  OrganizationAccessRequestInfo,
  OrganizationCreationRequestInfo,
  UserAccessInfo,
  mapDataToOARInfo,
  mapDataToOCRInfo,
  mapDataToUserAccessInfo,
  oarsTableColumns,
  orgCreationTableColumns,
  usersTableColumns,
} from './UserManagementDTOs'
import { RootState } from '../../store'

export const getRest = (
  client: AxiosInstance,
  id: string,
  count: number,
  additional_params?: { [x: string]: string }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any, any>>[] => {
  if (count < constants.pageSize) return []
  const pageNumbers = [...Array(Math.ceil(count / constants.pageSize)).keys()]
    .map((i) => i + 1)
    .slice(1)
  return pageNumbers.map((n) =>
    client.get(id, {
      params: {
        page_size: constants.pageSize,
        page: n,
        ...(additional_params ?? {}),
      },
    })
  )
}

const UserManagementPage: React.FC = () => {
  const [loadingOARs, setLoadingOARs] = useState(true)
  const [loadingUsers, setLoadingUsers] = useState(true)
  const [loadingOCRs, setLoadingOCRs] = useState(true)
  const [dirtyState, setDirtyState] = useState<boolean | undefined>(undefined)
  const [userInfoData, setUserInfoData] = useState<UserAccessInfo[]>([])
  const [oarInfoData, setOarInfoData] = useState<
    OrganizationAccessRequestInfo[]
  >([])
  const [ocrInfoData, setOcrInfoData] = useState<
    OrganizationCreationRequestInfo[]
  >([])
  const intl = useIntl()
  const client = useClient()
  const dispatch = useDispatch()
  const user = useSelector((state: RootState) => state.user)

  const fetch = useMemo(() => {
    const fetchData = async (): Promise<void> => {
      const [
        userFirstResponse,
        organizationFirstResponse,
        oarFirstResponse,
        orgCreationRequestsFirst,
      ] = await Promise.all([
        client.get('user', { params: { page_size: constants.pageSize } }),
        client.get('organization', {
          params: { page_size: constants.pageSize },
        }),
        client.get('organizationaccessrequest', {
          params: { page_size: constants.pageSize },
        }),
        client.get('organizationcreationrequest', {
          params: { page_size: constants.pageSize },
        }),
      ])

      const restResponse = await Promise.all(
        [
          getRest(client, 'user', userFirstResponse.data.meta.count),
          getRest(
            client,
            'organization',
            organizationFirstResponse.data.meta.count
          ),
          getRest(
            client,
            'organizationaccessrequest',
            oarFirstResponse.data.meta.count
          ),
          getRest(
            client,
            'organizationcreationrequest',
            orgCreationRequestsFirst.data.meta.count
          ),
        ].flat()
      )

      const userData = [
        ...userFirstResponse.data.data,
        ...restResponse
          .filter((response) => 'username' in response.data.data[0])
          .flatMap((response) => response?.data.data),
      ]

      const organizationData = [
        ...organizationFirstResponse.data.data,
        ...restResponse
          .filter(
            (response) => response.data.data[0]['@type'] === 'Organization'
          )
          .flatMap((response) => response.data.data),
      ]

      const oarData = [
        ...oarFirstResponse.data.data,
        ...restResponse
          .filter(
            (response) =>
              response.data.data[0]['@type'] === 'OrganizationAccessRequest'
          )
          .flatMap((response) => response.data.data),
      ]

      const orgCreationReqData = [
        ...orgCreationRequestsFirst.data.data,
        ...restResponse
          .filter(
            (response) =>
              response.data.data[0]['@type'] === 'OrganizationCreationRequest'
          )
          .flatMap((res) => res.data.data),
      ]

      if (userData && Array.isArray(userData))
        setUserInfoData(
          userData.map((obj) => mapDataToUserAccessInfo(obj, organizationData))
        )

      if (oarData && Array.isArray(oarData))
        setOarInfoData(
          oarData
            .filter(
              (oar: OrganizationAccessRequest) => oar.request_status === 1
            )
            .map((oar: OrganizationAccessRequest) =>
              mapDataToOARInfo(oar, organizationData)
            )
        )
      if (orgCreationReqData && Array.isArray(orgCreationReqData)) {
        setOcrInfoData(
          orgCreationReqData
            .filter(
              (ocr: OrganizationCreationRequest) => ocr.request_status === 1
            )
            .map((ocr: OrganizationCreationRequest) => mapDataToOCRInfo(ocr))
        )
      }
      setLoadingOARs(false)
      setLoadingUsers(false)
      setLoadingOCRs(false)
    }

    return debounce(fetchData, 3000)
  }, [client])

  useEffect(() => {
    if (fetch && user) fetch()
  }, [fetch, dirtyState])

  const handleOrganizationCreationRequest = async (
    action: string,
    id: string
  ): Promise<void> => {
    if (!client) {
      console.warn('Client not initialized')
      return
    }

    setLoadingOCRs(true)

    try {
      await client.put(`organizationcreationrequest/${id}`, {
        action_type: action,
      })
      dispatch(
        setFlashMsg({
          msg: `organization-creation-request-${action.toLowerCase()}ed`,
          style: 'message',
          sticky: false,
        })
      )
      if (setOcrInfoData) {
        setOcrInfoData(ocrInfoData.filter((ocr) => ocr.id !== id))
      }
    } catch (error) {
      dispatch(
        setFlashMsg({
          msg: 'generic-error-message',
          style: 'error',
          sticky: false,
        })
      )
    }
    dispatch(decreasePendingRequests())

    setLoadingOARs(false)
    if (setDirtyState && setLoadingUsers) {
      setDirtyState(!dirtyState)
      setLoadingUsers(true)
    }
  }

  const handleProcessOrganizationAccessRequest = async (
    action: string,
    id: string
  ): Promise<void> => {
    if (!client) {
      console.warn('Client not initialized')
      return
    }

    setLoadingOARs(true)

    try {
      await client.put(`organizationaccessrequest/${id}`, {
        action_type: action,
      })
      dispatch(
        setFlashMsg({
          msg: `organization-access-request-${action.toLowerCase()}ed`,
          style: 'message',
          sticky: false,
        })
      )
      if (setOarInfoData) {
        setOarInfoData(oarInfoData.filter((oar) => oar.id !== id))
      }
    } catch (error) {
      dispatch(
        setFlashMsg({
          msg: 'generic-error-message',
          style: 'error',
          sticky: false,
        })
      )
    }
    dispatch(decreasePendingRequests())

    setLoadingOARs(false)
    if (setDirtyState && setLoadingUsers) {
      setDirtyState(!dirtyState)
      setLoadingUsers(true)
    }
  }

  return (
    <div className="container">
      <h1>{intl.formatMessage({ id: 'user-management-page' })}</h1>
      <UsersTable
        data={oarInfoData}
        loading={loadingOARs}
        columns={oarsTableColumns}
        headerId={'user-management-oars'}
        action={handleProcessOrganizationAccessRequest}
        actionLoading={loadingOARs}
      />
      <UsersTable
        data={ocrInfoData}
        loading={loadingOCRs}
        columns={orgCreationTableColumns}
        headerId={'user-management-ocrs'}
        action={handleOrganizationCreationRequest}
        actionLoading={loadingOCRs}
      />
      <UsersTable
        data={userInfoData}
        loading={loadingUsers}
        columns={usersTableColumns}
        headerId={'user-management-users'}
      />
    </div>
  )
}

export default UserManagementPage
