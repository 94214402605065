import { keys, isNil, pickBy } from 'lodash'
import { push } from 'connected-react-router'

import { preliminaryErrorHandler } from '../utils/errorHandler'
import { constants } from '../constants'
import {
  mapAPIDataToUIFormat,
  mapUIDataToAPIFormat,
  calculateSuperEventTime,
  createSubEventsFromFormValues,
  updateSubEventsFromFormValues,
  splitSubEvents,
} from '../utils/formDataMapping'
import { emptyField, scrollToTop } from '../utils/helpers'
import { deleteEvent, getEventIdFromUrl } from '../utils/events'

import { client } from '../api/client'
import { clearFlashMsg, setFlashMsg } from '../store/app/index'
import { setLoading } from '../store/editor'

import { doValidations } from '../validation/validator'
import { getContentLanguages } from '../utils/language'
import { toWrappedId } from '../utils/formDataMapping'

const { PUBLICATION_STATUS, SUPER_EVENT_TYPE_RECURRING, EVENT_CREATION } =
  constants

/**
 * Set editor form data
 * @param {obj} formValues      new form values
 */
export function setData(values) {
  return {
    type: constants.EDITOR_SETDATA,
    values,
  }
}

export function updateSubEvent(value, property, eventKey) {
  return {
    type: constants.EDITOR_UPDATE_SUB_EVENT,
    value,
    property,
    eventKey,
  }
}

export function sortSubEvents() {
  return {
    type: constants.EDITOR_SORT_SUB_EVENTS,
  }
}
export function setEventData(values, key) {
  return {
    type: constants.EDITOR_SETDATA,
    key,
    values,
    event: true,
  }
}
export function setOfferData(values, key) {
  return {
    type: constants.EDITOR_SETDATA,
    key,
    values,
    offer: true,
  }
}
export function addOffer(values) {
  return {
    type: constants.EDITOR_ADD_OFFER,
    values,
  }
}
export function deleteOffer(offerKey) {
  return {
    type: constants.EDITOR_DELETE_OFFER,
    offerKey,
  }
}
export function setFreeOffers(isFree) {
  return {
    type: constants.EDITOR_SET_FREE_OFFERS,
    isFree,
  }
}
export function setLanguages(languages) {
  return {
    type: constants.EDITOR_SETLANGUAGES,
    languages,
  }
}

// TODO: Refactor, remove the logic and only return object.
export function validateFor(publicationStatus) {
  if (
    publicationStatus === PUBLICATION_STATUS.PUBLIC ||
    publicationStatus === PUBLICATION_STATUS.DRAFT
  ) {
    return {
      type: constants.VALIDATE_FOR,
      validateFor: publicationStatus,
    }
  }

  return {
    type: constants.VALIDATE_FOR,
    validateFor: null,
  }
}

/**
 * Set validation errors for editor (shown with validation popovers)
 * @param {obj} errors
 */
export const setValidationErrors = (errors) => {
  return { type: constants.SET_VALIDATION_ERRORS, errors }
}

/**
 * Format descriptions to HTML
 * @param formValues
 */
const formatDescription = (formValues) => {
  // eslint-disable-next-line no-constant-binary-expression
  const formattedDescriptions = { ...formValues.description } || {}

  for (const [key, value] of Object.entries(formattedDescriptions)) {
    if (value) {
      const description = value
        .replace(/\n\n/g, '</p><p>')
        .replace(/\n/g, '<br/>')
        .replace(/&/g, '&amp;')
      // check if the value is already wrapped in a <p> tag
      const formattedDescription =
        description.indexOf('<p>') === 0 &&
        description.substring(description.length - 4) === '</p>'
          ? description
          : `<p>${description}</p>`

      formattedDescriptions[key] = formattedDescription
    }
  }

  return formattedDescriptions
}

/**
 * Replace all editor values
 * @param  {obj} formData     new form values to replace all existing values
 */
export function replaceData(formData) {
  return (dispatch, getState) => {
    const { keywordSets } = getState().editor
    let formObject = mapAPIDataToUIFormat(formData, keywordSets)
    const publicationStatus =
      formObject.publication_status || PUBLICATION_STATUS.PUBLIC

    // run only draft level validation before copying
    const validationErrors = doValidations(
      formObject,
      getContentLanguages(formObject),
      PUBLICATION_STATUS.DRAFT,
      keywordSets
    )
    // empty id, event_status, and any field that has validation errors
    keys(validationErrors).map((field) => {
      formObject = emptyField(formObject, field)
      return formObject
    })
    delete formObject.id
    delete formObject.event_status
    delete formObject.super_event_type
    formObject.sub_events = {}

    // here, we do more thorough validation
    dispatch(validateFor(publicationStatus))
    dispatch(setValidationErrors({}))
    dispatch({
      type: constants.EDITOR_REPLACEDATA,
      values: formObject,
    })
    return undefined
  }
}

/**
 * Clear all editor form data
 */
export const clearData = () => ({ type: constants.EDITOR_CLEARDATA })

const prepareFormValues =
  (formValues, publicationStatus) => (dispatch, getState) => {
    const { keywordSets, contentLanguages } = getState().editor

    // Run validations
    const validationErrors =
      formValues.super_event != null
        ? {}
        : doValidations(
            formValues,
            contentLanguages,
            publicationStatus,
            keywordSets
          )
    // There are validation errors, don't continue sending
    if (keys(validationErrors).length > 0) {
      dispatch(setLoading(false))
      dispatch(setValidationErrors(validationErrors))
      return undefined
    }

    let data = {
      ...formValues,
      publication_status: publicationStatus,
      description: formatDescription(formValues),
    }

    // specific processing for event with multiple dates
    if (formValues.super_event_type === 'recurring') {
      // calculate the super event's start_time and end_time based on its sub events
      const superEventTime = calculateSuperEventTime(
        pickBy(formValues.sub_events, (event) => !event.markAsDeleted)
      )
      data = {
        ...data,
        super_event_type: SUPER_EVENT_TYPE_RECURRING,
        start_time: superEventTime.start_time,
        end_time: superEventTime.end_time,
      }
    }

    return mapUIDataToAPIFormat(data, keywordSets)
  }

/**
 *
 * @param createdEventId
 * @param data
 * @param action
 */
export const sendDataComplete =
  (createdEventId, data, action) => (dispatch) => {
    if (data.apiErrorMsg) {
      dispatch(setFlashMsg(data.apiErrorMsg, 'error', data))
    } else {
      dispatch(push(`/event/done/${action}/${createdEventId}`))
      dispatch({ type: constants.EDITOR_SENDDATA_SUCCESS })
      scrollToTop()
    }
    dispatch(setLoading(false))
  }

export const executeSendRequest =
  (formValues, updateExisting, publicationStatus) => async (dispatch) => {
    // check publication status to decide whether to allow the request to happen
    const amendedPublicationStatus =
      publicationStatus || formValues.publication_status

    if (!amendedPublicationStatus || !formValues) {
      return
    }

    dispatch(setLoading(true))
    dispatch(validateFor(amendedPublicationStatus))

    // prepare the body of the request (event object/array)
    const preparedFormValues = dispatch(
      prepareFormValues(formValues, amendedPublicationStatus)
    )

    if (!preparedFormValues || !keys(preparedFormValues).length > 0) {
      return
    }

    const url = updateExisting ? `event/${formValues.id}` : 'event'

    try {
      const response = updateExisting
        ? await client.put(url, preparedFormValues)
        : await client.post(url, preparedFormValues)

      let { data } = response
      let createdEventId = data.id
      let actionName = updateExisting ? 'update' : 'create'

      // flash message is shown according to the actionName value
      if (
        data.publication_status === PUBLICATION_STATUS.PUBLIC &&
        data.publication_status !== formValues.publication_status
      ) {
        actionName = EVENT_CREATION.PUBLISH
      } else if (data.publication_status === PUBLICATION_STATUS.PUBLIC) {
        actionName = EVENT_CREATION.SAVE_PUBLIC
      } else if (data.publication_status === PUBLICATION_STATUS.DRAFT) {
        actionName = EVENT_CREATION.SAVE_DRAFT
      }

      // handle sub events if we created/updated a recurring event
      if (
        !isNil(data) &&
        data.super_event_type != null &&
        Object.keys(formValues.sub_events).length >
          Object.values(formValues.sub_events).filter((e) => e.markAsDeleted)
      ) {
        handleSubEvents(
          formValues,
          updateExisting,
          amendedPublicationStatus,
          data['@id'],
          dispatch
        )
      } else dispatch(sendDataComplete(createdEventId, data, actionName))
    } catch (error) {
      preliminaryErrorHandler(error, dispatch)
    }
  }

/**
 * @param {import('../store/editor').EditorValues} formValues Form data
 * @param {boolean} updateExisting Whether we're updating an existing event
 * @param {'draft' | 'public'} publicationStatus Publication status
 * @param {import('../types/apiTypes').APIEvent[]} subEvents Sub event data
 * @param {boolean?} updatingSubEvents Whether we're updating sub events
 */
export const handleSubEvents = async (
  formValues,
  updateExisting,
  publicationStatus,
  superEventUrl,
  dispatch
) => {
  // this tells the executeSendRequest method whether we're updating sub events
  const splittedSubEvents = splitSubEvents(formValues.sub_events)

  let subEventsToPost =
    Object.keys(splittedSubEvents.newSubEvents).length > 0 &&
    createSubEventsFromFormValues(
      { ...formValues, sub_events: splittedSubEvents.newSubEvents },
      superEventUrl
    )

  let subEventsToPut =
    Object.keys(splittedSubEvents.oldSubEvents).length > 0 &&
    (updateExisting && formValues.super_event_type === 'recurring'
      ? updateSubEventsFromFormValues(
          formValues,
          splittedSubEvents.oldSubEvents,
          superEventUrl
        )
      : Object.values(splittedSubEvents.oldSubEvents).map((subEvent) => ({
          ...subEvent,
          super_event: toWrappedId(superEventUrl),
        })))

  const deletableSubEvents = splittedSubEvents.oldSubEvents

  for (const key in deletableSubEvents) {
    const subEvent = deletableSubEvents[key]
    if (subEvent.markAsDeleted) {
      // remove the event from subEventsToSend - list, because it will be posted later to API.
      subEventsToPost =
        subEventsToPost &&
        subEventsToPost.filter(
          (subEventToSend) => subEventToSend.id !== subEvent.id
        )
      subEventsToPut =
        subEventsToPut &&
        subEventsToPut.filter(
          (subEventToSend) => subEventToSend.id !== subEvent.id
        )
      deleteEvent(subEvent)
    }
  }
  let promises = []

  if (subEventsToPut && subEventsToPut.length > 0)
    promises.push(
      client.put(
        'event',
        subEventsToPut.map((formObject) =>
          dispatch(prepareFormValues(formObject, publicationStatus))
        )
      )
    )

  if (subEventsToPost && subEventsToPost.length > 0)
    promises.push(
      client.post(
        'event',
        subEventsToPost.map((formObject) =>
          dispatch(prepareFormValues(formObject, publicationStatus))
        )
      )
    )

  if (promises.length === 0) {
    preliminaryErrorHandler(
      {
        message: 'Recurring event has no subevents',
      },
      dispatch
    )
    return
  }
  try {
    const response = await Promise.all(promises)
    const data = response.flatMap((x) => x.data)
    const pubStatus = data[0].publication_status

    let actionName = updateExisting ? 'update' : 'create'
    if (
      pubStatus === PUBLICATION_STATUS.PUBLIC &&
      pubStatus !== formValues.publication_status
    ) {
      actionName = EVENT_CREATION.PUBLISH
    } else if (pubStatus === PUBLICATION_STATUS.PUBLIC) {
      actionName = EVENT_CREATION.SAVE_PUBLIC
    } else if (pubStatus === PUBLICATION_STATUS.DRAFT) {
      actionName = EVENT_CREATION.SAVE_DRAFT
    }
    dispatch(
      sendDataComplete(getEventIdFromUrl(superEventUrl), data, actionName)
    )
  } catch (error) {
    preliminaryErrorHandler(error, dispatch)
  }
}

// Receive topic, place, and audience keywords

/** @param {import('../types/apiTypes').KeywordSet[]} keywordSets */
export const receiveKeywordSets = (keywordSets) => {
  localStorage.setItem('KEYWORDSETS', JSON.stringify(keywordSets))

  return {
    type: constants.EDITOR_RECEIVE_KEYWORDSETS,
    keywordSets,
  }
}

// Fetch keyword sets
export const fetchKeywordSets = () => async (dispatch) => {
  try {
    const response = await client.get('keyword_set', { include: 'keywords' })

    /** @type {import('../types/apiTypes').KeywordSet[]} */
    const keywordSets = response.data.data

    dispatch(receiveKeywordSets(keywordSets))
  } catch (e) {
    throw new Error(e)
  }
}

// Receive languages
export const receiveLanguages = (languages) => {
  localStorage.setItem('LANGUAGES', JSON.stringify(languages))

  return {
    type: constants.EDITOR_RECEIVE_LANGUAGES,
    languages,
  }
}

// Fetch languages
export const fetchLanguages = () => async (dispatch) => {
  try {
    const response = await client.get('language')
    const languages = response.data.data

    dispatch(receiveLanguages(languages))
  } catch (e) {
    throw new Error(e)
  }
}

export const setEventForEditing = (eventData) => (dispatch, getState) => {
  const { keywordSets } = getState().editor
  dispatch({
    type: constants.RECEIVE_EVENT_FOR_EDITING,
    event: eventData,
    keywordSets,
  })
  dispatch(setLanguages(getContentLanguages(eventData)))
}

export function setEditorAuthFlashMsg() {
  return (dispatch, getState) => {
    const { router, user } = getState()
    const pathName = router?.location?.pathname
    const isEditorRoute = ['/event/update/', '/event/create/'].some((path) =>
      pathName.includes(path)
    )

    if (isEditorRoute) {
      isNil(user)
        ? dispatch(
            setFlashMsg('editor-authorization-required', 'error', {
              sticky: true,
            })
          )
        : dispatch(clearFlashMsg())
    }
  }
}
