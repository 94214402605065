import React, { useEffect, useState } from 'react'
import {
  Button,
  CircularProgress,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  OrganizationAccessRequestInfo,
  OrganizationCreationRequestInfo,
  UserAccessInfo,
} from './UserManagementDTOs'
import { Search } from '@material-ui/icons'

import './UsersTable.scss'
import { isEmail } from 'validator'

export interface UsersTableColumn {
  id: string
  translationId: string
}

interface UsersTableProps {
  data: (
    | UserAccessInfo
    | OrganizationAccessRequestInfo
    | OrganizationCreationRequestInfo
  )[]
  loading: boolean // TODO: Should you use redux editor/setLoading instead?
  columns: UsersTableColumn[]
  headerId: string
  action?: (action: string, id: string) => void
  actionLoading?: boolean
}

const UsersTable: React.FC<UsersTableProps> = ({
  data: receivedData,
  loading,
  columns,
  headerId,
  action,
  actionLoading,
}) => {
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [searchQuery, setSearchQuery] = useState('')
  const [shownData, setShownData] = useState<
    (
      | UserAccessInfo
      | OrganizationAccessRequestInfo
      | OrganizationCreationRequestInfo
    )[]
  >([])
  const intl = useIntl()

  useEffect(() => {
    const dataToBeShown = !searchQuery
      ? receivedData
      : receivedData?.filter((dataRow) =>
          Object.values(dataRow).some((property) =>
            property?.toLowerCase().includes(searchQuery.toLowerCase())
          )
        )
    setShownData(dataToBeShown)
    setPage(0)
  }, [receivedData, searchQuery])

  const pageSizeOptions = [10, 25, 50, 100]
  const dataSliceStart = page * pageSize
  const dataSliceEnd = dataSliceStart + pageSize

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setPageSize(+event.target.value)
    setPage(0)
  }

  return (
    <>
      <h2>{intl.formatMessage({ id: headerId })}</h2>
      <TextField
        className="users-table-search"
        placeholder={intl.formatMessage({ id: 'search' })}
        onChange={(e) => setSearchQuery(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search color="disabled" />
            </InputAdornment>
          ),
        }}
      />
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <FormattedMessage id={column.translationId} />
                </TableCell>
              ))}
              {headerId === 'user-management-oars' && (
                <TableCell key="oar-actions-empty-head-column" />
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <CircularProgress style={{ margin: '10px 0' }} />
                </TableCell>
              </TableRow>
            ) : (
              shownData
                .slice(dataSliceStart, dataSliceEnd)
                .map((row: Record<string, string>) => (
                  <TableRow hover key={row.userName ?? row.id}>
                    {columns.map((column) => {
                      // Jos organisaatioita paljon niin miten rivitetään etc?
                      const isHashInEmailColumn =
                        column.id === 'email' && !isEmail(row[column.id] ?? '')
                      const value = isHashInEmailColumn ? '' : row[column.id]
                      return (
                        <TableCell key={row.userName + column.id}>
                          {value}
                        </TableCell>
                      )
                    })}
                    {!!action && (
                      <TableCell>
                        <div className="oar-buttons">
                          <Button
                            variant="outlined"
                            disabled={actionLoading}
                            onClick={() => action('REJECT', row.id)}
                            endIcon={
                              actionLoading && (
                                <CircularProgress color="inherit" size={25} />
                              )
                            }
                          >
                            {intl.formatMessage({
                              id: 'reject-organization-access-request',
                            })}
                          </Button>
                          <Button
                            variant="contained"
                            disabled={actionLoading}
                            onClick={() => action('GRANT', row.id)}
                            endIcon={
                              actionLoading && (
                                <CircularProgress color="inherit" size={25} />
                              )
                            }
                            color="primary"
                          >
                            {intl.formatMessage({
                              id: 'grant-organization-access-request',
                            })}
                          </Button>
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={pageSizeOptions}
                count={shownData.length}
                rowsPerPage={pageSize}
                page={page}
                onChangePage={(_event: unknown, newPage: number): void =>
                  setPage(newPage)
                }
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  )
}

export default UsersTable
