import React, { useRef } from 'react'
import moment from 'moment'
import momentTz from 'moment-timezone'
import { ValidationPopover } from '../ValidationPopover/ValidationPopover'
import { HelDatePicker } from './HelDatePicker'

export const HelDateTimeField: React.FC<{
  name: string
  label?: string
  defaultValue?: string
  validationErrors: string[] | undefined
  disabled?: boolean
  disablePast?: boolean
  placeholder?: string
  minDate?: string | moment.Moment
  maxDate?: moment.Moment | undefined
  required?: boolean
  onChange: (value?: moment.MomentInput | string | undefined) => void
}> = ({
  name,
  label,
  defaultValue,
  validationErrors,
  disabled,
  disablePast,
  placeholder,
  minDate,
  maxDate,
  required,
  onChange,
}) => {
  const containerRef = useRef(null)

  // Formats date to ISO string and converts to UTC time.
  // Then calls onChange function with the formatted value.
  const dateOnChange = (value: moment.Moment | null | undefined) => {
    let formattedValue: moment.MomentInput | string | undefined
    if (value) {
      // if valid, convert to utc time and format to ISO string
      // else, use moment input value if the date is invalid
      formattedValue = value.isValid()
        ? momentTz(value as momentTz.MomentInput)
            .tz('Europe/Helsinki')
            .utc()
            .toISOString()
        : value.creationData().input
    }

    onChange(formattedValue)
  }

  return (
    <div ref={containerRef} data-testid="date-picker">
      <HelDatePicker
        type="date-time"
        name={name}
        label={label}
        disabled={disabled}
        disablePast={disablePast}
        defaultValue={defaultValue}
        onChange={(value: moment.Moment) => dateOnChange(value)}
        minDate={minDate}
        maxDate={maxDate}
        required={required}
        placeholder={placeholder}
      />
      <ValidationPopover
        data-testid={label}
        anchor={containerRef.current}
        placement="right"
        validationErrors={validationErrors}
      />
    </div>
  )
}
