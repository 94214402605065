import React, { useState } from 'react'
import { RootState } from '../../../store'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import {
  EditorValues,
  addSubEvent,
  deleteSubEvent,
} from '../../../store/editor'
import { UIEvent, WrappedId } from '../../../types/apiTypes'
import { getStringWithLocale } from '../../../utils/locale'
import moment from 'moment'
import { AutoComplete } from './AutoComplete'
import { useIntl } from 'react-intl'
import './UmbrellaEventPicker.scss'

export const UmbrellaEventPicker = ({
  futureSubEvents,
  setACValue,
  isLoading,
}: {
  futureSubEvents: (UIEvent & WrappedId)[]
  setACValue: (val: string) => void
  isLoading: boolean
}) => {
  const [currentSubEvent, setCurrentSubEvent] = useState<string>('')
  const subEvents = useSelector(
    (state: RootState) => state.editor.values.sub_events
  )
  const locale = useSelector((state: RootState) => state.userLocale.locale)
  const formValues = useSelector((state: RootState) => state.editor.values)
  const oldSubEvents = useSelector(
    (state: RootState) => state.editor.old_subevent_ids
  )
  const intl = useIntl()
  const dispatch = useDispatch()

  const addToUmbrella = () => {
    if (currentSubEvent === '') return
    const subEventToAdd = futureSubEvents?.find(
      (x) => x['@id'] === currentSubEvent
    ) as unknown as EditorValues
    dispatch(addSubEvent({ event: subEventToAdd }))
    setCurrentSubEvent('')
  }

  return (
    <>
      <div className="umbrella-row">
        <AutoComplete
          setACValue={setACValue}
          isLoading={isLoading}
          label="UmbrellaSubEventPicker"
          pickHandler={(value: string) => {
            setCurrentSubEvent(value)
          }}
          options={futureSubEvents
            ?.filter((event: WrappedId & UIEvent) => {
              return (
                moment(event.start_time).isAfter(formValues.start_time) &&
                (formValues.end_time
                  ? moment(formValues.end_time).isAfter(moment(event.end_time))
                  : true) &&
                event.super_event == null &&
                event.super_event_type == null
              )
            })
            .map((e: UIEvent & WrappedId) => {
              return {
                name: `${getStringWithLocale(e, 'name', locale)} ${moment(e.start_time).format('DD.MM.YYYY')}`,
                value: e['@id'],
              }
            })}
        />
        <Button aria-label="add-to-umbrella" onClick={addToUmbrella}>
          {intl.formatMessage({ id: 'add-to-umbrella' })}
        </Button>
      </div>
      <div className="sub-event-listing">
        {subEvents && (
          <table>
            <tbody>
              {Object.entries(subEvents)
                .filter(([_, e]) => !e.markAsDeleted)
                .map(([subEventKey, e]) => (
                  <tr key={e.id}>
                    <td>{`${getStringWithLocale(e, 'name', locale)} ${moment(e.start_time).format('DD.MM.YYYY')}`}</td>
                    <td>
                      {!oldSubEvents?.includes(e['@id'] ?? '') ? (
                        <Button
                          onClick={() => {
                            dispatch(
                              deleteSubEvent({
                                subEventKey: parseInt(subEventKey),
                                event: e,
                                totalDelete: false,
                              })
                            )
                          }}
                        >
                          {intl.formatMessage({
                            id: 'remove-umbrella-sub-event',
                          })}
                        </Button>
                      ) : null}{' '}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  )
}
