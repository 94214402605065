import './ImagePicker.scss'

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import {
  Button,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
} from '@material-ui/core'
import { Close, ErrorOutline, Publish } from '@material-ui/icons'
import { get, isEmpty } from 'lodash'

import { deleteImage } from '../../actions/userImages'
import { ConnectedImageEdit } from '../ImageEdit/ImageEdit'
import { ConnectedImageGalleryGrid } from '../ImageGalleryGrid/ImageGalleryGrid'
import { HelMaterialTheme } from '../../themes/material-ui'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'

// Display either the image thumbnail or the "Add an image to the event" text.
const PreviewImage = (props) => {
  const backgroundImage = props.backgroundImage ? props.backgroundImage : null
  const backgroundStyle = { backgroundImage: `url(${backgroundImage})` }

  if (backgroundImage) {
    return (
      <div
        className="image-picker--preview"
        style={backgroundStyle}
        onClick={() => props.openModalMethod()}
      />
    )
  }

  return (
    <React.Fragment>
      <div
        className="image-picker--preview"
        onClick={() => props.openModalMethod()}
      >
        <Publish />
        <label>
          <FormattedMessage id="choose-image" />
        </label>
      </div>
    </React.Fragment>
  )
}

// ImagePicker can at any point in time be in only one of the following states
const State = Object.freeze({
  CLOSED: 'closed', // ImagePicker is closed
  OPEN: 'open', // ImagePicker is open in the gallery view
  // The following two states have been separated since the close behavior differs depending on the type of upload
  UPLOAD_LOCAL: 'uploadLocal', // Upload an image from the computer
  UPLOAD_EXTERNAL: 'uploadExternal', // Upload an image from an external URL
  EDIT: 'edit', // Edit an existing image
})

export class ImagePicker extends React.Component {
  constructor(props) {
    super(props)

    this.hiddenFileInput = React.createRef()

    this.state = {
      mode: State.CLOSED,
      imageFile: null,
      thumbnailUrl: null,
      fileSizeError: false,
      showConfirmationModal: false,
    }

    this.closeNestedDialog = this.closeNestedDialog.bind(this)
    this.closeNestedDialogAndResetUrl =
      this.closeNestedDialogAndResetUrl.bind(this)
  }

  clickHiddenUploadInput() {
    this.hiddenFileInput.current.click()
  }

  handleExternalImage = (event) => {
    this.setState({ thumbnailUrl: event.target.value })
  }

  handleExternalImageSave() {
    this.setState({ mode: State.UPLOAD_EXTERNAL, imageFile: null })
  }

  handleUpload(event) {
    const file = event.target.files[0]

    if (file && !this.validateFileSize(file)) {
      return
    }

    const data = new FormData()

    data.append('image', file)

    if (
      file &&
      (file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/gif')
    ) {
      this.setState({
        mode: State.UPLOAD_LOCAL,
        imageFile: file,
        thumbnailUrl: window.URL.createObjectURL(file),
      })
    }
  }

  validateFileSize = (file) => {
    const maxSizeInMB = 2

    const decimalFactor = 1024 * 1024

    const fileSizeInMB = parseInt(file.size, 10) / decimalFactor

    if (fileSizeInMB > maxSizeInMB) {
      this.setState({
        fileSizeError: true,
      })

      return false
    }

    if (this.state.fileSizeError) {
      this.setState({
        fileSizeError: false,
      })
    }

    return true
  }

  handleDelete(selectedImage, user) {
    if (!isEmpty(selectedImage)) {
      this.props.dispatch(deleteImage(selectedImage, user))
      this.setShowConfirmationModal(false)
    } else {
      console.warn('Image deletion attempted without specifying image.')
    }
  }

  setShowConfirmationModal(showModal) {
    this.setState({
      showConfirmationModal: showModal,
    })
  }

  handleEdit() {
    this.setState({
      mode: State.EDIT,
    })
  }

  closeGalleryModal() {
    this.setState({ mode: State.CLOSED })
  }

  openGalleryModal = () => {
    this.setState({ mode: State.OPEN })
  }

  closeNestedDialog = () => {
    this.setState({ mode: State.OPEN })
  }

  closeNestedDialogAndResetUrl = () => {
    // Reset also the thumbnail URL field
    this.setState({ mode: State.OPEN, thumbnailUrl: '' })
  }

  render() {
    const backgroundImage = get(this.props.editor.values, 'image.url', '')

    const { editor, user, images, loading } = this.props
    const { image } = this.props.editor.values
    const { imageFile, mode, thumbnailUrl } = this.state

    let editModal = null

    if (
      (mode === State.UPLOAD_LOCAL || mode === State.UPLOAD_EXTERNAL) &&
      thumbnailUrl
    ) {
      /* When adding a new image */
      editModal = (
        <ConnectedImageEdit
          imageFile={imageFile}
          thumbnailUrl={thumbnailUrl}
          close={
            mode === State.UPLOAD_LOCAL
              ? this.closeNestedDialogAndResetUrl
              : // When closing the external image upload dialog, we don't want to reset the external image URL field
                this.closeNestedDialog
          }
          onSave={this.closeNestedDialogAndResetUrl}
        />
      )
    } else if (mode === State.EDIT && !isEmpty(image)) {
      /* When editing existing image by pressing the edit button on top of the grid */
      editModal = (
        <ConnectedImageEdit
          id={image.id}
          defaultName={image.name}
          altText={image.alt_text}
          defaultPhotographerName={image.photographer_name}
          thumbnailUrl={image.url}
          license={image.license}
          close={this.closeNestedDialog}
          onSave={this.closeNestedDialogAndResetUrl}
          updateExisting
        />
      )
    }

    return (
      <div className="image-picker" data-testid="image-picker">
        {loading ? (
          <CircularProgress className="loading-spinner" size={60} />
        ) : (
          <PreviewImage
            backgroundImage={backgroundImage}
            openModalMethod={this.openGalleryModal}
          />
        )}

        <Dialog
          className="image-picker--dialog"
          open={this.state.mode === State.OPEN}
          fullWidth
          maxWidth="lg"
          onClose={() => this.closeGalleryModal()}
          transitionDuration={0}
        >
          <DialogTitle>
            <FormattedMessage id="event-image-title" />
            <IconButton onClick={() => this.closeGalleryModal()}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography className="image-picker--dialog-title" variant="h6">
              <FormattedMessage id="new-image" />
            </Typography>
            <div className="file-upload">
              <div className="file-upload--new">
                <input
                  onChange={(e) => this.handleUpload(e)}
                  style={{ display: 'none' }}
                  type="file"
                  ref={this.hiddenFileInput}
                />
                <Button
                  className="upload-img"
                  variant="contained"
                  color="primary"
                  onClick={() => this.clickHiddenUploadInput()}
                >
                  <FormattedMessage id="upload-image" />
                </Button>
                {this.state.fileSizeError && (
                  <React.Fragment>
                    <ErrorOutline
                      style={{ margin: HelMaterialTheme.spacing(0, 1, 0, 2) }}
                    />
                    <FormattedMessage id="uploaded-image-size-error" />
                  </React.Fragment>
                )}
              </div>
              <div className="file-upload--external">
                <TextField
                  className="file-upload--external-input"
                  label={<FormattedMessage id="upload-image-from-url" />}
                  value={thumbnailUrl ?? ''}
                  onChange={this.handleExternalImage}
                />
                <Button
                  className="file-upload--external-button"
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: HelMaterialTheme.spacing(2) }}
                  disabled={!thumbnailUrl}
                  onClick={() => this.handleExternalImageSave()}
                >
                  <FormattedMessage id="attach-image-to-event" />
                </Button>
              </div>
            </div>
            <hr />
            <Typography className="image-picker--dialog-title" variant="h6">
              <FormattedMessage id="use-existing-image" />
            </Typography>

            <div className={'button-row'}>
              <Button
                className="delete"
                color="secondary"
                variant="contained"
                onClick={() => this.setShowConfirmationModal(true)}
                disabled={isEmpty(image)}
              >
                <FormattedMessage id="delete-from-filesystem" />
              </Button>

              {this.state.showConfirmationModal && (
                <ConfirmationModal
                  msg="confirm-image-delete"
                  actionButtonLabel="delete"
                  cancel={() => this.setShowConfirmationModal(false)}
                  confirm={() => this.handleDelete(image, user)}
                  additionalMsg={image.name}
                />
              )}

              <div className={'wrapper-right'}>
                <Button
                  className="edit"
                  variant="contained"
                  disabled={isEmpty(image)}
                  onClick={() => this.handleEdit()}
                >
                  <FormattedMessage id="edit-selected-image" />
                </Button>
                <Button
                  className="attach"
                  variant="contained"
                  onClick={() => this.closeGalleryModal()}
                  color="primary"
                >
                  <FormattedMessage id="attach-image-to-event" />
                </Button>
              </div>
            </div>

            <ConnectedImageGalleryGrid
              editor={editor}
              user={user}
              images={images}
            />
          </DialogContent>
        </Dialog>
        {editModal}
      </div>
    )
  }
}

ImagePicker.defaultProps = {
  editor: {
    values: {},
  },
  images: {},
  user: {},
  loading: true,
}

ImagePicker.propTypes = {
  editor: PropTypes.object,
  user: PropTypes.object,
  images: PropTypes.object,
  children: PropTypes.element,
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
}

PreviewImage.propTypes = {
  backgroundImage: PropTypes.string,
  openModalMethod: PropTypes.func,
}

const mapStateToProps = (state) => ({
  user: state.user,
  editor: state.editor,
  images: state.images,
})

export const ConnectedImagePicker = injectIntl(
  connect(mapStateToProps)(ImagePicker)
)
