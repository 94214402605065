import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { IntlProvider } from 'react-intl'
import Intl from 'intl'

import { translations } from '../../i18n'
import { constants } from '../../constants'

require('@formatjs/intl-pluralrules/polyfill')
require('@formatjs/intl-pluralrules/locale-data/fi')
require('@formatjs/intl-relativetimeformat/polyfill')
require('@formatjs/intl-relativetimeformat/locale-data/fi')

if (window && !window.Intl) {
  window.Intl = Intl
}

class IntlProviderWrapper extends Component {
  render() {
    // TODO: With hooks, you can use const locale = useSelector(state => state.userLocale.locale)
    const {
      userLocale: { locale },
    } = this.props

    const mergedMessages = {
      ...translations[constants.DEFAULT_LOCALE],
      ...translations[locale],
    }

    return (
      <div>
        <IntlProvider
          textComponent="span"
          locale={locale}
          messages={mergedMessages}
        >
          {this.props.children}
        </IntlProvider>
      </div>
    )
  }
}

IntlProviderWrapper.propTypes = {
  children: PropTypes.element,
  messages: PropTypes.object,
  user: PropTypes.object,
  userLocale: PropTypes.object,
}

const mapStateToProps = ({ userLocale }) => ({
  userLocale,
})

export const ConnectedIntlProviderWrapper =
  connect(mapStateToProps)(IntlProviderWrapper)
