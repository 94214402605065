import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { RootState } from '../../../store'
import { RecurringEvent } from '../../RecurringEvent/RecurringEvent'
import { NewEvent } from './NewEvent'
import { Button } from '@material-ui/core'
import { HelMaterialTheme } from '../../../themes/material-ui'
import { Add, Autorenew, Delete } from '@material-ui/icons'
import { setEventData } from '../../../actions/editor'
import { deleteAllSubEvents } from '../../../store/editor'

const SubEventFields = () => {
  const validationErrors =
    useSelector(
      (state: RootState) => state.editor.validationErrors.sub_events
    ) ?? {}
  const sub_events =
    useSelector((state: RootState) => state.editor.values.sub_events) ?? {}

  return (
    <>
      {Object.entries(sub_events)
        // This filters events that user wants to delete, but are not posted to API yet.
        // So once user presses delete, the event actually disappears from the list.
        // If the event was from API, it still need to exist in the redux store, until the deletion logic occurs.
        .filter(([_, event]) => !event.markAsDeleted)
        .sort((a, b) => {
          const prev = a[1].start_time
          const next = b[1].start_time
          if (!prev || !next) {
            return 0
          }

          const isPrevValidDate = !isNaN(new Date(prev).getTime())
          const isNextValidDate = !isNaN(new Date(next).getTime())
          if (!isPrevValidDate || !isNextValidDate) {
            return 0
          }

          if (prev < next) {
            return -1
          }
          if (prev > next) {
            return 1
          }
          return 0
        })
        .map(([key, event]) => (
          <NewEvent
            key={key}
            eventKey={key}
            event={event}
            errors={validationErrors[parseInt(key)] ?? {}}
          />
        ))}
    </>
  )
}

export const RecurringEventFields = () => {
  const editorValues = useSelector((state: RootState) => state.editor.values)
  const sub_events =
    useSelector((state: RootState) => state.editor.values.sub_events) ?? {}
  const validationErrors = useSelector(
    (state: RootState) => state.editor.validationErrors
  )

  const [showRecurringEvent, setShowRecurringEvent] = React.useState(false)
  const { action: formType } = useParams<{ action: string; eventId: string }>()
  const intl = useIntl()
  const dispatch = useDispatch()

  const values = useSelector((state: RootState) => state.editor.values)

  const showRecurringEventDialog = () => {
    setShowRecurringEvent(!showRecurringEvent)
  }

  const addNewEventDialog = () => {
    const subEventKeys = Object.keys(sub_events).map((key) => parseInt(key))
    const key =
      subEventKeys.length > 0 ? Math.max.apply(null, subEventKeys) + 1 : 1
    const newEventObject = { [key]: {} }
    dispatch(setEventData(newEventObject, key))
  }

  const deleteSubEvents = () => {
    dispatch(deleteAllSubEvents(editorValues))
  }

  return (
    <>
      <div className="new-events show">
        <SubEventFields />
      </div>
      {showRecurringEvent && (
        <RecurringEvent
          toggle={() => showRecurringEventDialog()}
          validationErrors={validationErrors}
          values={values}
          formType={formType}
        />
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={() => showRecurringEventDialog()}
        startIcon={<Autorenew />}
        fullWidth
        style={{ marginTop: HelMaterialTheme.spacing(2) }}
      >
        {intl.formatMessage({ id: 'event-add-recurring' })}
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => addNewEventDialog()}
        startIcon={<Add />}
        fullWidth
        style={{ marginTop: HelMaterialTheme.spacing(2) }}
      >
        {intl.formatMessage({ id: 'event-add-new-occasion' })}
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => deleteSubEvents()}
        startIcon={<Delete />}
        fullWidth
        style={{ marginTop: HelMaterialTheme.spacing(2) }}
      >
        {intl.formatMessage({ id: 'event-delete-all-sub-events' })}
      </Button>
    </>
  )
}
