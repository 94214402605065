import React from 'react'
import { Language } from '../../../types/apiTypes'
import { useIntl } from 'react-intl'

export const HtmlValue: React.FC<{
  className: string
  lang: Language
  rawHtml?: string | null
}> = ({ className, lang, rawHtml }) => {
  const intl = useIntl()
  if (!rawHtml) return null

  return (
    <div className={className}>
      <div className={`in-${lang} indented`}>
        <label className="language">
          {intl.formatMessage({ id: `in-${lang}` })}
        </label>
        {rawHtml && <div dangerouslySetInnerHTML={{ __html: rawHtml }} />}
      </div>
    </div>
  )
}
