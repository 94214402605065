import React from 'react'
import { HelLabeledCheckboxGroup } from '../../HelFormFields'
import { RootState } from '../../../store'
import { useDispatch, useSelector } from 'react-redux'
import { CheckboxOption } from '../../HelFormFields/HelLabeledCheckboxGroup/CheckboxOption'
import { setData } from '../../../actions/editor'
import { useIntl } from 'react-intl'
import { mapKeywordSetToForm } from '../../../utils/apiDataMapping'
import { selectYsoMapper } from '../../../store/editor/keywords'

const useCultureEvents = (): CheckboxOption[] => {
  const keywordSets = useSelector(
    (state: RootState) => state.editor.keywordSets
  )
  const locale = useSelector((state: RootState) => state.userLocale.locale)
  const keywordMapping = useSelector(selectYsoMapper)

  const skipTheseYsoCodes = [
    keywordMapping.harrastushaku,
    keywordMapping.kirjastotapahtumat,
    keywordMapping.kulttuuritapahtumat,
    keywordMapping.muuttapahtumat,
  ]

  const topicOptions = mapKeywordSetToForm(keywordSets, 'espoo:topics', locale)
    .sort((a, b) => String(a.label).localeCompare(b.label ?? ''))

    // these 4 possible code are filtered out from the list,
    // because this' parent component (Topics.tsx) already has them.
    // They are confusing for the user if shown also here.
    .filter((item) => !skipTheseYsoCodes.includes(item.value))

    .map((item) => item as CheckboxOption)

  return topicOptions
}

export const CultureAndOthersCheckboxGroup: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const selectedValues = useSelector(
    (state: RootState) => state.editor.values.keywords
  )
  const validationErrors = useSelector(
    (state: RootState) => state.editor.validationErrors.keywords
  )
  const options = useCultureEvents()

  return (
    <HelLabeledCheckboxGroup
      required={false}
      groupLabel={intl.formatMessage({ id: 'event-topics-label-culture' })}
      options={options}
      validationErrors={validationErrors}
      selectedValues={selectedValues}
      itemClassName="col-lg-6 col-md-12"
      // store data in the old keywords - field
      handleChange={(keywords) => dispatch(setData({ keywords }))}
    />
  )
}
