import React, { useRef, useState } from 'react'
import { getLogoutUrl } from '../../utils/user'
import { Button } from '@material-ui/core'
import { HelMaterialTheme } from '../../themes/material-ui'
import { useDispatch, useSelector } from 'react-redux'
import { logout as logoutAction } from '../../actions/user'
import { FormattedMessage } from 'react-intl'
import { RootState } from '../../store'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import { clearLoginType } from '../../store/app'

const LogoutButton: React.FC = () => {
  const logoutForm = useRef<HTMLFormElement>(null)
  const dispatch = useDispatch()
  const user = useSelector((state: RootState) => state.user)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const loginType = useSelector((state: RootState) => state?.app.loginType)

  const submitLogoutForm = (): void => {
    // Clear user data from localstorage and state
    dispatch(logoutAction())
    // Submit logout form in order to send POST request to API gateway logout URL
    logoutForm.current?.requestSubmit()
    localStorage.removeItem('loginType')
    localStorage.removeItem('EDITOR_VALUES')
    dispatch(clearLoginType())
    setShowConfirmationModal(false)
  }

  return (
    <>
      <form
        ref={logoutForm}
        action={getLogoutUrl(loginType)}
        method="POST"
      ></form>
      <Button
        style={{ color: HelMaterialTheme.palette.primary.contrastText }}
        disabled
      >
        {user.displayName}
      </Button>
      <Button
        style={{ color: HelMaterialTheme.palette.primary.contrastText }}
        onClick={() => setShowConfirmationModal(true)}
      >
        <FormattedMessage id="logout" />
      </Button>

      {showConfirmationModal && (
        <ConfirmationModal
          msg="confirm-logout"
          style="message"
          actionButtonLabel="logout"
          cancel={() => setShowConfirmationModal(false)}
          confirm={submitLogoutForm}
        />
      )}
    </>
  )
}

export default LogoutButton
