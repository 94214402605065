import './../MultiField.scss'
import React, { useRef } from 'react'
import { Language, MultiLanguageField } from '../../../types/apiTypes'
import { useIntl } from 'react-intl'
import { TextEditor } from '../../TextEditor/TextEditor'
import { ValidationPopover } from '../../ValidationPopover/ValidationPopover'

export const MultiLanguageTextEditor: React.FC<{
  label: string
  name: string
  validationErrors: string[]
  index: string
  defaultValue?: MultiLanguageField
  languages?: Language[]
  onChange: (lang: Language, value: string) => void
}> = ({
  label,
  name,
  validationErrors,
  index,
  defaultValue,
  languages = ['fi' as Language],
  onChange,
}) => {
  const intl = useIntl()
  const labelRef = useRef(null)

  const getValue = (
    lang: Language,
    field?: MultiLanguageField
  ): string | null | undefined => field?.[lang]

  if (languages.length === 1) {
    const lang = languages[0]
    const defVal = getValue(lang, defaultValue)

    const fieldLabel = `${intl.formatMessage({
      id: label,
    })} (${intl.formatMessage({ id: `in-${lang}` })})`

    return (
      <div>
        <div
          key={`name_${lang}`}
          style={{ position: 'relative' }}
          ref={labelRef}
        ></div>
        <TextEditor
          data-testid={label}
          label={fieldLabel}
          labelRef={labelRef}
          index={index}
          name={name}
          validationErrors={validationErrors}
          defaultValue={defVal}
          onChange={(_, __, ___, editor) => onChange(lang, editor.getHTML())}
        />
      </div>
    )
  }

  return (
    <div className="multi-field">
      <div className="indented">
        <label ref={labelRef}>
          {intl.formatMessage({ id: label })}
          <ValidationPopover
            index={index}
            anchor={labelRef.current}
            validationErrors={validationErrors}
          />
        </label>
        {languages.map((lang) => {
          // labelKey used here
          const defVal = getValue(lang, defaultValue)
          return (
            <React.Fragment key={lang}>
              <TextEditor
                label={intl.formatMessage({ id: `in-${lang}` })}
                name={name}
                index={index}
                validationErrors={validationErrors}
                defaultValue={defVal}
                onChange={(_, __, ___, editor) =>
                  onChange(lang, editor.getHTML())
                }
              />
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}
