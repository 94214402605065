import React from 'react'
import ReactDOM from 'react-dom'
import { Route } from 'react-router'
import { withRouter } from 'react-router-dom'
import { Provider, ReactReduxContext, connect } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { ThemeProvider } from '@material-ui/core'
import moment from 'moment'
import * as momentTimezone from 'moment-timezone'

import { DocumentHead } from './components/DocumentHead/DocumentHead'

import LoginModal from './components/LoginModal/LoginModal'
import { ConnectedApp } from './views/App/App'
import { ConnectedEditorPage } from './views/Editor/Editor'
import { SearchPage } from './views/Search/Search'
import { HelpPage } from './views/Help/Help'
import { TermsPage } from './views/Terms/Terms'
import UserInfoPage from './views/UserInformation/UserInfoPage'
import UserManagementPage from './views/UserManagement/UserManagement'
import { ConnectedEventPage } from './views/Event/Event'
import { ConnectedEventCreatedPage } from './views/EventCreated/EventCreated'
import { ConnectedEventListingPage } from './views/EventListing/EventListing'
import { ConnectedModerationPage } from './views/Moderation/Moderation'

// translation
import { ConnectedIntlProviderWrapper } from './components/IntlProviderWrapper/IntlProviderWrapper'
import { store, history } from './store'
import { ClientProvider } from './api/clientContext'

import { features } from './utils/featureFlags'
import { HelMaterialTheme } from './themes/material-ui'

// Moment locale
moment.locale('fi')
momentTimezone.locale('fi')

const LayoutContainer = withRouter(connect()(ConnectedApp))

ReactDOM.render(
  <Provider store={store} context={ReactReduxContext}>
    <ThemeProvider theme={HelMaterialTheme}>
      <ConnectedIntlProviderWrapper>
        <ClientProvider>
          <ConnectedRouter history={history} context={ReactReduxContext}>
            <DocumentHead />
            <LoginModal />
            <LayoutContainer>
              <Route exact path="/" component={ConnectedEventListingPage} />
              <Route
                exact
                path="/event/:eventId"
                component={ConnectedEventPage}
              />
              <Route
                exact
                path="/event/:action/:eventId"
                component={ConnectedEditorPage}
              />
              <Route
                exact
                path="/event/done/:action/:eventId"
                component={ConnectedEventCreatedPage}
              />
              <Route exact path="/search" component={SearchPage} />
              <Route exact path="/help" component={HelpPage} />
              <Route exact path="/terms" component={TermsPage} />
              {features.userManagement && (
                <>
                  <Route exact path="/user" component={UserInfoPage} />
                  <Route exaxt path="/users" component={UserManagementPage} />
                </>
              )}
              <Route
                exact
                path="/moderation"
                component={ConnectedModerationPage}
              />
            </LayoutContainer>
          </ConnectedRouter>
        </ClientProvider>
      </ConnectedIntlProviderWrapper>
    </ThemeProvider>
  </Provider>,
  document.getElementById('content')
)
