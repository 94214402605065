import '../MultiValueField.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { each, keys } from 'lodash'
import { FormattedMessage } from 'react-intl'
import { NoValue } from './NoValue'

export const MultiLanguageValue = (props) => {
  if (props.hidden) {
    return <div />
  }

  const value = props.value || []

  const count = keys(value).length

  // Determine column size depending on the amount of language options
  let colClass = 'col-md-12'
  if (count > 1) {
    colClass = count === 2 ? 'col-md-6' : 'col-md-4'
  }

  // Use a separate array to ensure correct field order
  const langOptions = ['fi', 'sv', 'en']
  const elements = []

  each(langOptions, (key) => {
    const val = value[key]
    const createHTML = () => ({ __html: val })

    if (val) {
      elements.push(
        <div className={colClass} key={key}>
          <div className={`in-${key} indented`}>
            <label className="language">
              <FormattedMessage id={`in-${key}`} />
            </label>
            <div dangerouslySetInnerHTML={createHTML()} />
          </div>
        </div>
      )
    }
  })

  if (elements.length > 0) {
    return (
      <div className="multi-value-field">
        <label>
          <FormattedMessage id={`${props.labelKey}`} />
        </label>
        <div className="row">{elements}</div>
      </div>
    )
  }

  return (
    <div className="multi-value-field">
      <label>
        <FormattedMessage id={`${props.labelKey}`} />
      </label>
      <div>
        <NoValue labelKey={props.labelKey} />
      </div>
    </div>
  )
}

MultiLanguageValue.propTypes = {
  hidden: PropTypes.bool,
  value: PropTypes.object,
  labelKey: PropTypes.string,
}
