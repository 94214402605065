import { constants } from '../constants'

// Saved in store.user
// const initialState = null

const initialState = null

export const update = (state = initialState, action) => {
  switch (action.type) {
    // Login
    case constants.RECEIVE_USERDATA:
      if (action.payload && action.payload.id) {
        // TODO: get from payload
        return {
          ...action.payload,
        }
      }

      return state
    // Logout
    case constants.CLEAR_USERDATA:
      return null
    default:
      return state
  }
}
