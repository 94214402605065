import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/lib/pipeable'
import { appSettings } from '../types/global'

interface Keyword {
  '@id': string
  id: string
}

interface KeywordSet {
  id: string
  keywords: Keyword[]
}

interface Location {
  id: string
  postalCode: string
}

const ESPOO_LOCATION_ID = `${appSettings.systemDataSourceId}:espoo`
const INTERNET_LOCATION_ID = `${appSettings.systemDataSourceId}:internet`

const ESPOO_PLACES_KEYWORD_SET_ID = 'espoo:places'
const NON_ESPOO_PLACE_KEYWORD_ID = 'espoo:p62'
const ONLINE_PLACE_KEYWORD_ID = 'espoo:p63'

const NON_DISTRICT_PLACE_KEYWORD_IDS = [
  NON_ESPOO_PLACE_KEYWORD_ID,
  ONLINE_PLACE_KEYWORD_ID,
]

// Note that mapping locations to districts based on postal codes is a naive implementation since the mapping isn't
// unambiguous. For instance, certain postal codes can map to multiple districts and vice versa. The mapping also isn't
// exactly one-to-one in the sense that postal code areas do not exactly follow district borders. Thus, it might happen
// that an event is organized in a location which has a certain postal code and is thus mapped to a certain district but
// the actual location is outside the district. Also, note that some locations may have postal codes that aren't exactly
// tied to the actual location. For instance, Sellosali has the postal code "02070" which is the postal code for the
// City of Espoo and not a certain geographical area such as Leppävaara where Sellosali is actually located. Thus,
// certain postal codes can't be used to map events to specific geographical districts automatically. Currently, there
// are 135 postal codes for Espoo of which 46 are mapped to actual geographical districts. These 46 postal codes have
// been mapped to districts in the below mapping. This mapping is based on information from:
// - Statistics Finland (http://www.stat.fi/tup/paavo/tietosisalto_ja_esimerkit.html, file: "Postinumero-kunta -avain
//   2020 (xlsx)") for getting the mapping of postal codes to districts
// - Wikipedia (https://fi.wikipedia.org/wiki/Luettelo_Suomen_postinumeroista_kunnittain) for getting the mapping of
//   postal codes to districts
// - The Paikkatietoikkuna service (https://kartta.paikkatietoikkuna.fi) for getting the mapping of postal codes to
//   districts based on postal code and district borders. This can be achieved by selecting, e.g., the map layers:
//   Paavo-postinumeroalueet 2020, Espoon tilastoalueet, and Kuntajako.
// - Posti's Postal Code Data File (PCF) for getting a listing of all postal codes for Espoo including those that aren't
//   tied to a certain geographical district (https://www.posti.fi/fi/asiakastuki/postinumerotiedostot)
// - Wikipedia (https://fi.wikipedia.org/wiki/Luettelo_Espoon_kaupunginosista) for a listing of Espoo's districts and
//   their postal codes
// NOTE! If you update this mapping, remember to also update the corresponding mapping in espooevents-service.
/* eslint-disable max-len */
const POSTAL_CODE_TO_PLACE_KEYWORD_ID: Record<string, string[]> = {
  // The names of the places in the comments are based on the postal code area name in the "Postinumero-kunta-avain"
  // file and the place keyword name in Espoo Events
  '02100': ['espoo:p54'], // Tapiola -> Tapiola
  '02110': ['espoo:p54'], // Otsolahti -> Tapiola
  '02120': ['espoo:p54'], // Länsikorkee-Suvikumpu -> Tapiola
  '02130': ['espoo:p46'], // Pohjois-Tapiola -> Pohjois-Tapiola
  '02140': ['espoo:p26'], // Laajalahti -> Laajalahti
  '02150': ['espoo:p18', 'espoo:p44'], // Otaniemi -> Keilaniemi, Otaniemi
  '02160': ['espoo:p59'], // Westend -> Westend
  '02170': ['espoo:p7'], // Haukilahti -> Haukilahti
  '02180': ['espoo:p35'], // Mankkaa -> Mankkaa
  '02200': ['espoo:p39'], // Niittykumpu -> Niittykumpu
  '02210': ['espoo:p43'], // Olari -> Olari
  '02230': ['espoo:p36'], // Matinkylä -> Matinkylä
  '02240': ['espoo:p43'], // Friisilä -> Olari
  '02250': ['espoo:p8', 'espoo:p52'], // Henttaa -> Henttaa, Suurpelto
  '02260': ['espoo:p11'], // Kaitaa -> Kaitaa
  '02270': ['espoo:p5'], // Finnoo-Eestinmalmi -> Finnoo
  '02280': ['espoo:p30', 'espoo:p42'], // Malminmäki-Eestinlaakso -> Latokaski, Nöykkiö
  '02290': ['espoo:p43'], // Puolarmetsän sairaala -> Olari
  '02300': ['espoo:p42'], // Nöykkiönpuro -> Nöykkiö
  '02320': ['espoo:p4', 'espoo:p21'], // Espoonlahti -> Espoonlahti, Kivenlahti
  '02330': ['espoo:p42', 'espoo:p48'], // Saunalahti-Kattilalaakso -> Nöykkiö, Saunalahti
  '02340': ['espoo:p30'], // Latokaski -> Latokaski
  '02360': ['espoo:p51'], // Soukka -> Soukka
  '02380': ['espoo:p53'], // Suvisaaristo -> Suvisaaristo
  '02600': ['espoo:p31'], // Etelä-Leppävaara -> Leppävaara
  '02610': ['espoo:p20'], // Kilo -> Kilo
  '02620': ['espoo:p13'], // Karakallio -> Karakallio
  '02630': ['espoo:p19', 'espoo:p20'], // Nihtisilta -> Kera, Kilo
  '02650': ['espoo:p31'], // Pohjois-Leppävaara -> Leppävaara
  '02660': ['espoo:p32'], // Lintuvaara -> Lintuvaara
  '02680': ['espoo:p32'], // Uusmäki -> Lintuvaara
  '02710': ['espoo:p60'], // Viherlaakso -> Viherlaakso
  '02720': ['espoo:p27'], // Lähderanta -> Laaksolahti
  '02730': ['espoo:p30'], // Jupperi -> Laaksolahti
  '02740': ['espoo:p15', 'espoo:p23'], // Bemböle-Pakankylä -> Karvasmäki, Kunnarla
  '02750': ['espoo:p25', 'espoo:p35', 'espoo:p49'], // Sepänkylä-Kuurinniitty -> Kuurinniitty, Mankkaa, Sepänkylä
  '02760': ['espoo:p2'], // Tuomarila-Suvela -> Espoon keskus
  '02770': ['espoo:p2', 'espoo:p17', 'espoo:p37'], // Espoon Keskus -> Espoon keskus, Kaupunginkallio, Muurala
  '02780': ['espoo:p3', 'espoo:p16', 'espoo:p24', 'espoo:p57'], // Kauklahti -> Espoonkartano, Kauklahti, Kurttila, Vanttila
  '02810': ['espoo:p6', 'espoo:p14'], // Gumböle-Karhusuo -> Gumböle, Karhusuo
  '02820': ['espoo:p22', 'espoo:p40', 'espoo:p41', 'espoo:p56'], // Nupuri-Nuuksio -> Kolmperä, Nupuri, Nuuksio, Vanha-Nuuksio
  '02860': ['espoo:p50'], // Siikajärvi -> Siikajärvi
  '02920': ['espoo:p38', 'espoo:p45', 'espoo:p55', 'espoo:p61'], // Niipperi -> Niipperi, Perusmäki, Vanhakartano, Ämmässuo
  '02940': ['espoo:p1', 'espoo:p9', 'espoo:p10', 'espoo:p33', 'espoo:p47'], // Lippajärvi-Järvenperä -> Bodom, Högnäs, Järvenperä, Lippajärvi, Röylä
  '02970': ['espoo:p12', 'espoo:p28', 'espoo:p34'], // Kalajärvi -> Kalajärvi, Lahnus, Luukki
  '02980': ['espoo:p29', 'espoo:p58'], // Lakisto -> Lakisto, Velskola
}
/* eslint-enable max-len */

const NON_GEOGRAPHICAL_POSTAL_CODES_FOR_ESPOO = [
  '00095',
  '02008',
  '02010',
  '02014',
  '02020',
  '02022',
  '02044',
  '02066',
  '02070',
  '02101',
  '02104',
  '02105',
  '02124',
  '02131',
  '02134',
  '02135',
  '02151',
  '02154',
  '02155',
  '02171',
  '02174',
  '02175',
  '02184',
  '02201',
  '02204',
  '02207',
  '02211',
  '02214',
  '02215',
  '02231',
  '02234',
  '02235',
  '02241',
  '02244',
  '02254',
  '02264',
  '02271',
  '02274',
  '02275',
  '02284',
  '02285',
  '02304',
  '02321',
  '02324',
  '02325',
  '02334',
  '02335',
  '02344',
  '02361',
  '02364',
  '02365',
  '02601',
  '02604',
  '02605',
  '02611',
  '02614',
  '02615',
  '02621',
  '02624',
  '02631',
  '02634',
  '02635',
  '02654',
  '02661',
  '02664',
  '02665',
  '02677',
  '02684',
  '02711',
  '02715',
  '02725',
  '02744',
  '02754',
  '02755',
  '02761',
  '02764',
  '02765',
  '02771',
  '02774',
  '02775',
  '02781',
  '02784',
  '02885',
  '02921',
  '02924',
  '02925',
  '02941',
  '02944',
  '02945',
]

const NON_GEOGRAPHICAL_POSTAL_CODE_LOCATION_TO_PLACE_KEYWORD_ID: {
  [x: string]: string
} = {
  'tprek:26429': 'espoo:p31', // Sellosali -> Leppävaara
}

const getEspooPlacesKeywordSet = (
  keywordSets: KeywordSet[]
): O.Option<KeywordSet> =>
  pipe(
    A.findFirst(
      (keywordSet: KeywordSet) => keywordSet.id === ESPOO_PLACES_KEYWORD_SET_ID
    )(keywordSets)
  )

const getKeywordUriBasedOnId = (
  keywordSet: KeywordSet,
  keywordId: string
): O.Option<string> =>
  pipe(
    A.findFirst((keyword: Keyword) => keyword.id === keywordId)(
      keywordSet.keywords
    ),
    O.map((keyword) => keyword['@id'])
  )

const getAllDistrictPlaceKeywordUris = (keywordSet: KeywordSet): string[] =>
  pipe(
    A.filter(
      (keyword: Keyword) => !NON_DISTRICT_PLACE_KEYWORD_IDS.includes(keyword.id)
    )(keywordSet.keywords),
    A.map((keyword) => keyword['@id'])
  )

const getEspooDistrictIdsBasedOnPostalCode = (
  postalCode: string
): O.Option<string[]> =>
  pipe(O.fromNullable(POSTAL_CODE_TO_PLACE_KEYWORD_ID[postalCode]))

const isPostalCodeEspooNonGeographical = (postalCode: string): boolean =>
  NON_GEOGRAPHICAL_POSTAL_CODES_FOR_ESPOO.includes(postalCode)

const getEspooDistrictIdForLocationWithNonGeographicalPostalCode = (
  locationId: string
): O.Option<string> =>
  pipe(
    O.fromNullable(
      NON_GEOGRAPHICAL_POSTAL_CODE_LOCATION_TO_PLACE_KEYWORD_ID[locationId]
    )
  )

const setOnlinePlaceKeyword = (
  currentPlaceKeywords: Set<string>,
  keywordSets: KeywordSet[]
): Set<string> => {
  const onlineEventPlaceKeywordUri = pipe(
    getEspooPlacesKeywordSet(keywordSets),
    O.chain((keywordSet) =>
      getKeywordUriBasedOnId(keywordSet, ONLINE_PLACE_KEYWORD_ID)
    )
  )

  if (O.isSome(onlineEventPlaceKeywordUri)) {
    currentPlaceKeywords.add(onlineEventPlaceKeywordUri.value)
  }

  return currentPlaceKeywords
}

const setAllEspooPlaceDistrictKeywords = (
  currentPlaceKeywords: Set<string>,
  keywordSets: KeywordSet[]
): Set<string> => {
  const districtPlaceKeywordUris: string[] = pipe(
    getEspooPlacesKeywordSet(keywordSets),
    O.map((keywordSet) => getAllDistrictPlaceKeywordUris(keywordSet)),
    O.getOrElseW(() => [])
  )

  districtPlaceKeywordUris.map((districtPlaceKeywordUri) =>
    currentPlaceKeywords.add(districtPlaceKeywordUri)
  )

  return currentPlaceKeywords
}

const setNonEspooPlaceKeyword = (
  currentPlaceKeywords: Set<string>,
  keywordSets: KeywordSet[]
): Set<string> => {
  const nonEspooPlaceKeywordUri = pipe(
    getEspooPlacesKeywordSet(keywordSets),
    O.chain((keywordSet) =>
      getKeywordUriBasedOnId(keywordSet, NON_ESPOO_PLACE_KEYWORD_ID)
    )
  )

  if (O.isSome(nonEspooPlaceKeywordUri)) {
    currentPlaceKeywords.add(nonEspooPlaceKeywordUri.value)
  }

  return currentPlaceKeywords
}

const setEspooDistrictKeywordsBasedOnDistrictIds = (
  districtIds: string[],
  currentPlaceKeywords: Set<string>,
  keywordSets: KeywordSet[]
): Set<string> => {
  const districtPlaceKeywordUris: readonly string[] = pipe(
    getEspooPlacesKeywordSet(keywordSets),
    O.map((keywordSet) =>
      districtIds.map((districtId) =>
        getKeywordUriBasedOnId(keywordSet, districtId)
      )
    ),
    O.chain((x) => O.sequenceArray(x)),
    O.getOrElseW(() => [])
  )

  districtPlaceKeywordUris.map((districtPlaceKeywordUri: string) =>
    currentPlaceKeywords.add(districtPlaceKeywordUri)
  )

  return currentPlaceKeywords
}

const setEspooDistrictKeywordForLocationWithNonGeographicalPostalCode = (
  locationId: string,
  currentPlaceKeywords: Set<string>,
  keywordSets: KeywordSet[]
): Set<string> => {
  const districtPlaceKeywordId =
    getEspooDistrictIdForLocationWithNonGeographicalPostalCode(locationId)

  const districtPlaceKeywordUri = pipe(
    getEspooPlacesKeywordSet(keywordSets),
    O.chain((keywordSet) =>
      pipe(
        districtPlaceKeywordId,
        O.chain((districtId) => getKeywordUriBasedOnId(keywordSet, districtId))
      )
    )
  )

  if (O.isSome(districtPlaceKeywordUri)) {
    currentPlaceKeywords.add(districtPlaceKeywordUri.value)
  }

  return currentPlaceKeywords
}

export const setPlaceKeywordsBasedOnLocation = (
  location: Location | undefined,
  currentPlaceKeywords: string[] | undefined,
  keywordSets: KeywordSet[]
): string[] => {
  const currentPlaceKeywordsSet: Set<string> = pipe(
    O.fromNullable(currentPlaceKeywords),
    O.map((placeKeywords) => new Set(placeKeywords)),
    O.getOrElse(() => new Set())
  )

  if (!location) {
    return []
  }

  if (location.id === INTERNET_LOCATION_ID) {
    return Array.from(
      setOnlinePlaceKeyword(currentPlaceKeywordsSet, keywordSets)
    )
  }

  if (location.id === ESPOO_LOCATION_ID) {
    return Array.from(
      setAllEspooPlaceDistrictKeywords(currentPlaceKeywordsSet, keywordSets)
    )
  }

  if (!location.postalCode) {
    return Array.from(currentPlaceKeywordsSet)
  }

  if (isPostalCodeEspooNonGeographical(location.postalCode)) {
    return Array.from(
      setEspooDistrictKeywordForLocationWithNonGeographicalPostalCode(
        location.id,
        currentPlaceKeywordsSet,
        keywordSets
      )
    )
  }

  const espooDistrictIds = getEspooDistrictIdsBasedOnPostalCode(
    location.postalCode
  )
  return Array.from(
    pipe(
      espooDistrictIds,
      O.fold(
        () => setNonEspooPlaceKeyword(currentPlaceKeywordsSet, keywordSets),
        (districtIds) =>
          setEspooDistrictKeywordsBasedOnDistrictIds(
            districtIds,
            currentPlaceKeywordsSet,
            keywordSets
          )
      )
    )
  )
}
