import React from 'react'
import { useIntl } from 'react-intl'
import { ConnectedHelLanguageSelect } from '../HelFormFields'
import { API } from '../../api'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

export const PresentedLanguages: React.FC = () => {
  const intl = useIntl()
  const contentLanguages = useSelector(
    (state: RootState) => state.editor.contentLanguages
  )

  return (
    <div className="col-sm-12 highlighted-block">
      <div className="col-xl-4 col-sm-12">
        <label>
          {intl.formatMessage({ id: 'event-presented-in-languages' })}
        </label>
      </div>
      <div className="col-xl-8 col-sm-12">
        <ConnectedHelLanguageSelect
          options={API.eventInfoLanguages()}
          checked={contentLanguages}
        />
      </div>
    </div>
  )
}
