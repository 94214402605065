import { setFlashMsg } from '../store/app/index'
import { AxiosError } from 'axios'
import { constants } from '../constants'
import { Dispatch } from 'react'
import { setValidationErrors } from '../actions/editor'
import { setLoading } from '../store/editor'

// TODO: Move all typing to be part of a slice when that is possible
type ServerErrorDispatch =
  | SetLoadingAction
  | ReturnType<typeof setFlashMsg>
  | SetValidationErrorAction
  | ReturnType<typeof setServerErrorData>

// TODO: Do we need to define action
export type SetLoadingAction = {
  type: 'editor/setLoading'
  payload: boolean
}

export type SetValidationErrorAction = {
  type: typeof constants.SET_VALIDATION_ERRORS
  errors: FormValidationErrors
}

export type FormValidationErrors = {
  [fieldName: string]: string[]
}

type ServerErrorMetadata = {
  start_time?: string
  end_time?: string
  date_published?: string
}

const setServerErrorData = (
  errors: ServerErrorMetadata
): {
  type: string
  errors: ServerErrorMetadata
} => ({
  type: constants.SET_SERVER_ERRORS,
  errors,
})

const errorMapper: Record<string, string> = {
  PAST_EVENT_EDIT_ERROR: 'event-in-the-past',
}

type FormErrors = {
  type: 'FORM_ERRORS'
  errors: FormValidationErrors
  metadata: ServerErrorMetadata
}

function isNonEmptyObject<T extends object>(obj: unknown): obj is T {
  return !!obj && Object.keys(obj).length > 0
}

const isAxiosError = (
  error: AxiosError | Error
): error is AxiosError<FormErrors | string[]> =>
  // eslint-disable-next-line no-prototype-builtins
  error.hasOwnProperty('config') &&
  'response' in error &&
  error.response !== undefined &&
  'data' in error.response &&
  error.response.data !== undefined

const isFormErrors = (data: unknown): data is FormErrors =>
  isNonEmptyObject(data) && 'type' in data && data.type === 'FORM_ERRORS'

const handleAxiosError = (
  error: AxiosError<FormErrors | string[]>,
  dispatch: Dispatch<ServerErrorDispatch>
): void => {
  if (!error.response?.data) {
    return
  }

  const data = error.response?.data

  if (isFormErrors(data)) {
    dispatch(setValidationErrors(data.errors))
    dispatch(setServerErrorData(data.metadata))
  } else {
    dispatch(
      setFlashMsg({
        msg: errorMapper[data[0]] ?? 'server-error',
        style: 'error',
      })
    )
  }
}

export const preliminaryErrorHandler = (
  error: AxiosError | Error,
  dispatch: Dispatch<ServerErrorDispatch>
): void => {
  if (isAxiosError(error)) {
    handleAxiosError(error, dispatch)
  } else if (error.message === 'Recurring event has no subevents') {
    dispatch(setValidationErrors({}))
    dispatch(
      setFlashMsg({ msg: 'recurring-event-missing-subevents', style: 'error' })
    )
  } else dispatch(setFlashMsg({ msg: 'server-error', style: 'error' }))

  dispatch(setLoading(false))
}
