import React from 'react'
import { getEnvironment } from '../../utils/env'
import { FormattedMessage } from 'react-intl'

export const EnvironmentBanner: React.FC = () => {
  const env = getEnvironment()

  if (!env || env === 'prod') {
    return null
  }

  return (
    <div className="helsinki-bar__env-title">
      <FormattedMessage id={`environment-${env}-name`} />
    </div>
  )
}
