import { MultiLanguageField } from '../types/apiTypes'

export const MultiLanguageFieldToArray = (
  value?: MultiLanguageField
): string[] => {
  if (!value) {
    return []
  }

  return [value.fi, value.sv, value.en]
    .filter((val) => !!val)
    .map((val) => val as string)
}
