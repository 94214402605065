import React from 'react'
import { Link } from 'react-router-dom'
import cityOfEspooLogo from '../../assets/images/espoo-logo.png'

export const HeaderLogo: React.FC = () => (
  <div className="helsinki-bar__logo">
    <Link to="/">
      <img src={cityOfEspooLogo} alt="City Of Espoo" />
    </Link>
  </div>
)
