import './EventTime.scss'
import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import { SideField } from '../SideField'
import { SingleEventFields } from './SingleEventFields'
import { RecurringEventFields } from './RecurringEventFields'
import { HelLabeledRadiobuttonGroup } from '../../HelFormFields/HelLabeledRadiobuttonGroup/HelLabeledRadiobuttonGroup'
import { constants } from '../../../constants'
import { RootState } from '../../../store'
import { setData } from '../../../actions/editor'
import { useParams } from 'react-router'

type SingleOrRecurring =
  | typeof constants.SUPER_EVENT_TYPE_SINGLE
  | typeof constants.SUPER_EVENT_TYPE_RECURRING

export const EventTime: React.FC<{ loading: boolean }> = ({ loading }) => {
  const { action: formType } = useParams<{ action: string }>()
  const intl = useIntl()
  const dispatch = useDispatch()

  const values = useSelector((state: RootState) => state.editor.values)
  const eventType = useSelector((state: RootState) =>
    state.editor.values.super_event_type ===
    constants.SUPER_EVENT_TYPE_RECURRING
      ? constants.SUPER_EVENT_TYPE_RECURRING
      : constants.SUPER_EVENT_TYPE_SINGLE
  )

  const setEventType = (newType: SingleOrRecurring) => {
    // The setter is a bit more complicated, because umbrella, and single (undefined) renders same values to UI.
    if (values.super_event_type === newType) {
      // Value does not change in redux state: no change
      return
    }

    // value changes.

    if (newType === constants.SUPER_EVENT_TYPE_RECURRING) {
      // if new value: recurring => change to recurring
      dispatch(
        setData({
          ...values,

          // clear single event fields
          date_published: undefined,
          start_time: undefined,
          end_time: undefined,

          // set to recurring
          super_event_type: constants.SUPER_EVENT_TYPE_RECURRING,
        })
      )
      return
    }

    // In this scope: newType == constants.SUPER_EVENT_TYPE_SINGLE
    // if the new value: single => keep the old value if it is either umbrella or undefined
    if (
      newType === constants.SUPER_EVENT_TYPE_SINGLE &&
      values.super_event_type === constants.SUPER_EVENT_TYPE_RECURRING
    ) {
      // if the new value: single => old and old value is recurring
      // then nullify the the value.
      // (Nullified, because the codebase prefers null over undefined.)
      dispatch(
        setData({
          ...values,

          // clear sub events
          sub_events: undefined,

          super_event_type: null,
        })
      )
      return
    }
  }

  return (
    <div className="event-time">
      {loading ? (
        <div className="event-all-fields">
          <CircularProgress color="inherit" size={50} />
        </div>
      ) : (
        <div className="event-all-fields">
          <div className="event-type">
            <HelLabeledRadiobuttonGroup<SingleOrRecurring>
              // Radiobutton this disabled when updating the event, because it makes the implementation easier.
              // Otherwise you would need to handle switching event type from one to another.
              disabled={formType === 'update'}
              required={false}
              groupLabel={intl.formatMessage({ id: 'event-type' })}
              value={eventType}
              setValue={setEventType}
              row={true}
              options={[
                {
                  label: intl.formatMessage({ id: 'event-type-single' }),
                  value: constants.SUPER_EVENT_TYPE_SINGLE,
                },
                {
                  label: intl.formatMessage({ id: 'event-type-recurring' }),
                  value: constants.SUPER_EVENT_TYPE_RECURRING,
                },
              ]}
            />
          </div>

          <div className="event-fields">
            {eventType === constants.SUPER_EVENT_TYPE_SINGLE ? (
              <SingleEventFields />
            ) : (
              <RecurringEventFields />
            )}
          </div>
        </div>
      )}
      <SideField className="event-tip">
        <div className="tip">
          <p>{intl.formatMessage({ id: 'editor-tip-time-type' })}</p>
          <p>{intl.formatMessage({ id: 'editor-tip-time-single' })}</p>
          <p>{intl.formatMessage({ id: 'editor-tip-time-recurring' })}</p>
          <p>{intl.formatMessage({ id: 'editor-tip-time-recurring2' })}</p>
        </div>
      </SideField>
    </div>
  )
}
