import './HelLabeledRadiobuttonGroup.scss'
import React from 'react'
import { useSelector } from 'react-redux'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { RootState } from '../../../store'
import { ValidationPopover } from '../../ValidationPopover/ValidationPopover'
import { RadiobuttonOption } from './RadiobuttonOption'

export const HelLabeledRadiobuttonGroup = <T = string,>({
  disabled,
  required,
  groupLabel,
  options,
  value,
  setValue,
  row,
}: {
  disabled?: boolean
  required?: boolean
  groupLabel: string
  options: RadiobuttonOption<T>[]
  value: T
  setValue: (newValue: T) => void
  row?: boolean
}): JSX.Element => {
  const labelRef = React.useRef<HTMLLegendElement>(null)
  // TODO: Should validationerrors -> ValidationPopover be in this component?
  const validationErrors = useSelector(
    (state: RootState) => state.editor.validationErrors
  )

  return (
    <>
      <fieldset className="col-sm-6 hel-radiobutton-group">
        <legend ref={labelRef}>
          {groupLabel}
          {required && <>&thinsp;{'*'}</>}
        </legend>
        {/* TODO: Replace bootstrap with  MUI grid */}
        <div className="row">
          <div className="col-md-12">
            <RadioGroup
              row={row}
              aria-labelledby="radiobutton-group-label"
              value={value}
              name="radiobutton-group"
              onChange={(event) => setValue(event.target.value as T)}
              style={{
                display: 'flex',
                flexDirection: row ? 'column' : 'row',
              }}
            >
              {options.map(({ value: rbvalue, label }, i) => (
                <span key={'hel-radiobutton-' + i} className="">
                  <FormControlLabel
                    disabled={disabled}
                    value={rbvalue}
                    control={<Radio color="primary" />}
                    label={label}
                  />
                </span>
              ))}
            </RadioGroup>
          </div>
        </div>
      </fieldset>
      <ValidationPopover
        anchor={labelRef.current}
        validationErrors={validationErrors}
      />
    </>
  )
}
