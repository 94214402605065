import { find } from 'lodash'
import { getStringWithLocale } from './locale'

/**
 *
 * @param {*} keywordSets
 * @param {*} id
 * @param {string} locale
 * @returns {{ value: string, label: string }[]}
 */
export function mapKeywordSetToForm(keywordSets, id, locale = 'fi') {
  const keywordSet = find(keywordSets, { id })
  if (keywordSet && keywordSet.keywords) {
    return keywordSet.keywords.map((item) => {
      const label = getStringWithLocale(item, 'name', locale)
      // we don't want yso parentheses visible in keyword sets, so only pick the part before them
      const keyword = label.split([' ('])[0]
      return {
        value: item['@id'],
        label: keyword,
      }
    })
  }

  return []
}

/**
 *
 * @param {any[]} set
 * @param {import('../store/userLocale/types').UserLocale} locale
 * @returns {({ value: string; label: string })[]}
 */
export function mapLanguagesSetToForm(set, locale = 'fi') {
  if (set && set.length) {
    return set.map((item) => {
      const label = getStringWithLocale(item, 'name', locale, item.id)
      return {
        value: item['@id'],
        label,
      }
    })
  }

  return []
}

/**
 *
 * @param {any[]} hobbyCategories
 * @param {import('../store/userLocale/types').UserLocale} locale
 * @returns {({ value: string; label: string })[]}
 */
export function mapHobbyCategoriesToForm(hobbyCategories, locale) {
  if (hobbyCategories && hobbyCategories.length > 0) {
    return hobbyCategories.map((item) => ({
      value: item['@id'],
      label: item[`name_${locale}`],
    }))
  }
  return []
}
