import { createAction } from 'redux-actions'

import { constants } from '../constants'
import { setData } from './editor'
import { setFlashMsg } from '../store/app/index'
import { client } from '../api/client'

/**
 * Fetch images from the API.
 *
 * @param user
 * @param pageSize = page size, how many images do you want to display on a single page.
 * @param pageNumber
 * @returns Object
 */
async function makeImageRequest(user = {}, pageSize, pageNumber = undefined) {
  const params = {
    page_size: pageSize,
    publisher:
      user.organization && user.userType === constants.USER_TYPE.ADMIN
        ? user.organization
        : null,
    created_by: user.userType === constants.USER_TYPE.REGULAR ? 'me' : null,
  }

  const result = await client.get(
    'image',
    pageNumber ? { ...params, page: pageNumber } : params
  )

  // Append the page number to the JSON array so that it can be easily used in the pagination
  if (pageNumber !== null) {
    result.data.meta.currentPage = pageNumber
  }

  return result
}

/**
 * An action for fetching both images and meta data, that holds the next / previous page information and image count.
 *
 * @param response
 * @returns Object
 */
export function receiveUserImagesAndMeta(response) {
  return {
    type: constants.IMAGES.RECEIVE_IMAGES_AND_META,
    items: response.data.data,
    meta: response.data.meta,
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function receiveUserImagesFail(response) {
  return {
    type: constants.IMAGES.RECEIVE_IMAGES_ERROR,
    items: [],
  }
}

export function fetchUserImages(pageSize = 100, pageNumber = null) {
  const startFetching = createAction(constants.IMAGES.REQUEST_IMAGES_AND_META)

  return async (dispatch, getState) => {
    const { user } = getState()
    let response

    try {
      dispatch(startFetching)

      response = await makeImageRequest(user, pageSize, pageNumber)

      dispatch(receiveUserImagesAndMeta(response))
    } catch (error) {
      dispatch(
        setFlashMsg({
          msg: 'image-fetch-error',
          style: 'error',
          data: response,
        })
      )
      dispatch(receiveUserImagesFail(response))
      throw new Error(error)
    }
  }
}

export function selectImage(image) {
  return {
    type: constants.SELECT_IMAGE_BY_ID,
    img: image,
  }
}

export function imageUploadFailed(json) {
  return {
    type: constants.IMAGES.IMAGE_UPLOAD_ERROR,
    data: json,
  }
}

export function imageUploadComplete(json) {
  return {
    type: constants.IMAGES.IMAGE_UPLOAD_SUCCESS,
    data: json,
  }
}

export function postImage(formData, user, imageId = null) {
  return async (dispatch) => {
    try {
      let request

      // Use PUT when updating the existing image metadata, use POST when adding a new image.
      if (imageId) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        request = await client.put(`image/${imageId}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      } else {
        request = await client.post('image/', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      }

      // Append uploaded image data to the form
      dispatch(setData({ image: request.data }))

      dispatch(imageUploadComplete(request))

      dispatch(
        setFlashMsg({
          msg: imageId ? 'image-update-success' : 'image-creation-success',
          style: 'success',
          data: request,
        })
      )
    } catch (error) {
      dispatch(setFlashMsg({ msg: 'image-creation-error', style: 'error' }))

      dispatch(imageUploadFailed(error))

      throw new Error(error)
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function deleteImage(selectedImage, user) {
  return async (dispatch) => {
    try {
      await client.delete(`image/${selectedImage.id}`)

      // Update form image value
      dispatch(setData({ image: null }))

      dispatch(
        setFlashMsg({
          msg: 'image-deletion-success',
          style: 'success',
          data: '',
        })
      )

      // Update image listing
      dispatch(fetchUserImages())
    } catch (error) {
      dispatch(
        setFlashMsg({
          msg: 'image-deletion-error',
          style: 'error',
          data: error,
        })
      )

      throw new Error(error)
    }
  }
}
