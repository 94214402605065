import './OffersValue.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { CheckedValue } from './CheckedValue'
import { MultiLanguageValue } from './MultiLanguageValue'
import { NoValue } from './NoValue'

export const OffersValue = (props) => {
  const {
    values: { offers },
    locale, // TODO: With hooks, you can use const locale = useSelector(state => state.userLocale.locale)
  } = props

  if (
    !offers ||
    !offers.length ||
    (offers[0] && typeof offers[0] !== 'object')
  ) {
    return <NoValue labelKey={props.labelKey} />
  }

  // Sort the offers by the price field in descending order using natural sort
  const collator = new Intl.Collator(locale, {
    numeric: true,
    sensitivity: 'base',
  })
  const sortedOffers = offers.sort((a, b) =>
    collator.compare(b.price[locale], a.price[locale])
  )

  return (
    <div>
      <CheckedValue checked={offers[0].is_free} labelKey="is-free" />
      {sortedOffers.map((offer, key) => (
        <div key={`offer-value-${key}`} className="offer-values">
          <MultiLanguageValue
            labelKey="event-purchase-link"
            value={offer.info_url}
          />
          <MultiLanguageValue
            labelKey="event-price"
            hidden={offer.is_free}
            value={offer.price}
          />
          <MultiLanguageValue
            labelKey="event-price-info"
            hidden={offer.is_free}
            value={offer.description}
          />
        </div>
      ))}
    </div>
  )
}

OffersValue.propTypes = {
  values: PropTypes.object,
  labelKey: PropTypes.string,
  locale: PropTypes.string,
}
