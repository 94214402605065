import './ImageThumbnail.scss'

import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Build } from '@material-ui/icons'

import { selectImage as selectImageAction } from '../../actions/userImages'
import { ConnectedImageEdit } from '../ImageEdit/ImageEdit'

/* eslint-disable @typescript-eslint/unbound-method */
class ImageThumbnail extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      edit: false,
    }

    this.selectThis = this.selectThis.bind(this)
  }

  selectThis() {
    this.props.selectImage(this.props.data)
  }

  render() {
    let classname = this.props.selected ? 'image-thumb selected' : 'image-thumb'

    if (this.props.empty) {
      classname += ' no-image'
      return (
        <div
          className="col-md-3 col-xs-12"
          onClick={this.selectThis}
          id={this.props.data.id}
        >
          <div className={classname}>
            <div
              className="thumbnail"
              style={{ backgroundColor: 'lightgray' }}
            />
            <div className="no-image-text">
              <FormattedMessage id="no-image" />
            </div>
          </div>
        </div>
      )
    }

    const bgStyle = { backgroundImage: `url(${this.props.url})` }

    let editModal = null

    if (this.state.edit) {
      editModal = (
        <ConnectedImageEdit
          id={this.props.data.id}
          defaultName={this.props.data.name}
          altText={this.props.data.alt_text}
          defaultPhotographerName={this.props.data.photographer_name}
          thumbnailUrl={this.props.url}
          license={this.props.data.license}
          close={() => this.setState({ edit: false })}
          onSave={() => this.setState({ edit: false })}
          updateExisting
        />
      )
    }

    return (
      <div
        className="col-md-3 col-xs-12"
        onClick={this.selectThis}
        id={this.props.data.id}
      >
        <div className={classname}>
          <div className="thumbnail" style={bgStyle} />
          <div
            className="name edit-image"
            onClick={() => this.setState({ edit: true })}
          >
            <span className={'image-title'}>
              {this.props.data.name || <FormattedMessage id="edit-image" />}
            </span>
            <Build />
          </div>
        </div>
        {editModal}
      </div>
    )
  }
}
/* eslint-enable @typescript-eslint/unbound-method */
ImageThumbnail.propTypes = {
  data: PropTypes.object,
  selected: PropTypes.bool,
  empty: PropTypes.bool,
  url: PropTypes.string,
  selectImage: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
  selectImage: (data) => dispatch(selectImageAction(data)),
})

const mapStateToProps = () => ({})
// TODO: if leave null, react-intl not refresh. Replace this with better React context
export const ConnectedImageThumbnail = connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageThumbnail)
