import React, { createContext, useContext, useMemo } from 'react'
import axios, { AxiosInstance } from 'axios'
import { constants } from '../constants'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import { appSettings } from '../types/global'

const ClientContext = createContext<AxiosInstance | undefined>(undefined)

const useToken = (): string => {
  const token = useSelector((state: RootState) => state?.user?.token)
  if (!token) {
    console.warn('User is not logged in or authentication token is missing.')
    return ''
  }

  return token
}

export const ClientProvider: React.FC = ({ children }) => {
  const authToken = useToken()
  const client = useMemo(() => {
    const instance = axios.create({
      baseURL: window.location.origin + appSettings.api_base,
      headers: {
        // If you need do requests, that require modified headers,
        // like sending images, then override the headers in the component and not here.
        // This should have only generic defaults without explicit business logic.
        ...constants.API_HEADERS,
        ...(authToken ? { Authorization: `JWT ${authToken}` } : {}),
      },
    })

    instance.interceptors.request.use((config) => {
      // URLs in Django must have a trailing slash
      if (!config.url?.endsWith('/')) {
        config.url += '/'
      }

      return config
    })

    return instance
  }, [authToken])

  return (
    <ClientContext.Provider value={client}>{children}</ClientContext.Provider>
  )
}

/** Context provided hook for axios client. */
export const useClient = (): AxiosInstance => {
  const client = useContext(ClientContext)
  if (!client) {
    throw new Error('useClient must be used within a ClientProvider')
  }
  return client
}
