import React from 'react'

export const HelpPage = () => {
  const { host } = window.location
  const apiUrl = `${host}/api/events/`
  const fullApiUrl = `https://${apiUrl}`
  return (
    <div className="container help-page">
      <div id="short">
        <h1>Espoo Events lyhyesti</h1>
        <ul>
          <li>
            Tapahtumatiedot voidaan näyttää eri sovelluksissa – myös muissa kuin
            kaupungin omissa.
          </li>
          <li>
            Tapahtumia saa syöttää kaupungin luvalla. Niiden ei tarvitse olla
            kaupungin järjestämiä.
          </li>
          <li>
            Tapahtumalle voi antaa useita päivämääriä. Tapahtumasarjalle luodaan
            automaattisesti ylätapahtuma.
          </li>
          <li>Tapahtumia voi myös kopioida, perua, lykätä ja poistaa.</li>
          <li>Huolella syötetyt tiedot helpottavat tapahtuman löytämistä:</li>
          <ul>
            <li>Ymmärrettävä otsikko, lyhyt kuvaus ja kuvaus.</li>
            <li>
              Lyhyt kuvaus näytetään vain listoissa, kuvaus taas tapahtuman
              omalla sivulla.
            </li>
            <li>Jos kohderyhmä on rajattu, kerro siitä kuvauskentässä.</li>
            <li>
              Verkkoon sopivaksi muokattu 3:2-vaakakuva, esim. 1200px X 800px.
              Kuvatiedoston koko alle 2 megatavua.
            </li>
            <li>Ylätasoinen asiasanoitus ja luokittelu.</li>
          </ul>
        </ul>

        <p>
          Kaupungin tapahtumahaku:&nbsp;
          <a
            href="https://www.espoo.fi/haku?type=event"
            rel="noopener noreferrer"
            target="_blank"
          >
            www.espoo.fi/haku?type=event
          </a>
        </p>

        <p>
          Espoo Events -rajapinta:{' '}
          <a href={fullApiUrl} rel="noopener noreferrer" target="_blank">
            {apiUrl}
          </a>
        </p>

        <p>
          Rajapinnan omistaja Espoon konsernihallinnon viestintä&nbsp;
          <a href="mailto:verkkoviestinta@espoo.fi">verkkoviestinta@espoo.fi</a>
        </p>
      </div>

      <hr />

      <div id="long">
        <h2>Espoo Events -tapahtumarajapinnan syöttökäyttöliittymä</h2>

        <h3>Yleistä</h3>

        <ul>
          <li>
            Espoo Events on Espoon kaupungin tapahtumarajapinta. Rajapintaan
            syötetyt tapahtumat siirtyvät automaattisesti kaupungin
            tapahtumakalenteriin ja niitä on mahdollista näyttää myös muissa
            kuin kaupungin ylläpitämissä kalenterisovelluksissa. Tapahtuman
            kuvaus kannattaa siksi laatia helposti ymmärrettäväksi.
          </li>

          <li>
            Rajapinnan syöttökäyttöliittymä löytyy osoitteesta {host}.
            Käyttöoikeuden saa kirjautumalla ensin palveluun ja lähettämällä
            tämän jälkeen oikeuksien vahvistuspyynnön. Muokkausoikeus on oman
            organisaation tapahtumille.
          </li>

          <li>
            Rajapintaan saa syöttää tapahtumia Espoon kaupungin luvalla.
            Tapahtumien ei tarvitse olla kaikille avoimia, mutta rajoitukset on
            kerrottava selkeästi tapahtuman kuvauksessa.
          </li>

          <li>
            Käyttöliittymässä voi hakea kaikkia kaupungin tapahtumia, lisätä
            uusia tapahtumia sekä selata ja muokata oman organisaation
            tapahtumia.
          </li>

          <li>
            Uuden tapahtuman tietojen pohjana voi käyttää vanhaa avaamalla
            tapahtuman ja valitsemalla &quot;Kopioi uuden tapahtuman
            pohjaksi&quot;.
          </li>

          <li>
            Jos tapahtuman ajankohtaa siirretään eteenpäin eikä uusi ajankohta
            ole tiedossa, valitse &quot;Lykkää tapahtumaa&quot;. Silloin se
            näkyy tapahtumalistauksessa merkinnällä &quot;Lykätty&quot;.
          </li>
        </ul>

        <h3>Tapahtuman syöttö</h3>

        <ul>
          <li>
            Lisää uusi tapahtuma: Tapahtuman tiedot on syötettävä ainakin
            suomeksi. Ruotsin- ja englanninkieliset tiedot on syytä täyttää
            suurissa ja kielikohderyhmille sopivissa tapahtumissa. Täytä tiedot
            riittävän tarkasti. Lomakkeen ohjetekstit auttavat täyttämisessä.
          </li>

          <li>
            Tapahtuman paikka valitaan Espoon kaupungin toimipisterekisterin
            paikoista. Osoite- ja muut tiedot haetaan siis automaattisesti, kun
            oikea paikka löytyy. Tieto on pakollinen.
          </li>

          <li>
            Tapahtuman luokittelu asiasanoilla on tärkeää, jotta eri sovellusten
            käyttäjät löytävät tapahtuman suuresta tapahtumamassasta.
            Pääkategoriat ja kohderyhmät ovat ensisijaisesti espoo.fi-sivustoa
            varten, mutta ne näkyvät myös muille rajapinnan hyödyntäjille.
          </li>

          <li>
            Useana päivänä toistuvan tapahtuman lisäämiseksi on Lisää uusi
            ajankohta -painike ja säännöllisesti toistuvalle tapahtumalle
            Toistuva tapahtuma -painike. Jos tapahtumalla on useita
            päivämääräkertoja, voi tallentamisessa kestää hetken. Jos et saa
            Julkaise tapahtuma -napin painamisen jälkeen virheilmoitusta, odota
            hetki, jolloin lomakkeen pitäisi siirtyä seuraavaan näkymään.
          </li>
        </ul>
      </div>

      <hr />

      <div id="images">
        <h2>Kuvien lisääminen rajapintaan</h2>

        <ul>
          <li>
            Pyri aina löytämään tapahtumalle kuva. Jos tapahtumalle ei anneta
            kuvaa, niin kuvan tilalla näkyy Espoon kaupungin tunnus.
          </li>

          <li>
            Muokkaa kuva etukäteen verkkoon sopivaksi. Olennaista on, että
            tiedostokoko pysyy kohtuullisena myös mobiilikäytössä.
          </li>

          <li>
            Käytä vaakakuvia, jotka ovat kuvasuhteessa 3:2. Suositeltu mitta
            kuville on 1200px X 800px.
          </li>

          <li>Yli 2 megatavun kokoisia kuvia ei voi syöttää rajapintaan.</li>
        </ul>

        <h3>Kuvan vaihtoehtoinen teksti eli alt-teksti</h3>

        <ul>
          <li>
            Alt-teksti on kuvan sisällön sanallinen kuvailu sellaisille
            henkilöille, jotka eivät syystä tai toisesta voi nähdä itse kuvaa.
            EU:n saavutettavuusdirektiivin mukaan kuvalla on aina oltava
            alt-teksti, joten siksi kenttä on pakollinen.
          </li>

          <li>
            Alt-tekstissä voi lukea esimerkiksi &quot;Lapsia leikkimässä
            päiväkodin pihalla&quot;. Alt-teksti ei ole kuvateksti, joten siinä
            ei kerrota asioita, jotka eivät näy kuvassa.
          </li>
        </ul>

        <h3>Kuvateksti ja kuvaaja</h3>

        <ul>
          <li>
            Kuvateksti ja kuvaaja eivät ole pakollisia tietoja, mutta ne on hyvä
            täyttää. Kuvan lisenssissä saatetaan vaatia, että kuvaajan nimi
            mainitaan.
          </li>

          <li>
            On hyvä käytäntö syöttää aina kuvaajan nimi ja jos se ei ole
            tiedossa, niin ainakin kuvan lähde.
          </li>
        </ul>

        <h3>Lisenssi</h3>

        <ul>
          <li>
            Espoon kaupungilla on oltava oikeudet kuvan käyttöön. Kuvalle voi
            merkitä joko&nbsp;
            <a
              href="https://creativecommons.org/licenses/by/4.0/deed.fi"
              rel="noopener noreferrer"
              target="_blank"
            >
              CC 4.0 BY-lisenssin
            </a>
            &nbsp;mukaisen laajan käyttöoikeuden tai rajata kuvan käytön vain
            kyseisen tapahtuman yhteyteen.
          </li>

          <li>
            Kuvan käyttöoikeuden varmistaminen ja oikean lisenssin valinta on
            aina kuvan syöttäjän vastuulla.
          </li>

          <li>
            Jos kuvaajan tai kuvan oikeudenomistajan kanssa ei ole sovittu
            muuta, valitse lisenssiksi &quot;Käyttö rajattu tapahtuman
            yhteyteen&quot;, jolloin kuvaa voi käyttää ainoastaan kyseisen
            tapahtuman viestintään ja markkinointiin.
          </li>

          <li>Kerro kuvaajan nimi ja/tai kuvan lähde Kuvaaja-kentässä.</li>
        </ul>
      </div>

      <hr />

      <div id="other">
        <h2>Muuta huomioitavaa</h2>

        <p>
          Virhetilanteissa voit lähettää raportin liittymän vasemmasta laidasta
          löytyvän ikonin kautta. Virheilmoitus menee järjestelmän tekniselle
          kehittäjälle Espoossa.
        </p>
      </div>
    </div>
  )
}
