import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Button, CircularProgress } from '@material-ui/core'
import { push } from 'connected-react-router'
import { get } from 'lodash'

import { ConnectedEventDetails } from '../../components/EventDetails/EventDetails'
import { replaceData as replaceDataAction } from '../../actions/editor'
import { getStringWithLocale } from '../../utils/locale'
import { mapAPIDataToUIFormat } from '../../utils/formDataMapping'
import { client } from '../../api/client'
import { constants } from '../../constants'
import { EventQueryParams, fetchEvent } from '../../utils/events'
import { getBadge, scrollToTop } from '../../utils/helpers'

import './Event.scss'
import { ConnectedEventActionButton } from '../../components/EventActionButton/EventActionButton'
import {
  getOrganizationAncestors,
  hasOrganizationWithRegularUsers,
} from '../../utils/user'
import isEmail from 'validator/lib/isEmail'

const {
  PUBLICATION_STATUS,
  EVENT_STATUS,
  SUPER_EVENT_TYPE_UMBRELLA,
  SUPER_EVENT_TYPE_RECURRING,
} = constants

class EventPage extends React.Component {
  state = {
    event: {},
    superEvent: {},
    subEvents: [],
    // loading is true initially because we always fetch event data when the component is mounted
    loading: true, // TODO: Should you use redux editor/setLoading instead?
    publisher: null,
  }

  componentDidMount() {
    this.props.user != null && this.fetchEventData()
  }

  componentDidUpdate(prevProps, prevState) {
    const { event } = this.state
    const publisherId = get(event, 'publisher')
    const oldPublisherId = get(prevState, ['event', 'publisher'])
    const eventId = get(this.props.match, ['params', 'eventId'])
    const oldEventId = get(prevProps, ['match', 'params', 'eventId'])

    if (
      eventId !== oldEventId ||
      (prevProps.user == null && this.props.user != null)
    ) {
      this.fetchEventData()
    }

    if (publisherId && publisherId !== oldPublisherId) {
      client
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        .get(`organization/${publisherId}`)
        .then((response) => this.setState({ publisher: response.data }))
      getOrganizationAncestors(publisherId).then((response) =>
        this.setState((state) => ({
          ...state,
          event: {
            ...state.event,
            publisherAncestors: response.data.data,
          },
        }))
      )
    }
  }

  /**
   * Fetches the event, sub event and super event data
   */
  fetchEventData = async () => {
    const eventId = get(this.props, ['match', 'params', 'eventId'])

    if (!eventId) {
      return
    }

    this.setState({ loading: true })

    const queryParams = new EventQueryParams()
    queryParams.include =
      'keywords,location,audience,in_language,external_links,sub_events,hobby_categories,super_event'

    try {
      const eventData = await fetchEvent(eventId, queryParams)
      const [event, subEvents, superEvent] = eventData

      this.setState({ event, subEvents, superEvent })
    } finally {
      this.setState({ loading: false })
    }
  }

  /**
   * Opens the editor with the event data in given mode
   * @param mode  Whether event is copied as a template or being updated. Can be either 'copy' or 'update'
   */
  openEventInEditor = (mode = 'update') => {
    const { replaceData, routerPush } = this.props
    const { event } = this.state

    const route = mode === 'copy' ? 'create/new' : `update/${event.id}`

    if (event) {
      replaceData(event)
      routerPush(`/event/${route}`)
      scrollToTop()
    }
  }

  /**
   * Returns the publisher & creator info text
   * @returns {null|*}
   */
  getPublishedText = () => {
    const { event, publisher } = this.state

    if (!publisher) {
      return null
    }

    const createdBy = get(event, 'created_by')
    const publishedAt = moment(event.last_modified_time).format(
      'D.M.YYYY HH:mm'
    )
    let creator = null
    let maybeEmail = null
    let email = null

    if (createdBy) {
      ;[creator, maybeEmail] = createdBy.split(' - ')
      email = isEmail(maybeEmail) ? maybeEmail : ''
    }

    return (
      <span>
        <FormattedMessage
          id="event-publisher-info"
          values={{ publisher: publisher.name }}
        />
        {creator && email && (
          <React.Fragment>
            <span> | {creator} | </span>
            <a href={`mailto:${email}`}>{email}</a>
          </React.Fragment>
        )}
        <span> | {publishedAt}</span>
      </span>
    )
  }

  /**
   * Returns a div containing all the action buttons for the view
   * @returns {*}
   */
  getEventActions = () => {
    const { user } = this.props
    const { event, loading } = this.state

    const isAdmin = get(user, 'superuser')
    const isDraft = event.publication_status === PUBLICATION_STATUS.DRAFT
    const editEventButton = this.getActionButton(
      'edit',
      this.openEventInEditor,
      false
    )
    const publishEventButton = this.getActionButton('publish')
    const cancelEventButton = this.getActionButton('cancel')
    const deleteEventButton = this.getActionButton('delete')

    return (
      <div className="event-actions">
        <div className="cancel-delete-btn">
          {cancelEventButton}
          {deleteEventButton}
        </div>
        <div className="edit-copy-btn">
          {isAdmin && isDraft && publishEventButton}
          {editEventButton}
          <Button
            variant="contained"
            disabled={loading}
            onClick={() => this.openEventInEditor('copy')}
          >
            <FormattedMessage id="copy-event-to-draft" />
          </Button>
        </div>
      </div>
    )
  }

  /**
   * Returns a button for the given action
   * @param action        Action to run
   * @param customAction  Custom action that should be run instead of the default one
   * @param askConfirmation       Whether confirmation modal should be shown before running action
   * @returns {*}
   */
  getActionButton = (action, customAction, askConfirmation = true) => {
    const { event, subEvents, loading } = this.state

    return (
      <ConnectedEventActionButton
        action={action}
        askConfirmation={askConfirmation}
        customAction={customAction}
        event={event}
        loading={loading}
        runAfterAction={this.handleConfirmedAction}
        subEvents={subEvents}
      />
    )
  }

  handleConfirmedAction = (action, event) => {
    const { routerPush, user } = this.props
    const isDraft = event.publication_status === PUBLICATION_STATUS.DRAFT

    // navigate to moderation if an admin deleted a draft event, otherwise navigate to event listing
    if (action === 'delete') {
      if (isDraft && hasOrganizationWithRegularUsers(user)) {
        routerPush('/moderation')
      } else {
        routerPush('/')
      }
    }
    // re-fetch event data after cancel, postpone or publish action
    if (action === 'cancel' || action === 'publish') {
      this.fetchEventData()
    }
  }

  render() {
    const { event, superEvent, loading, publisher } = this.state
    const { editor } = this.props

    const formattedEvent = mapAPIDataToUIFormat(
      this.state.event,
      editor.keywordSets
    )
    const isUmbrellaEvent = event.super_event_type === SUPER_EVENT_TYPE_UMBRELLA
    const isRecurringEvent =
      event.super_event_type === SUPER_EVENT_TYPE_RECURRING
    const isDraft = event.publication_status === PUBLICATION_STATUS.DRAFT
    const isCancelled = event.event_status === EVENT_STATUS.CANCELLED
    const isPostponed = event.event_status === EVENT_STATUS.POSTPONED
    const publishedText = this.getPublishedText()

    return (
      <div className="event-page container">
        <header>
          <h1>
            {loading ? (
              <CircularProgress size={60} />
            ) : (
              getStringWithLocale(event, 'name')
            )}
          </h1>
          {!loading && (
            <h4>
              {isPostponed && getBadge('postponed', 'medium')}
              {isCancelled && getBadge('cancelled', 'medium')}
              {isDraft && getBadge('draft', 'medium')}
              {isUmbrellaEvent && getBadge('umbrella', 'medium')}
              {isRecurringEvent && getBadge('series', 'medium')}
            </h4>
          )}
        </header>
        {this.getEventActions()}
        <div className="published-information">{publishedText}</div>
        <ConnectedEventDetails
          values={formattedEvent}
          superEvent={superEvent}
          rawData={event}
          publisher={publisher}
          editor={editor}
        />
        <footer>{this.getEventActions()}</footer>
      </div>
    )
  }
}

EventPage.propTypes = {
  intl: PropTypes.object.isRequired,
  editor: PropTypes.object,
  user: PropTypes.object,
  match: PropTypes.object,
  events: PropTypes.object,
  superEvent: PropTypes.object,
  subEvents: PropTypes.object,
  loading: PropTypes.bool,
  replaceData: PropTypes.func,
  routerPush: PropTypes.func,
}

const mapStateToProps = (state) => ({
  user: state.user,
  editor: state.editor,
})

const mapDispatchToProps = (dispatch) => ({
  replaceData: (event) => dispatch(replaceDataAction(event)),
  routerPush: (url) => dispatch(push(url)),
})

export const ConnectedEventPage = injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(EventPage)
)
