import React from 'react'
import { HelDateTimeField } from '../../HelFormFields'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { setData } from '../../../actions/editor'

export const SingleEventFields = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const values = useSelector((state: RootState) => state.editor.values)
  const validationErrors = useSelector(
    (state: RootState) => state.editor.validationErrors
  )

  return (
    <div className="row">
      <div className="col-xs-6 col-sm-6" data-testid="start_time">
        <HelDateTimeField
          data-testid="event-starting-datetime"
          required={true}
          validationErrors={validationErrors.start_time}
          defaultValue={values.start_time}
          name="start_time"
          label="event-starting-datetime"
          placeholder={intl.formatMessage({
            id: 'date-time-field-placeholder',
          })}
          maxDate={values.end_time ? moment(values.end_time) : undefined}
          onChange={(value) => dispatch(setData({ start_time: value }))}
        />
      </div>
      <div className="col-xs-6 col-sm-6" data-testid="end_time">
        <HelDateTimeField
          disablePast
          validationErrors={validationErrors.end_time}
          defaultValue={values.end_time}
          name="end_time"
          label="event-ending-datetime"
          placeholder={intl.formatMessage({
            id: 'date-time-field-placeholder',
          })}
          minDate={values.start_time ? moment(values.start_time) : undefined}
          onChange={(value) => dispatch(setData({ end_time: value }))}
        />
      </div>
      <div className="col-xs-6 col-sm-6" data-testid="date_published">
        <HelDateTimeField
          required={false}
          validationErrors={validationErrors.date_published}
          defaultValue={values.date_published}
          name="date_published"
          label="event-published-datetime"
          placeholder={intl.formatMessage({
            id: 'date-time-field-placeholder',
          })}
          maxDate={values.end_time ? moment(values.end_time) : undefined}
          onChange={(value) => dispatch(setData({ date_published: value }))}
        />
      </div>
    </div>
  )
}
