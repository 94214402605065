export class API {
  static eventInfoLanguages() {
    return [
      {
        value: 'fi',
        label: 'in-fi',
      },
      {
        value: 'sv',
        label: 'in-sv',
      },
      {
        value: 'en',
        label: 'in-en',
      },
    ]
  }
}
