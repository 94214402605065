import React, { useEffect, useState } from 'react'
import './AutoComplete.scss'
import { useIntl } from 'react-intl'
import LinearProgress from '@material-ui/core/LinearProgress'

export const AutoComplete = ({
  options,
  label,
  pickHandler,
  setACValue,
  isLoading,
}: {
  options?: { name: string; value: string; extra?: string }[]
  label: string
  pickHandler: (value: string, extra?: string) => void
  setACValue: (val: string) => void
  isLoading: boolean
}) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [showTable, setShowTable] = useState<boolean>(false)
  const ref = React.useRef<HTMLDivElement | null>(null)
  const intl = useIntl()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOutsideClick = (event: any) => {
    if (ref && ref.current && !ref.current.contains(event.target)) {
      setShowTable(false)
    }
  }

  useEffect(() => {
    if (showTable) {
      document.addEventListener('click', handleOutsideClick)
      return () => document.removeEventListener('click', handleOutsideClick)
    }
  }, [showTable])

  return (
    <div aria-label={label} className="autocomplete" ref={ref}>
      <input
        style={{ marginLeft: '12px' }}
        width="85%"
        value={inputValue}
        onFocus={() => setShowTable(true)}
        onChange={(e) => {
          setInputValue(e.target.value)
          setACValue(e.target.value)
        }}
      />
      {showTable &&
        (isLoading ? (
          <div className="ac-loading">
            <LinearProgress />
          </div>
        ) : (
          <div className="dropdown">
            <ul className="dropdown-container">
              {options != null && options.length > 0 ? (
                options
                  .filter((o) =>
                    o.name.toLowerCase().includes(inputValue.toLowerCase())
                  )
                  .map((option) => (
                    <li
                      key={`${option.value}-autocomplete-row`}
                      className="drop-down-row"
                      onClick={() => {
                        setInputValue(option.name)
                        pickHandler(option.value, option.extra)
                        setShowTable(false)
                      }}
                    >
                      {option.name}
                    </li>
                  ))
              ) : (
                <li
                  className="drop-down-row"
                  key={`no-value-autocomplete-row`}
                  onClick={() => {
                    setShowTable(false)
                  }}
                >
                  {intl.formatMessage({ id: 'no-options' })}
                </li>
              )}
            </ul>
          </div>
        ))}
    </div>
  )
}
