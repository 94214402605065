import React from 'react'

export const SideField: React.FC<{
  className?: string
  children: React.ReactNode
}> = ({ className, children }) => (
  <div className={`side-field col-sm-5 col-sm-push-1 ${className}`}>
    {children}
  </div>
)
